import React from "react";

export const DeleteButtons = (props) => {
  console.log("Delete buttons", props);
  return (
    <div className="flex gap-16 grid grid-cols-12">
      <button
        onClick={props.closeModal}
        className="col-span-6 w-full !font-medium border border-blue text-blue px-10 py-8 rounded-8 leading-16 text-1.4 border-solid hover:bg-[#0D4B951A]"
      >
        Annuler
      </button>
      <button
        onClick={props.clickHandler}
        className="col-span-6 w-full !font-medium text-white bg-blue px-10 py-8 rounded-8 leading-16 text-1.4 border-solid hover:bg-[#0D3C98F7]"
      >
        {props.text}
      </button>
    </div>
  );
};
