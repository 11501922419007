import cx from "classnames";
import React from "react";
import { NavLink } from "react-router-dom";
import styles from "./Workshop.module.scss";

export const Workshop = (props) => {
  return (
    <>
      <NavLink
        className="flex-1 flex-grow basis-0 min-w-0 max-w-[218px]"
        to={`/workshop-detail/${props.id}`}
      >
        <div
          className={cx(
            styles.row,
            "row",
            styles[props.theme],
            styles["Workshop-container"]
          )}
        >
          <img alt="cover" src={props.cover_img} className={styles["img-style"]} />
          <div className={styles.wrapper}>
            <h1
              className={cx(
                styles.heading,
                "text-1.4",
                "md:text-1.4",
                "text-ellipsis whitespace-nowrap overflow-hidden"
              )}
            >
              {props.workshop_name}
            </h1>
          </div>
        </div>
      </NavLink>
    </>
  );
};
