import cx from "classnames";
import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import AuthContext from "../../context/AuthContext";
import styles from "./WEventOptions.module.scss";

export const WEventOptions = (props) => {
  console.log(window.location.origin);
  const { authTokens, user } = useContext(AuthContext);

  const cloneWorkshop = async () => {
    var requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Token ${authTokens.token}`,
      },
    };
    toast("Duplication en cours");
    await fetch(
      process.env.REACT_APP_API_URL + `add-workshop/clone/?id=${props.id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (!result.id) {
          toast.error("Quelque chose s’est mal passé");
        } else {
          toast.success("Atelier dupliqué correctement");
        }
      });
  };

  return (
    <div className="">
      <button
        onClick={() => {
          navigator.clipboard.writeText(
            `${window?.location?.origin}${process.env.REACT_APP_SUBPAGE}/workshop-detail/${props.id}`
          );
          toast.success("Copié dans le presse-papier");
        }}
        className="px-16 flex gap-1.2 py-6 items-center w-full hover:bg-[rgba(104,213,219,0.05)]"
      >
        <div className="">
          <img
            alt="link circle icon"
            src={`${process.env.PUBLIC_URL}/images/link-circle.svg`}
            className={cx(styles["img-style"])}
          />
        </div>
        <p className="text-1.2">Copier le lien</p>
      </button>

      <NavLink className="" to={`/edit-workshop/${props.id}`}>
        <button className="px-16 !font-medium flex gap-1.2 py-6 w-full items-center hover:bg-[rgba(104,213,219,0.05)]">
          <div className="">
            <img
              alt="link circle icon"
              src={`${process.env.PUBLIC_URL}/images/edit2.svg`}
              className={cx(styles["img-style"])}
            />
          </div>
          <p className="text-1.2">Éditer</p>
        </button>
      </NavLink>
      <button
        onClick={cloneWorkshop}
        className="px-16 !font-medium flex gap-1.2 py-6 w-full items-center hover:bg-[rgba(104,213,219,0.05)]"
      >
        <div className="">
          <img
            alt="link circle icon"
            src={`${process.env.PUBLIC_URL}/images/iconClone.svg`}
            className={cx(styles["img-style"], "w-[17px]")}
          />
        </div>
        <p className="text-1.2">Dupliquer</p>
      </button>
      {user.user_type === 1 && (
        <div className="px-16 flex w-full py-6 hover:bg-[rgba(104,213,219,0.05)] ">
          <button onClick={props.openModal} className="flex w-full gap-1.2 ">
            <div className="">
              <img
                alt="delete icon"
                src={`${process.env.PUBLIC_URL}/images/Trash.svg`}
                className={cx(styles["img-style"])}
              />
            </div>
            <p className="text-1.2">Supprimer</p>
          </button>
        </div>
      )}
    </div>
  );
};
