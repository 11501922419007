import cx from "classnames";
import React from "react";
import styles from "./WorkshopButton.module.scss";
export const WorkshopButton = (props) => {
  return (
    <>
      <a className="" target={"_blank"} href={props.document} rel="noreferrer">
        <button
          className={cx(
            styles.buttonBg,
            "lmd:text-1.4 text-1.2 text-blue items-center"
          )}
        >
          <div className="lmd:pr-8 pr-6 md:pr-0">
            <img
              alt="icon"
              src={`${process.env.PUBLIC_URL}/images/${props.icon}`}
              className={cx(styles["img-style"], "min-w-[24px]")}
            />
          </div>
          <div className="md:hidden">{props.text}</div>
        </button>
      </a>
    </>
  );
};
