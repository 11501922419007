import cx from "classnames";
import React from "react";
import { NavLink } from "react-router-dom";
import styles from "./CLAUpcomingRS.module.scss";

export const CLAUpcomingRS = (props) => {
  var assignOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
  };
  const tempWorkshopDate = new Date(props?.workshop_date).toLocaleString(
    "en-US",
    assignOptions
  );
  return (
    <div
      className={
        (styles.row, "row", styles[props.theme], styles["ActivityCard_container"])
      }
    >
      <NavLink className="block " to={`/workshop-detail/${props?.id}`}>
        <div
          className={cx(
            styles.rowStyle,
            "flex gap-4 pb-10 hover:bg-[rgba(104,213,219,0.05)]"
          )}
        >
          <div className={cx(styles.imgWrapper)}>
            <img
              alt="upcoming icon"
              src={`${process.env.PUBLIC_URL}/images/upcoming-icon.png`}
              className={cx(styles["img-style"])}
            />
          </div>

          <div className={cx(styles.wrapper, "w-[calc(100%-50px)] ")}>
            <h1
              className={cx(
                styles.teacherHeading,
                "text-16 leading-16 pb-8 text-themeDark whitespace-nowrap text-ellipsis overflow-hidden md:text-1.4"
              )}
            >
              {props?.workshop_name}
            </h1>
            <p className={cx(styles.text, "text-1.2 leading-14 text-ashgray")}>
              {tempWorkshopDate}
            </p>
          </div>
        </div>
      </NavLink>
    </div>
  );
};
