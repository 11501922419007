import cx from "classnames";
import React from "react";
import { WorkshopLabels } from "../WorkshopLabels/WorkshopLabels";
import styles from "./WorkshopBanner.module.scss";

export const WorkshopBanner = (props) => {
  var assignOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
    // hour: "numeric",
  };
  const tempWorkshopDate = new Date(props?.workshop_date).toLocaleString(
    "en-US",
    assignOptions
  );
  return (
    <div className={cx("col-span-12", styles.overlay)}>
      <div
        style={{
          backgroundImage: `url(${props.cover_img})`,
        }}
        className={styles.bgImg}
      >
        <div className="lmd:text-1.4 text-1.2 pl-32 ssm:pl-16 z-10 relative">
          {tempWorkshopDate} |{" "}
          <span className="class-date inline-flex overflow-hidden">
            {props?.start_time}
          </span>{" "}
          -{" "}
          <span className="class-date inline-flex overflow-hidden">
            {props?.end_time}
          </span>
          {/* {props?.start_time} - {props.end_time} */}
        </div>
        <div className="lmd:text-36 text-34 sm:text-26 pb-32 pl-32 ssm:pl-16 ssm:pr-16 ssm:text-24 relative z-10 leading-[43px]">
          {props.workshop_name}
        </div>
      </div>
      <div className="text-ashgray lmd:text-1.4 text-1.2 leading-8 p-10 md:p-16">
        {props.description}
      </div>
      <div className="grid grid-cols-3 xmd:grid-cols-2 lmd:gap-48 gap-8 pr-10 pl-10 xmd:pl-10 pb-10 sm:grid-cols-1 sm:pl-10">
        <WorkshopLabels name="Thème" subTitle={props.category_name} />
        <WorkshopLabels name="Format" subTitle={props.type_name} />
        <WorkshopLabels name="Niveau" subTitle={props.level_name} />
      </div>
    </div>
  );
};
