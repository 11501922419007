import cx from "classnames";
import React, { useContext, useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import Select from "react-select";
import { toast } from "react-toastify";
import AuthContext from "../../context/AuthContext";
import { ImageInput } from "../ImageInput/ImageInput";

export const StudentList = (props) => {
  const { authTokens } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);

  const getCustomStyles = (fieldName) => {
    const customStyles = {
      control: (base) => ({
        ...base,
        padding: "5px 8px 6px 8px",
        borderColor: errors[fieldName]?.message ? "#EB5757 !important" : "#d4e6ff",
        boxShadow: "0 !important",
        "&:hover": {
          borderColor: "#2684ff",
        },
      }),
      valueContainer: (base) => ({
        ...base,
        paddingTop: "0 !important",
        paddingBottom: "0 !important",
      }),
      singleValue: (base) => ({
        ...base,
        fontSize: "12px",
      }),
      menuList: (base) => ({
        ...base,
        paddingTop: "0 !important",
        paddingBottom: "0 !important",
        borderRadius: "4px",
      }),
      option: (base) => ({
        ...base,
        color: "#020422",
      }),
    };
    return customStyles;
  };
  const userOptions = [
    { label: "Staff", value: 1 },
    { label: "Teacher", value: 2 },
    { label: "Student", value: 3 },
  ];
  const [schoolOptions, setschoolOptions] = useState([]);
  const getSchool = async () => {
    const response = await fetch(process.env.REACT_APP_API_URL + "add-schools").then(
      (response) => response.json()
    );
    setschoolOptions(response);
  };
  useEffect(() => {
    getSchool();
  }, []);
  const methods = useForm();
  const errors = methods.formState.errors;
  useEffect(() => {
    Object.keys(methods.getValues()).forEach((key) => {
      methods.setValue(key, props?.peopleData[key]);
    });
  }, []);
  const onSubmit = (data) => {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      if (key === "profile_pic") {
        if ("File" in window && data[key][0] instanceof File)
          formData.append(key, data[key][0]);
      } else if (data[key]) formData.append(key, data[key]);
    });
    var requestOptions = {
      method: "PUT",
      headers: {
        Accept: "application/json",
        Authorization: `Token ${authTokens.token}`,
      },
      body: formData,
    };
    setIsLoading(true); //Disable/able the Add Button
    fetch(
      process.env.REACT_APP_API_URL + `user/${props?.peopleData?.id}/`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (!result.id) {
          Object.keys(result).forEach((key) =>
            methods.setError(key, { type: "custom", message: result[key] })
          );
        } else {
          toast.success("Utilisateur ajouté");
          props.closeModal();
          props.getpeopleData();
          methods.reset();
        }
        setIsLoading(false);
      })
      .catch((error) => {
        toast.error(error.message);
        setIsLoading(false);
      });
  };

  return (
    <>
      <div className="bg-white rounded-12 p-10 mb-10 ">
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="">
              <div className="flex w-full grid-cols-12">
                <div className=" col-span-6">
                  <h2 className="text-20 text-blue  sm:pb-16 sm:text-1.8 ">
                    Editer le profil
                  </h2>
                </div>
                <div className="items-center col-span-6 w-[50%]">
                  <div className="">
                    <div className="text-start items-start">
                      <ImageInput
                        className="mx-auto"
                        accept={{ "image/*": [".jpeg", ".jpg", ".png", ".webp"] }}
                        name="profile_pic"
                        {...methods.register("profile_pic", {
                          required: "This field may not be blank.",
                        })}
                      />
                      <div className="text-red-500 h-[12px] mt-[4px]">
                        {errors?.profile_pic?.message && errors.profile_pic.message}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-12 flex gap-16 sm:grid-cols-6 pb-16">
                <div className="col-span-6">
                  <h1 className="text-1.4 pb-8 sm:pb-8 sm:1.2">Prénom</h1>
                  <div className="">
                    <input
                      className={cx(
                        "border mb-[4px] border-solid leading-15 text-1.2 rounded-8 px-16 py-17 w-full",
                        { "!border-errorRed": errors?.first_name?.message }
                      )}
                      type="text"
                      {...methods.register("first_name", {
                        required: "This field may not be blank.",
                      })}
                    />
                  </div>
                  <div className="text-errorRed h-[12px]">
                    {errors?.first_name?.message && errors.first_name.message}
                  </div>
                </div>
                <div className="col-span-6 ">
                  <h1 className="text-1.4 pb-8 sm:pb-8  sm:1.2">Nom de famille</h1>
                  <div className="">
                    <input
                      className={cx(
                        "border mb-[4px] border-solid leading-15 text-1.2 rounded-8 px-16 py-17 w-full",
                        { "!border-errorRed": errors?.last_name?.message }
                      )}
                      type="text"
                      {...methods.register("last_name", {
                        required: "This field may not be blank.",
                      })}
                    />
                  </div>
                  <div className="text-errorRed h-[12px]">
                    {errors?.last_name?.message && errors.last_name.message}
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-12 flex gap-16 sm:grid-cols-6 sm:pt-16 pb-16">
                <div className="col-span-6">
                  <h1 className="text-1.4 pb-8 sm:pb-8 sm:1.2">Email</h1>
                  <div className="">
                    <input
                      className={cx(
                        "border mb-[4px] border-solid leading-15 text-1.2 rounded-8 px-16 py-17 w-full",
                        { "!border-errorRed": errors?.email?.message }
                      )}
                      type="email"
                      {...methods.register("email", {
                        required: "This field may not be blank.",
                      })}
                    />
                  </div>
                  <div className="text-errorRed h-[12px]">
                    {errors?.email?.message && errors.email.message}
                  </div>
                </div>
                <div className="col-span-6 ">
                  <h1 className="text-1.4 pb-8 sm:pb-8 sm:1.2">Numéro de contact</h1>
                  <div className="">
                    <input
                      className={cx(
                        "border mb-[4px] border-solid leading-15 text-1.2 rounded-8 px-16 py-17 w-full",
                        { "!border-errorRed": errors?.phone_number?.message }
                      )}
                      placeholder="Écrire Numéro de contact"
                      {...methods.register("phone_number", {
                        required: "This field may not be blank.",
                      })}
                    />
                  </div>
                  <div className="text-errorRed h-[12px]">
                    {errors?.phone_number?.message && errors.phone_number.message}
                  </div>
                </div>
              </div>
              <div className="flex gap-16 pb-16 sm:grid-cols-6 sm:pt-16">
                <div className="col-span-6 w-full ">
                  <h1 className="text-1.4 pb-8 sm:pb-8 sm:1.2">Type de profil</h1>
                  <div className="">
                    {/* {JSON.stringify(userOptions)} */}
                    <Controller
                      className="border rounded-12 px-16 py-17 max-w-full w-full"
                      control={methods.control}
                      name="user_type"
                      rules={{ required: "This field may not be blank." }}
                      render={({ field: { onChange, value, ref } }) => (
                        <Select
                          className="react-select-container mb-[4px]"
                          inputRef={ref}
                          styles={getCustomStyles("user_type")}
                          classNamePrefix="react-select"
                          options={userOptions}
                          placeholder="Sélectionner"
                          value={userOptions?.find((c) => c.value === value)}
                          onChange={(val) => onChange(val.value)}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: "8px",
                            colors: {
                              ...theme.colors,
                              primary25: "#D4E6FF",
                              primary: "#93C1FF",
                              color: "#020422",
                            },
                          })}
                        />
                      )}
                    />
                    <div className="text-errorRed h-[12px]">
                      {errors?.user_type?.message && errors.user_type.message}
                    </div>
                  </div>
                </div>
                <div className="col-span-6 w-full ">
                  <h1 className="text-1.4 pb-8 sm:pb-8 sm:1.2">
                    Établissement scolaire
                  </h1>
                  <div className="">
                    {/* {JSON.stringify(schoolOptions)} */}
                    <Controller
                      className="border rounded-12 px-16 py-17 max-w-full w-full"
                      control={methods.control}
                      name="schools"
                      rules={{ required: "This field may not be blank." }}
                      render={({ field: { onChange, value, ref } }) => (
                        <Select
                          className="react-select-container mb-[4px]"
                          inputRef={ref}
                          menuPlacement="auto"
                          styles={getCustomStyles("school_name")}
                          classNamePrefix="react-select"
                          placeholder="Sélectionner"
                          options={schoolOptions}
                          getOptionLabel={(option) => option.school_name}
                          getOptionValue={(option) => option.id}
                          value={schoolOptions?.find((c) => c.id === value)}
                          onChange={(val) => onChange(val.id)}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: "8px",
                            colors: {
                              ...theme.colors,
                              primary25: "#D4E6FF",
                              primary: "#93C1FF",
                              color: "#020422",
                            },
                          })}
                        />
                      )}
                    />
                    <div className="text-errorRed h-[12px]">
                      {errors?.school_name?.message && errors.school_name.message}
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid-cols-12  gap-16  sm:grid-cols-6 sm:pt-16">
                <div className="col-span-6 ">
                  <h1 className="text-1.4 pb-8 sm:pb-8 sm:1.2">emplacement</h1>
                  <div className="w-full">
                    <input
                      className={cx(
                        "border border-solid leading-15 text-1.2 rounded-8 mb-[4px] px-16 py-17 w-full",
                        { "!border-errorRed": errors?.location?.message }
                      )}
                      type="location"
                      placeholder="Écrire emplacement"
                      {...methods.register("location", {
                        required: "This field may not be blank.",
                      })}
                    />
                  </div>
                </div>
                <div className="text-errorRed h-[12px]">
                  {errors?.location?.message && errors.location.message}
                </div>
              </div>

              <div className="pt-32 pb-10 ">
                <div className="flex gap-16 grid-cols-12 w-full">
                  <button
                    onClick={props.closeModal}
                    className="col-span-6 w-full border !font-medium text-green border-green p-16 rounded-8 leading-16 text-1.4 border-solid hover:bg-[#17A4541A]"
                  >
                    Annuler
                  </button>
                  <button
                    disabled={isLoading}
                    type="submit"
                    className="col-span-6 w-full border !font-medium text-white bg-green p-16 rounded-8 leading-16 text-1.4 border-solid hover:bg-[#037f39]"
                  >
                    Confirmer
                  </button>
                </div>
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
    </>
  );
};
