import cx from "classnames";
import React, { useContext } from "react";
import { toast } from "react-toastify";
import AuthContext from "../../context/AuthContext";
import styles from "./WAssignmentOptions.module.scss";

export const WAssignmentOptions = (props) => {
  const { user } = useContext(AuthContext);

  return (
    <div className="">
      {user.user_type === 1 && (
        <button
          onClick={() => {
            navigator.clipboard.writeText(props.document.document);
            toast.success("Copié dans le presse-papier");
          }}
          className="px-16 flex gap-1.2 py-6 w-full items-center hover:bg-[rgba(104,213,219,0.05)]"
        >
          <div className="">
            <img
              alt="link circle icon"
              src={`${process.env.PUBLIC_URL}/images/link-circle.svg`}
              className={cx(styles["img-style"])}
            />
          </div>
          <p className="text-1.2">Copier le lien</p>
        </button>
      )}

      <a className="" target={"_blank"} href={props.document} rel="noreferrer">
        <button
          className={cx(
            styles.buttonBg,
            " pl-16 pr-32 flex gap-1.2 py-6 w-full hover:bg-[rgba(104,213,219,0.05)]"
          )}
        >
          <img
            alt="download icon"
            src={`${process.env.PUBLIC_URL}/images/downarrow.svg`}
            className={cx(styles["img-style"])}
          />

          <p className="text-1.2">Télécharger</p>
        </button>
      </a>

      <div className="">
        <button
          onClick={props.openModal}
          className="px-16 flex gap-1.2 py-6 w-full hover:bg-[rgba(104,213,219,0.05)]"
        >
          <div className="">
            <img
              alt="delete icon"
              src={`${process.env.PUBLIC_URL}/images/Trash.svg`}
              className={cx(styles["img-style"])}
            />
          </div>
          <p className="text-1.2">Supprimer</p>
        </button>
      </div>
    </div>
  );
};
