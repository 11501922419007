import cx from "classnames";
import React from "react";
import styles from "./StudentProfileItem.module.scss";

export const StudentProfileItem = (props) => {
  return (
    <>
      <div className={cx("col-span-1 pl-10", styles.profile)}>
        <img
          alt="profile"
          src={`${props.user.profile_pic}`}
          className={cx(styles["img-style"], "mt-1 mb-5")}
        />
      </div>
      <div className="col-span-4 pb-16 pl-10 md:pl-16 sm:pb-0">
        <div className="col-span-2 text-24 text-blue sm:text-20">
          {props?.user?.first_name} {props?.user?.last_name}
        </div>
        <div className="col-span-2 text-16 sm:text-1.4">
          {props.user.user_type_str}
        </div>
      </div>
    </>
  );
};
