import React, { useState } from "react";
import { Calendar as ReactCalendar } from "react-calendar";
import "react-calendar/dist/Calendar.css";
import styles from "./Calendar.module.scss";

export const Calendar = (props) => {
  const [value, onChange] = useState(new Date());
  const upcomingDates = props.workshops?.map((workshop) => {
    const tempDate = new Date(workshop?.workshop_date);
    tempDate.setHours(0, 0, 0, 0);
    return tempDate.getTime();
  });

  return (
    <div>
      <ReactCalendar
        calendarType={"ISO 8601"}
        className={styles.customstyle}
        onChange={onChange}
        value={value}
        locale="fr"
        formatShortWeekday={(locale, date) =>
          ["Di", "Lu", "Ma", "Me", "Je", "Ve", "Sa"][date.getDay()]
        }
        tileClassName={(date) => {
          if (upcomingDates?.length) {
            if (upcomingDates.includes(date.date.getTime())) {
              return styles["test-date"];
            }
          }
        }}
      />
    </div>
  );
};
