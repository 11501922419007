import React, { useContext, useEffect, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { toast } from "react-toastify";
import AuthContext from "../../context/AuthContext";
import { NoData } from "../NoData/NoData";
import WorkshopGroups from "../WorkshopGroups/WorkshopGroups";

import { WorkshopPeopleLabel } from "../WorkshopPeopleLabel/WorkshopPeopleLabel";
import { WorkshopPeopleList } from "../WorkshopPeopleList/WorkshopPeopleList";

export const WorkshopPeople = (props) => {
  const { authTokens } = useContext(AuthContext);
  const [workshopGroups, setWorkshopGroups] = useState([]);
  const [studentsInGroups, setStudentsInGroups] = useState([]);
  const [groupsWithVacancy, setGroupsWithVacancy] = useState([]);

  const getWorkshopGroups = async () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: {
        Accept: "application/json",
        Authorization: `Token ${authTokens.token}`,
      },
    };
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}workshops-group/?workshop=${props.workshopId}`,
      requestOptions
    ).then((response) => response.json());
    const tempGroups = [];
    const tempStudentsInGroups = [];
    const tempGroupsWithVacancy = [];
    response?.forEach((group, index) => {
      const tempGroup = {};
      tempGroup.id = group.id;
      tempGroup.students = [];
      if (group.students?.length < 3) {
        tempGroupsWithVacancy.push({
          value: group.id,
          label: `Group ${String.fromCharCode(65 + index)}`,
        });
      }
      group.students?.forEach((student) => {
        student.student_data.referenceId = student.id;
        tempGroup.students.push(student.student_data);
        tempStudentsInGroups.push(student.student_data.id);
      });
      tempGroups.push(tempGroup);
    });
    setGroupsWithVacancy(tempGroupsWithVacancy);
    setStudentsInGroups(tempStudentsInGroups);
    setWorkshopGroups(tempGroups);
  };
  const createWorkshopGroups = async (numberOfGroups) => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: {
        Accept: "application/json",
        Authorization: `Token ${authTokens.token}`,
      },
    };
    await fetch(
      `${process.env.REACT_APP_API_URL}workshops-group/?workshop=${props.workshopId}&noofgroups=${numberOfGroups}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result?.length) {
          toast.success(
            `${numberOfGroups} groupe${numberOfGroups > 1 ? "s" : ""} créés`,
            { toastId: numberOfGroups }
          );
          getWorkshopGroups();
        }
      });
  };
  const addStudentToGroup = (group, student) => {
    const data = {
      group: group,
      students: student,
    };
    var requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: {
        Accept: "application/json",
        Authorization: `Token ${authTokens.token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };
    fetch(
      `${process.env.REACT_APP_API_URL}workshops-group-students/`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (!result.id) {
          toast.error("N’a pas été ajouté");
        } else {
          toast.success("Élève ajouté au groupe");
          getWorkshopGroups();
        }
      });
  };
  const removeStudentFromGroup = (referenceId) => {
    var requestOptions = {
      method: "DELETE",
      redirect: "follow",
      headers: {
        Accept: "application/json",
        Authorization: `Token ${authTokens.token}`,
        "Content-Type": "application/json",
      },
    };
    fetch(
      `${process.env.REACT_APP_API_URL}workshops-group-students/${referenceId}/`,
      requestOptions
    ).then((response) => {
      if (response.ok) {
        getWorkshopGroups();
      }
    });
  };
  const removeStudentFromWorkshop = (referenceId) => {
    var requestOptions = {
      method: "DELETE",
      redirect: "follow",
      headers: {
        Accept: "application/json",
        Authorization: `Token ${authTokens.token}`,
        "Content-Type": "application/json",
      },
    };
    fetch(
      `${process.env.REACT_APP_API_URL}workshop-assigns/${referenceId}/`,
      requestOptions
    ).then((response) => {
      if (response.ok) {
        toast.success("Élève enlevé du groupe");
        getStudentData();
        getWorkshopGroups();
      }
    });
  };
  const deleteGroup = (referenceId) => {
    var requestOptions = {
      method: "DELETE",
      redirect: "follow",
      headers: {
        Accept: "application/json",
        Authorization: `Token ${authTokens.token}`,
        "Content-Type": "application/json",
      },
    };
    fetch(
      `${process.env.REACT_APP_API_URL}workshops-group/${referenceId}/`,
      requestOptions
    ).then((response) => {
      if (response.ok) {
        getWorkshopGroups();
      }
    });
  };
  const [workshopstudents, setworkshopstudents] = useState([]);

  const getStudentData = async () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: {
        Accept: "application/json",
        Authorization: `Token ${authTokens.token}`,
      },
    };
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}workshop-assigns/?workshop=${props.workshopId}`,
      requestOptions
    ).then((response) => response.json());
    setworkshopstudents(response);
  };

  //User API
  const [peopleData, setpeopleData] = useState([]);
  const getPeopleData = async () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: {
        Accept: "application/json",
        Authorization: `Token ${authTokens.token}`,
      },
    };
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}user/`,
      requestOptions
    ).then((response) => response.json());
    setpeopleData(response);
  };
  const [allowGroupAdd, setAllowGroupAdd] = useState(false);
  useEffect(() => {
    const totalAddedStudents = workshopstudents?.length;
    const totalGroups = workshopGroups?.length;
    const groupsNeeded = Math.ceil(totalAddedStudents / 3);
    const groupsToBeCreated = groupsNeeded - totalGroups;

    setAllowGroupAdd(groupsToBeCreated > 0);
  });
  useEffect(() => {
    async function getData() {
      await getPeopleData();
      await getStudentData();
      await getWorkshopGroups();
    }
    getData();
  }, [props.workshopId]);

  let filteredStudents = peopleData.filter((student) => {
    const checkRecord = workshopstudents.filter(
      (assignment) => assignment.assignTo === student.id
    );

    !!checkRecord?.length && (student.referenceId = checkRecord[0].id);
    return !!checkRecord?.length;
  });
  const studentsNotInGroup = filteredStudents.filter(
    (student) => !studentsInGroups.includes(student.id)
  );

  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <div className="grid grid-cols-12">
          <div className="col-span-12 mld:col-span-6">
            <div className="bg-white rounded-b-xl p-10 mb-10 md:mb-0 md:px-16 md:pt-16 md:pb-0">
              <div className="flex justify-between pb-10 md:py-16">
                <h1 className="text-20 text-blue py-[3px] md:text-1.8">Students</h1>
                <div className="">
                  <WorkshopPeopleLabel
                    workshopId={props.workshopId}
                    selectedOptions={filteredStudents}
                    getPeople={getStudentData}
                    workshopGroups={workshopGroups}
                    studentsInGroups={studentsInGroups}
                    createWorkshopGroupsHandler={createWorkshopGroups}
                  />
                </div>
              </div>
              {studentsNotInGroup?.length ? (
                studentsNotInGroup.map((item) => (
                  <WorkshopPeopleList
                    canDrag
                    key={`workshop-people-list-${item.id}`}
                    {...item}
                    workshopId={props.workshopId}
                    availableVacancies={groupsWithVacancy}
                    addStudentToGroupHandler={addStudentToGroup}
                    removeStudentFromWorkshopHandler={removeStudentFromWorkshop}
                  />
                ))
              ) : (
                <NoData />
              )}
            </div>
          </div>
          {workshopGroups && (
            <WorkshopGroups
              allowGroupAdd={allowGroupAdd}
              data={workshopGroups}
              addStudentToGroupHandler={addStudentToGroup}
              removeStudentFromGroupHandler={removeStudentFromGroup}
              deleteGroupHandler={deleteGroup}
              createWorkshopGroupsHandler={createWorkshopGroups}
            />
          )}
        </div>
      </DndProvider>
    </>
  );
};
