import React from "react";
import { NoData } from "../NoData/NoData";
import WorkshopGroup from "../WorkshopGroup/WorkshopGroup";
const WorkshopGroups = (props) => {
  return (
    <div className="Grouping p-10 bg-white mb-10 rounded-b-12 col-span-12 mld:col-span-6 md:mb-0 md:p-16">
      <div className="flex pb-10 justify-between md:py-16">
        <h1 className="text-20 text-blue  md:text-1.8 py-[3px]">Groupes</h1>
        {props.allowGroupAdd && (
          <button
            onClick={() => props.createWorkshopGroupsHandler(1)}
            className="flex px-12 py-8 bg-bgGreen rounded-4 gap-8 md:px-4 md:py-6 md:rounded-xl"
          >
            <div className="text-green text-1.2  leading-15 md:text-1.1 mt-1">
              Ajouter Groupes
            </div>
            <div className="">
              <img alt="icon" src={`${process.env.PUBLIC_URL}/images/Plus.svg`} />
            </div>
          </button>
        )}
      </div>
      {props?.data?.length ? (
        props?.data?.map((group, index) => (
          <WorkshopGroup
            key={`workshop-group-${group.id}-${index}`}
            index={index}
            id={group.id}
            data={[...group.students]}
            allowDrop={group.students.length < 3}
            addStudentToGroupHandler={props.addStudentToGroupHandler}
            removeStudentFromGroupHandler={props.removeStudentFromGroupHandler}
            deleteGroupHandler={props.deleteGroupHandler}
          />
        ))
      ) : (
        <NoData />
      )}
    </div>
  );
};

export default WorkshopGroups;
