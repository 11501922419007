import cx from "classnames";
import React from "react";
import styles from "./Button.module.scss";

export const Button = (props) => {
  const getBtn = () => <button className={styles.button}>{props.text}</button>;
  return (
    <div className={cx(styles.row, "row")}>
      {props.link ? <a href={props.link}>{getBtn()}</a> : getBtn()}
    </div>
  );
};
