import React, { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import { EventWorkshop } from "../EventWorkshop/EventWorkshop";

export const WorkshopRequests = () => {
  const { authTokens } = useContext(AuthContext);

  const [requestedWorkshop, setrequestedWorkshop] = useState([]);
  const getRequestedData = async () => {
    var requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Token ${authTokens.token}`,
      },
    };
    const response = await fetch(
      process.env.REACT_APP_API_URL + "add-workshop/?wsstatustype=DRAFT",
      requestOptions
    ).then((response) => response.json());

    setrequestedWorkshop(response);
  };
  useEffect(() => {
    getRequestedData();
  }, []);
  return (
    <div className="bg-white p-10 rounded-12">
      <div className="">
        <h1 className="text-20 text-blue mb-10 md:text-1.8">Workshop Requests</h1>

        <div className="grid grid-cols-12 gap-10 ">
          {requestedWorkshop.map((item, index) => (
            <NavLink
              className="flex col-span-12 mmd:col-span-6 lmd:!col-span-4"
              key={`request-workshops-${index}`}
              to={`/edit-workshop/${item.id}`}
            >
              <EventWorkshop noLink hideOptions showStatus {...item} />
            </NavLink>
          ))}
        </div>
      </div>
    </div>
  );
};
