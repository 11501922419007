import cx from "classnames";
import React, { useContext } from "react";
import Modal from "react-modal";
import { toast } from "react-toastify";
import AuthContext from "../../context/AuthContext";
import { DeleteCard } from "../DeleteCard/DeleteCard";
import styles from "./LevelCard.module.scss";

export const LevelCard = (props) => {
  const { authTokens } = useContext(AuthContext);

  const updateLevelStatus = (id, levelStatus, levelName) => {
    const typeStatusUpdate = !levelStatus;
    var requestOptions = {
      method: "PUT",
      headers: {
        Authorization: `Token ${authTokens.token}`,

        "Content-Type": "application/json",
      },

      body: JSON.stringify({
        status: typeStatusUpdate,
        level_name: levelName,
      }),
    };
    fetch(process.env.REACT_APP_API_URL + `update-ws-level/${id}/`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (!result.id) {
          toast.error("Erreur");
        } else {
          toast.success("Niveau modifié");
          props.getLevelData();
        }
      });
  };

  const removefromTypes = (referenceId, callback) => {
    var requestOptions = {
      method: "DELETE",
      redirect: "follow",
      headers: {
        Accept: "application/json",
        Authorization: `Token ${authTokens.token}`,
        "Content-Type": "application/json",
      },
    };
    fetch(
      process.env.REACT_APP_API_URL + `update-ws-level/${referenceId}`,
      requestOptions
    ).then((response) => {
      if (response.ok) {
        toast.success("Type éliminer");
        props.getLevelData();
        callback();
      }
    });
  };
  const [modalDelOpen, setmodalDelOpen] = React.useState(false);

  function openDelModal() {
    setmodalDelOpen(true);
  }
  function closeDelModal() {
    setmodalDelOpen(false);
  }
  const removeHandler = () => {
    console.log("remove handler called");
    removefromTypes(props?.id, closeDelModal);
  };

  return (
    <>
      <div className="flex py-8 ">
        <button className="flex group justify-between bg-iceBlue bg-opacity-10 py-13 px-20 rounded-8 w-full text-1.4 items-center md:text-1.2">
          <div
            className={cx(
              { "text-ashgray": !props.status },
              "w-[calc(100%-30px)] whitespace-nowrap text-ellipsis overflow-hidden text-left"
            )}
          >
            {props.level_name}
          </div>
          <button
            onClick={() => {
              openDelModal && openDelModal(true);
            }}
            className={cx(
              "opacity-0 group-hover:opacity-100 pr-[12px] md:opacity-100"
            )}
          >
            <img
              alt="trash icon"
              src={`${process.env.PUBLIC_URL}/images/Trash.svg`}
              className="max-w-20"
            />
          </button>
          <div className="text-center w-[20px] h-[20px] justify-center flex items-center">
            <button
              onClick={() =>
                updateLevelStatus(props.id, props.status, props.level_name)
              }
            >
              {props.status ? (
                <img
                  alt="eye icon"
                  src={`${process.env.PUBLIC_URL}/images/eye.svg`}
                  className="max-w-20"
                />
              ) : (
                <img
                  alt="eye slash icon"
                  src={`${process.env.PUBLIC_URL}/images/eye-slash.svg`}
                  className="max-w-20"
                />
              )}
            </button>
          </div>
        </button>
      </div>
      <Modal
        appElement={document.getElementById("root")}
        isOpen={modalDelOpen}
        onRequestClose={closeDelModal}
        className={styles.DeleteUsers}
      >
        <DeleteCard
          multiple
          closeModal={closeDelModal}
          deleteHandler={removeHandler}
        />
      </Modal>
    </>
  );
};
