import cx from "classnames";
import React, { useContext, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import AuthContext from "../../context/AuthContext";
import { ImageInput } from "../ImageInput/ImageInput";

export const EditProfile = (props) => {
  const { authTokens, updateUser } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);

  const methods = useForm();
  const errors = methods.formState.errors;
  useEffect(() => {
    Object.keys(methods.getValues()).forEach((key) => {
      methods.setValue(key, props?.user[key]);
    });
  }, []);

  const onSubmit = (data) => {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      if (key === "profile_pic") {
        if ("File" in window && data[key][0] instanceof File)
          formData.append(key, data[key][0]);
      } else if (data[key]) formData.append(key, data[key]);
    });
    var requestOptions = {
      method: "PUT",
      headers: {
        Accept: "application/json",
        Authorization: `Token ${authTokens.token}`,
      },
      body: formData,
    };
    setIsLoading(true); //Disable/able the Add Button
    fetch(process.env.REACT_APP_API_URL + `user/${props.user.id}/`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (!result.id) {
          Object.keys(result).forEach((key) =>
            methods.setError(key, { type: "custom", message: result[key] })
          );
        } else {
          toast.success("Utilisateur ajouté");
          props.isLoggedIn && updateUser(result);
          props.closeModal();
          methods.reset();
        }
        setIsLoading(false);
      })
      .catch((error) => {
        toast.error(error.message);
        setIsLoading(false);
      });
  };

  return (
    <>
      <div className="bg-white rounded-12 p-10 mb-10">
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="">
              <div className="flex w-full grid-cols-12">
                <div className=" col-span-6">
                  <h2 className="text-20 text-blue  sm:pb-16 sm:text-1.8 ">
                    Editer le profil
                  </h2>
                </div>
                <div className="items-center col-span-6 w-[50%]">
                  <div className="">
                    <div className="text-start items-start">
                      <ImageInput
                        className="mx-auto"
                        accept="image/png, image/jpg, image/jpeg, image/gif"
                        name="profile_pic"
                        {...methods.register("profile_pic", {
                          required: "This field may not be blank.",
                        })}
                      />
                      <div className="text-red-500 h-[12px] mt-[4px]">
                        {errors?.profile_pic?.message && errors.profile_pic.message}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-12 flex gap-16 pb-16 sm:grid-cols-6">
              <div className="col-span-6">
                <h1 className="text-1.4 pb-8 sm:pb-8 sm:1.2">Ajouter un profil</h1>
                <div className="">
                  <input
                    className={cx(
                      "border sm:w-full border-solid leading-15 text-1.2 rounded-8 px-16 py-17 w-full mb-[4px]",
                      { "!border-errorRed": errors?.first_name?.message }
                    )}
                    type="text"
                    {...methods.register("first_name", {
                      required: "This field may not be blank.",
                    })}
                  />
                </div>
                <div className="text-red-500 h-[12px]">
                  {errors?.first_name?.message && errors.first_name.message}
                </div>
              </div>
              <div className="col-span-6 ">
                <h1 className="text-1.4 pb-8 sm:pb-8 sm:1.2">Nom de famille</h1>
                <div className="">
                  <input
                    className={cx(
                      "border sm:w-full border-solid leading-15 text-1.2 rounded-8 px-16 py-17 w-full mb-[4px]",
                      { "!border-errorRed": errors?.last_name?.message }
                    )}
                    type="text"
                    {...methods.register("last_name", {
                      required: "This field may not be blank.",
                    })}
                  />
                </div>
                <div className="text-red-500 h-[12px]">
                  {errors?.last_name?.message && errors.last_name.message}
                </div>
              </div>
            </div>
            <div className="grid grid-cols-12 pb-16 flex gap-16 sm:grid-cols-6 ">
              <div className="col-span-6 ">
                <h1 className="text-1.4 pb-8 sm:pb-8 sm:1.2">Email</h1>
                <div className="">
                  <input
                    className={cx(
                      "border sm:w-full border-solid leading-15 text-1.2 rounded-8 px-16 py-17 w-full mb-[4px]",
                      { "!border-errorRed": errors?.email?.message }
                    )}
                    type="email"
                    {...methods.register("email", {
                      required: "This field may not be blank.",
                    })}
                  />
                </div>
                <div className="text-red-500 h-[12px]">
                  {errors?.email?.message && errors.email.message}
                </div>
              </div>
              <div className="col-span-6 ">
                <h1 className="text-1.4 pb-8 sm:pb-8 sm:1.2">Numéro de contact</h1>
                <div className="">
                  <input
                    className={cx(
                      "border sm:w-full border-solid leading-15 text-1.2 rounded-8 px-16 py-17 w-full mb-[4px]",
                      { "!border-errorRed": errors?.phone_number?.message }
                    )}
                    placeholder="Écrire Numéro de contact"
                    {...methods.register("phone_number", {
                      required: "This field may not be blank.",
                    })}
                  />
                </div>
                <div className="text-red-500 h-[12px]">
                  {errors?.phone_number?.message && errors.phone_number.message}
                </div>
              </div>
            </div>
            <div className="grid-cols-12 gap-16 pb-16 sm:grid-cols-6 ">
              <div className="col-span-6 ">
                <h1 className="text-1.4 pb-8 sm:pb-8 sm:1.2">emplacement</h1>
                <div className="w-full">
                  <input
                    className={cx(
                      "border sm:w-full border-solid leading-15 text-1.2 rounded-8 mb-[4px] px-16 py-17 w-full",
                      { "!border-errorRed": errors?.location?.message }
                    )}
                    type="location"
                    placeholder="Écrire emplacement"
                    {...methods.register("location", {
                      required: "This field may not be blank.",
                    })}
                  />
                </div>
                <div className="text-red-500 h-[12px]">
                  {errors?.location?.message && errors.location.message}
                </div>
              </div>
            </div>
            <div className="grid-cols-12 flex gap-16 sm:grid-cols-6 w-full">
              <div className="col-span-6 w-full">
                <h1 className="text-1.4 pb-8 sm:pb-8 sm:1.2">Mot de passe</h1>
                <div className="">
                  <input
                    className={cx(
                      "border sm:w-full border-solid leading-15 text-1.2 rounded-8 px-16 py-17 w-full mb-[4px]",
                      { "!border-errorRed": errors?.password?.message }
                    )}
                    type="password"
                    placeholder="Écrire mot de passe"
                    {...methods.register("password", {
                      required: "This field may not be blank.",
                    })}
                  />
                </div>
                <div className="text-red-500 h-[12px]">
                  {errors?.password?.message && errors.password.message}
                </div>
              </div>
              <div className="col-span-6 w-full">
                <h1 className="text-1.4 pb-8 sm:pb-8 sm:1.2">
                  Confirmer mot de passe
                </h1>
                <div className="">
                  <input
                    className={cx(
                      "border sm:w-full border-solid leading-15 text-1.2 rounded-8 px-16 py-17 w-full mb-[4px]",
                      { "!border-errorRed": errors?.confirm_password?.message }
                    )}
                    type="password"
                    placeholder="Écrire mot de passe"
                    {...methods.register("confirm_password", {
                      required: "This field may not be blank.",
                    })}
                  />
                </div>
              </div>
            </div>
            <div className="pb-10 pt-32">
              <div className="flex gap-16 grid-cols-12 w-full">
                <button
                  onClick={props.closeModal}
                  className="col-span-6 !font-medium w-full border border-green text-green p-16 rounded-8 leading-16 text-1.4 border-solid hover:bg-[#17A4541A]"
                >
                  Annuler
                </button>
                <button
                  disabled={isLoading}
                  type="submit"
                  className="col-span-6 !font-medium w-full border text-white bg-green p-16 rounded-8 leading-16 text-1.4 border-solid hover:bg-[#037f39]"
                >
                  Confirmer
                </button>
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
    </>
  );
};
