import cx from "classnames";
import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import styles from "./PasswordRecovery.module.scss";

export const PasswordRecovery = () => {
  const params = useParams();

  const navigate = useNavigate();

  const { register, handleSubmit } = useForm();
  const methods = useForm();
  const errors = methods.formState.errors;
  const onSubmit = (data) => {
    data.uid = params.uid;
    data.token = params.token;
    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...data,
      }),
    };

    fetch(
      process.env.REACT_APP_API_URL + "dj-rest-auth/password/reset/confirm/",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (!result.detail) {
          Object.keys(result).forEach((key) =>
            methods.setError(key, { type: "custom", message: result[key] })
          );
        } else {
          toast.success("Mot de passe téléchargé");
          navigate("/login");
          methods.reset();
        }
      });
  };

  return (
    <div className="flex grid-cols-12">
      <div className="min-w-[350px] h-[100vh] pt-[150px] md:pt-10 pb-10 bg-white rounded-12  md:w-full md:px-16 px-10 mb-10">
        <div className="text-center justify-center flex">
          <img
            alt="password recover"
            src={`${process.env.PUBLIC_URL}/images/logo1.svg`}
            className={cx(styles.logoStyle, "")}
          />
        </div>
        <div className="text-center pt-48 text-20 pb-48 sm:text-1.8">
          <span className="">Récupérer mot de passe</span>
        </div>
        <div className="">
          <div className="col-span-3 p-5 text-center ">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="col-span-1 pb-[10px] pt-10">
                <h1 className="flex justify-start text-1.4 pb-16 sm:text-1.2">
                  Nouveau Mot de passe
                </h1>
                <input
                  name="password"
                  type="password"
                  placeholder="Enter New Password"
                  className="p-4 w-full rounded-4 border mb-8"
                  {...register("new_password1")}
                ></input>
                <div className="text-red-500 h-[16px] text-start">
                  {errors?.new_password1?.message && errors.new_password1.message}
                </div>
              </div>
              <div className="col-span-1 pb-[12px]">
                <h1 className="flex justify-start text-1.4 pb-[12px] sm:text-1.2">
                  Confirmer Mot de passe
                </h1>
                <input
                  name="password"
                  type="password"
                  placeholder="Re-enter new password"
                  className="p-4 rounded-4 border w-full mb-8"
                  {...register("new_password2")}
                ></input>
                <div className="text-red-500 h-[16px] text-start">
                  {errors?.new_password2?.message && errors.new_password2.message}
                </div>
              </div>
              <div className="col-span-1 pb-7">
                <button
                  type="submit"
                  className="pt-4 pb-4 pr-7 pl-7 bg-green rounded-12 text-white w-full text-1.4 sm:text-1.2 hover:bg-[#037f39]"
                >
                  Réinitialiser Mot de passe
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="flex justify-end lg:w-4/5 md:hidden px-[50px]">
        <img
          alt="password recover"
          src={`${process.env.PUBLIC_URL}/images/recover-side.png`}
          className={cx(styles.img, "")}
        />
      </div>
    </div>
  );
};
