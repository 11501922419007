import cx from "classnames";
import React from "react";
import { PeopleAddButton } from "../PeopleAddButton/PeopleAddButton";
import styles from "./PeopleLabel.module.scss";

export const PeopleLabel = (props) => {
  const PeopleAddButtonItems = {
    text: "Créer un nouveau profil",
    icon: "Plus.svg",
    hoverIcon: "white.svg",
  };

  return (
    <>
      <div className="flex justify-between">
        <div className="flex gap-10 sm:gap-20 sm:mb-4 items-center">
          {props.showCheckbox && (
            <input
              onChange={(e) => {
                props.checkChangeHandler(e.target.checked, undefined, props.label);
              }}
              checked={props.allSelected}
              type="checkbox"
            />
          )}

          <div className="">
            <button
              disabled={!props.showDeleteButton}
              onClick={() => {
                props.openDelModal && props.openDelModal(true);
              }}
              className={cx(
                { [styles.activeDelete]: props.showDeleteButton },
                `border bg-white px-12 py-8 rounded-4 text-1.2 text-ashgray md:text-1.1 md:px-4 md:py-8 `
              )}
            >
              Supprimé
            </button>
          </div>
        </div>
        <div className="flex gap-10 sm:gap-4">
          <div className={styles.button}>
            <PeopleAddButton {...PeopleAddButtonItems} getPeople={props.getPeople} />
          </div>
        </div>
      </div>
    </>
  );
};
