import cx from "classnames";
import React, { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Button } from "../../components/Button/Button";
import { Calendar } from "../../components/Calendar/Calendar";
import { CLAUpcomingRS } from "../../components/CLAUpcomingRS/CLAUpcomingRS";
import { Dashboard } from "../../components/Dashboard/Dashboard";
import { DashboardTeacher } from "../../components/DashboardTeacher/DashboardTeacher";
import { NoData } from "../../components/NoData/NoData";
import { TeacherActivityGrid } from "../../components/TeacherActivityGrid/TeacherActivityGrid";
import { Workshop } from "../../components/Workshop/Workshop";
import { WorkshopPerformance } from "../../components/WorkshopPerformance/WorkshopPerformance";
import AuthContext from "../../context/AuthContext";
import styles from "./Overview.module.scss";

const Overview = () => {
  const { authTokens, user } = useContext(AuthContext);

  var size = 3;
  var upcomingSize = 4;
  var requestOptions = {
    method: "GET",
    redirect: "follow",
    headers: {
      Accept: "application/json",
      Authorization: `Token ${authTokens.token}`,
    },
  };

  const [studentWorkshop, setstudentWorkshop] = useState([]);
  const getUserWorkshopData = async () => {
    const response = await fetch(
      process.env.REACT_APP_API_URL + "user-workshops-list/",
      requestOptions
    ).then((response) => response.json());
    setstudentWorkshop(response);
  };
  const upcomingStudentWorkshops = [];
  studentWorkshop.forEach((workshop) => {
    if (
      new Date(workshop?.workshop?.workshop_date).getTime() > new Date().getTime()
    ) {
      upcomingStudentWorkshops.push(workshop.workshop);
    }
  });

  //CLA STAFF & TEACHERS
  const [upcomingWorkshopList, setupcomingWorkshopList] = useState([]);
  const getWorkshopList = async () => {
    const response = await fetch(
      process.env.REACT_APP_API_URL + "upcoming-workshops/",
      requestOptions
    ).then((response) => response.json());
    setupcomingWorkshopList(response);
  };

  //Total Workshop API
  const [totalWorkshops, settotalWorkshops] = useState(0);
  const getTotalWorkshopData = async () => {
    const response = await fetch(
      process.env.REACT_APP_API_URL + "your-workshops/",
      requestOptions
    ).then((response) => response.json());
    settotalWorkshops(response.workshops);
  };

  //Total Student API
  const [totalStudents, settotalStudents] = useState("-");
  const getTotalStudents = async () => {
    const response = await fetch(
      process.env.REACT_APP_API_URL + "student-in-workshop/",
      requestOptions
    ).then((response) => response.json());
    settotalStudents(response.Students);
  };

  //Active Workshops
  const [activeWorkshops, setactiveWorkshops] = useState("-");
  const getActiveWorkshops = async () => {
    const response = await fetch(
      process.env.REACT_APP_API_URL + "student-active-workshop/",
      requestOptions
    ).then((response) => response.json());
    setactiveWorkshops(response["Active Workshops"]);
  };

  //Total Students
  const [totalOverallStudents, settotalOverallStudents] = useState("-");
  const gettotalOverallStudents = async () => {
    const response = await fetch(
      process.env.REACT_APP_API_URL + "total-students/",
      requestOptions
    ).then((response) => response.json());
    settotalOverallStudents(response["Total Students"]);
  };

  //Total Teachers
  const [totalOverallTeachers, settotalOverallTeachers] = useState("-");
  const gettotalOverallTeachers = async () => {
    const response = await fetch(
      process.env.REACT_APP_API_URL + "total-teachers/",
      requestOptions
    ).then((response) => response.json());
    settotalOverallTeachers(response["Total teachers"]);
  };

  //Total Workshop
  const [totalOverallWorkshops, settotalOverallWorkshops] = useState("-");
  const getTotalOverallWorkshops = async () => {
    const response = await fetch(
      process.env.REACT_APP_API_URL + "total-workshops/",
      requestOptions
    ).then((response) => response.json());
    settotalOverallWorkshops(response["Total Workshops"]);
  };

  //Total Active Workshop
  const [totalActiveWorkshops, settotalActiveWorkshops] = useState("-");
  const getTotalActiveWorkshops = async () => {
    const response = await fetch(
      process.env.REACT_APP_API_URL + "total-active-workshops/",
      requestOptions
    ).then((response) => response.json());
    settotalActiveWorkshops(response["Total Active Workshops"]);
  };

  //Teacher Page --> CLA Staff Last Activity
  const [teacherStaffLastAvtivity, setteacherStaffLastAvtivity] = useState([]);
  const getTeacherStaffLastAvtivity = async () => {
    const response = await fetch(
      process.env.REACT_APP_API_URL + "cla-activities/?limit=true",
      requestOptions
    ).then((response) => response.json());
    setteacherStaffLastAvtivity(response);
  };

  //User API to get user details
  const [peopleData, setpeopleData] = useState([]);
  const getPeopleData = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}user/`,
      requestOptions
    ).then((response) => response.json());
    setpeopleData(response);
  };

  //Workshop API to get workshop Data
  const [upcomingWorkshop, setupcomingWorkshop] = useState([]);
  const getWorkshopData = async () => {
    var requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Token ${authTokens.token}`,
      },
    };
    const response = await fetch(
      process.env.REACT_APP_API_URL + "add-workshop/",
      requestOptions
    ).then((response) => response.json());
    setupcomingWorkshop(response);
  };

  //Object
  let TeacherActivities = teacherStaffLastAvtivity.map((activity) => {
    activity.user_details_object =
      activity.user_details &&
      peopleData.filter((user) => user.id === activity.user_details)[0];
    activity.workshop_object =
      activity.workshop &&
      upcomingWorkshop?.filter(
        (workshopdetail) => workshopdetail.id === activity.workshop
      )[0];

    return activity;
  });

  //Teacher Page --> CLA Student Last Activity
  const [studentLastActivity, setstudentLastActivity] = useState([]);
  const getstudentLastActivity = async () => {
    const response = await fetch(
      process.env.REACT_APP_API_URL + "students-activities/?limit=true",
      requestOptions
    ).then((response) => response.json());
    setstudentLastActivity(response);
  };

  //Object
  let StudentActivities = studentLastActivity.map((activity) => {
    activity.user_details_object =
      activity.user_details &&
      peopleData.filter((user) => user.id === activity.user_details)[0];
    activity.workshop_object =
      activity.workshop &&
      upcomingWorkshop?.filter(
        (workshopdetail) => workshopdetail.id === activity.workshop
      )[0];

    return activity;
  });
  //END

  //CLA STAFF LAST ACTIVITY --> (Students Page)
  const [claStaffLastActivity, setclaStaffLastActivity] = useState([]);
  const getclaStaffLastActivity = async () => {
    const response = await fetch(
      process.env.REACT_APP_API_URL + "cla-activities/?limit=true",
      requestOptions
    ).then((response) => response.json());
    setclaStaffLastActivity(response);
  };

  //Object
  let CLAstaffActivities = claStaffLastActivity.map((activity) => {
    activity.user_details_object =
      activity.user_details &&
      peopleData.filter((user) => user.id === activity.user_details)[0];
    activity.workshop_object =
      activity.workshop &&
      upcomingWorkshop?.filter(
        (workshopdetail) => workshopdetail.id === activity.workshop
      )[0];

    return activity;
  });
  //END

  //Students and teachers LAST ACTIVITY --> (CLA STAFF Page)
  const [studentTeacherLastActivity, setstudentTeacherLastActivity] = useState([]);
  const [loadingStudentTeacherLastActivity, setLoadingStudentTeacherLastActivity] =
    useState(false);
  const getstudentTeacherLastActivity = async () => {
    setLoadingStudentTeacherLastActivity(true);
    const response = await fetch(
      process.env.REACT_APP_API_URL + "students-teachers-activities/?limit=true",
      requestOptions
    ).then((response) => response.json());
    setLoadingStudentTeacherLastActivity(false);
    setstudentTeacherLastActivity(response);
  };

  //Object
  let StudentTeacherActivities = studentTeacherLastActivity.map((activity) => {
    activity.user_details_object =
      activity.user_details &&
      peopleData.filter((user) => user.id === activity.user_details)[0];
    activity.workshop_object =
      activity.workshop &&
      upcomingWorkshop?.filter(
        (workshopdetail) => workshopdetail.id === activity.workshop
      )[0];

    return activity;
  });
  //END

  //Workshop Performance --> (CLA Staff Page Only)
  const [workshopPerformance, setworkshopPerformance] = useState([]);
  const [loadingWorkshopPerformance, setloadingWorkshopPerformance] =
    useState(false);
  const getWorkshopPerformance = async () => {
    setloadingWorkshopPerformance(true);
    const response = await fetch(
      process.env.REACT_APP_API_URL + "workshops-performance/?limit=true",
      requestOptions
    ).then((response) => response.json());
    setloadingWorkshopPerformance(false);
    setworkshopPerformance(response);
  };
  const fetchData = () => {
    getUserWorkshopData();
    getWorkshopData();
    getWorkshopList();
    getTotalWorkshopData();
    getTotalStudents();
    getActiveWorkshops();
    gettotalOverallStudents();
    gettotalOverallTeachers();
    getTotalOverallWorkshops();
    getTotalActiveWorkshops();
    getTeacherStaffLastAvtivity();
    getPeopleData();
    getstudentLastActivity();
    getclaStaffLastActivity();
    getstudentTeacherLastActivity();
    getWorkshopPerformance();
  };
  useEffect(() => {
    fetchData();
  }, []);
  //End

  const dashboardSummaryItems = [
    {
      title: totalStudents,
      subTitle: "Nombre d’élèves",
      theme: "yellow",
    },

    {
      title: totalWorkshops,
      subTitle: "Nombre d’ateliers",
      theme: "pink",
    },
    {
      title: activeWorkshops,
      subTitle: "Ateliers programmés",
      theme: "green",
    },
  ];
  const dashboardTeacherItems = [
    {
      title: totalStudents,
      subTitle: "Nombre d’élèves",
      theme: "yellow",
    },
    {
      title: totalWorkshops,
      subTitle: "Nombre d’ateliers",
      theme: "pink",
    },
    {
      title: activeWorkshops,
      subTitle: "Ateliers programmés",
      theme: "green",
    },
    //End
  ];
  const dashboardCLAItems = [
    //Teacher and CLA Staff
    {
      title: totalOverallTeachers,
      subTitle: "Nombre de professeurs",
      theme: "yellow",
    },
    {
      title: totalOverallStudents,
      subTitle: "Nombre d’élèves",
      theme: "purple",
    },
    {
      title: totalOverallWorkshops,
      subTitle: "Nombre d’ateliers",
      theme: "pink",
    },
    {
      title: totalActiveWorkshops,
      subTitle: "Ateliers programmés",
      theme: "green",
    },
    //End
  ];

  return (
    <>
      <div className="grid grid-cols-12 lg:flex lg:flex-col md:mt-[16px]">
        <div className=" flex flex-col col-span-8 lg:col-span-12 lg:min-w-0 gap-10 md:gap-16">
          {/* Dashboard STudents Screen */}
          {user.user_type === 3 && (
            <section className="flex justify-between gap-10 md:p-16 p-10 bg-white rounded-12 flex-col smm:flex-row ">
              {dashboardSummaryItems.map((item, index) => (
                <Dashboard {...item} key={`dashboard-student-${index}`} />
              ))}
            </section>
          )}
          {/* Dashboard Teachers Screen */}
          {user.user_type === 2 && (
            <section className="flex justify-between gap-10 md:p-16 p-10 bg-white rounded-12 flex-col smm:flex-row ">
              {dashboardTeacherItems.map((item, index) => (
                <DashboardTeacher {...item} key={`dashboard-teacher-${index}`} />
              ))}
            </section>
          )}
          {/* Dashboard CLA STAFF Screen */}
          {user.user_type === 1 && (
            <section className="grid-cols-2 grid gap-10 md:p-16 p-10 md:gap-16 bg-white rounded-12 flex-col smm:flex-row sm:grid-cols-1 ">
              {dashboardCLAItems.map((item, index) => (
                <DashboardTeacher {...item} key={`dashboard-staff-${index}`} />
              ))}
            </section>
          )}
          {/* Workshops Students */}
          {user.user_type === 3 && (
            <section className="bg-white md:p-16 p-10  rounded-12">
              <div className="flex justify-between items-center  md:pt-8 mb-10 md:mb-[16px]">
                <div>
                  <h1 className="text-20 text-iconBlue leading-8">Ateliers</h1>
                </div>

                {studentWorkshop?.length > 3 && (
                  <NavLink className="" to={"/upcoming-workshops"}>
                    {!!studentWorkshop?.length && (
                      <div className="flex sm:hidden text-green text-1.4 md:text-1.2 hover:text-themeGreenHover">
                        <h2 className="">Voir tout</h2>
                        <img
                          alt="icon"
                          src={`${process.env.PUBLIC_URL}/images/sideicon.svg`}
                        ></img>
                      </div>
                    )}
                  </NavLink>
                )}
              </div>
              <div className="flex gap-10 flex-col smm:flex-row justify-center">
                {studentWorkshop?.length ? (
                  studentWorkshop
                    .slice(0, size)
                    .map((item, index) => (
                      <Workshop {...item} key={`workshop-students-${index}`} />
                    ))
                ) : (
                  <NoData />
                )}
              </div>
              {!!studentWorkshop?.length && (
                <div className="mmd:hidden md:pb-8 mt-[32px]">
                  <NavLink className="" to={"/last-activity"}>
                    <div className="flex smm:hidden text-green text-1.4 md:text-1.2 hover:text-themeGreenHover">
                      <button className="border flex bg-white border-green bg-opacity-10 p-8 rounded-8 w-full justify-center  text-1.4 md:text-1.2 text-green hover:bg-[#17A4541A]">
                        Voir tout
                      </button>
                    </div>
                  </NavLink>
                </div>
              )}
            </section>
          )}
          {/* Teacher CLA STAFF */}
          {user.user_type === 2 && (
            <section className="bg-white md:p-16 p-10 rounded-12">
              <div className="flex justify-between items-center  md:pt-8 mb-10 md:mb-[16px]">
                <div>
                  <h1 className="text-20 text-iconBlue leading-8 md:text-[18px]">
                    Workshops
                  </h1>
                </div>

                {upcomingWorkshop?.length > 3 && (
                  <NavLink className="" to={"/workshops"}>
                    <div className="flex sm:hidden text-green text-1.4 md:text-1.2  hover:text-themeGreenHover">
                      <h2 className=" text-1.4 leading-16 pr-2">Voir tout</h2>
                      <img
                        alt="icon"
                        src={`${process.env.PUBLIC_URL}/images/sideicon.svg`}
                        className="hover:fill-themeGreenHover hover:text-themeGreenHover"
                      ></img>
                    </div>
                  </NavLink>
                )}
              </div>
              <div className="flex gap-10  flex-col smm:flex-row justify-start">
                {upcomingWorkshop?.length ? (
                  upcomingWorkshop
                    .slice(0, size)
                    .map((item, index) => (
                      <Workshop {...item} key={`upcoming-teacher-${index}`} />
                    ))
                ) : (
                  <NoData />
                )}
              </div>
              {!!upcomingWorkshop.length && (
                <div className="mmd:hidden md:pb-8 mt-[32px]">
                  <NavLink className="" to={"/last-activity"}>
                    <div className="flex smm:hidden text-green text-1.4 md:text-1.2 hover:text-themeGreenHover">
                      <button className="border flex bg-white border-green bg-opacity-10 p-8 rounded-8 w-full justify-center  text-1.4 md:text-1.2 text-green hover:bg-[#17A4541A]">
                        Voir tout
                      </button>
                    </div>
                  </NavLink>
                </div>
              )}
            </section>
          )}
          {/* CLA Student Last Activity (Student Page) */}
          {user.user_type === 3 && (
            <section className="bg-white md:p-16 p-10 rounded-12 mb-10 md:mb-0">
              <NavLink className="" to={"/last-activity"}>
                <div className="flex justify-between items-center md:pt-8">
                  <div>
                    <h1 className="text-20 text-iconBlue leading-8 md:text-[18px] ">
                      Dernières activités des animateurs CLA
                    </h1>
                  </div>

                  {!!CLAstaffActivities.length && (
                    <div className="flex sm:hidden text-green text-1.4 md:text-1.2 hover:text-themeGreenHover">
                      <h2 className="">Voir tout</h2>
                      <img
                        alt="icon"
                        src={`${process.env.PUBLIC_URL}/images/sideicon.svg`}
                      ></img>
                    </div>
                  )}
                </div>
              </NavLink>
              <div className="">
                <TeacherActivityGrid data={CLAstaffActivities} />
              </div>
              {!!CLAstaffActivities.length && (
                <div className="mmd:hidden">
                  <NavLink className="" to={"/last-activity"}>
                    <div className="flex smm:hidden text-green text-1.4 md:text-1.2 hover:text-themeGreenHover">
                      <button className="border flex bg-white border-green bg-opacity-10 p-8 rounded-8 w-full justify-center  text-1.4 md:text-1.2 text-green hover:bg-[#17A4541A]">
                        Voir tout
                      </button>
                    </div>
                  </NavLink>
                </div>
              )}
            </section>
          )}
          {/* End */}
          {/* CLA Staff Last Activity (Teacher Page) */}
          {user.user_type === 2 && (
            <section className="bg-white md:p-16 p-10  rounded-12 ">
              <NavLink className="" to={"/last-activity"}>
                <div className="flex justify-between items-center md:pt-8">
                  <div>
                    <h1 className="text-20 text-iconBlue leading-8 md:text-[18px]">
                      Dernières activités des animateurs CLA
                    </h1>
                  </div>

                  {!!TeacherActivities.length && (
                    <div className="flex sm:hidden text-green text-1.4 md:text-1.2 hover:text-themeGreenHover">
                      <h2 className="">Voir tout</h2>
                      <img
                        alt="icon"
                        src={`${process.env.PUBLIC_URL}/images/sideicon.svg`}
                      ></img>
                    </div>
                  )}
                </div>
              </NavLink>
              <div className="">
                <TeacherActivityGrid data={TeacherActivities} />
              </div>
              {!!TeacherActivities.length && (
                <div className="mmd:hidden mt-[32px]">
                  <NavLink className="" to={"/last-activity"}>
                    <div className="flex smm:hidden text-green text-1.4 md:text-1.2 hover:text-themeGreenHover">
                      <button className="border flex bg-white border-green bg-opacity-10 p-8 rounded-8 w-full justify-center  text-1.4 md:text-1.2 text-green hover:bg-[#17A4541A]">
                        Voir tout
                      </button>
                    </div>
                  </NavLink>
                </div>
              )}
            </section>
          )}
          {/* End */}
          {/* CLA Student Last Activity (Teacher Page) */}
          {(user.user_type === 1 || user.user_type === 2) && (
            <section className="bg-white pt-10 md:p-16 p-10 rounded-12 ">
              <NavLink className="" to={"/workshop-performance"}>
                <div className="flex justify-between items-center md:pt-8">
                  <div>
                    <h1 className="text-20 text-iconBlue leading-8 md:text-[18px] ">
                      Résumé
                    </h1>
                  </div>

                  {!!workshopPerformance.length && (
                    <div className="flex sm:hidden text-green text-1.4 md:text-1.2 hover:text-themeGreenHover">
                      <h2 className="">Voir tout</h2>
                      <img
                        alt="icon"
                        src={`${process.env.PUBLIC_URL}/images/sideicon.svg`}
                      ></img>
                    </div>
                  )}
                </div>
              </NavLink>
              <div className="">
                <WorkshopPerformance
                  loading={loadingWorkshopPerformance}
                  data={workshopPerformance}
                />
              </div>
              {!!workshopPerformance.length && (
                <div className="mmd:hidden mt-[32px]">
                  <NavLink className="" to={"/workshop-performance"}>
                    <div className="flex smm:hidden text-green text-1.4 md:text-1.2 hover:text-themeGreenHover">
                      <button className="border flex bg-white border-green bg-opacity-10 p-8 rounded-8 w-full justify-center  text-1.4 md:text-1.2 text-green hover:bg-[#17A4541A]">
                        Voir tout
                      </button>
                    </div>
                  </NavLink>
                </div>
              )}
            </section>
          )}
          {user.user_type === 2 && (
            <section className="bg-white md:p-16 p-10  rounded-12 mb-8 md:mb-0">
              <NavLink className="" to={"/last-activity"}>
                <div className="flex justify-between items-center md:pt-8">
                  <div>
                    <h1 className="text-20 text-iconBlue leading-8 md:text-[18px]">
                      Dernières activités des élèves
                    </h1>
                  </div>

                  {!!StudentActivities.length && (
                    <div className="flex sm:hidden text-green text-1.4 md:text-1.2 hover:text-themeGreenHover">
                      <h2 className="">Voir tout</h2>
                      <img
                        alt="icon"
                        src={`${process.env.PUBLIC_URL}/images/sideicon.svg`}
                      ></img>
                    </div>
                  )}
                </div>
              </NavLink>
              <div className="">
                <TeacherActivityGrid data={StudentActivities} />
              </div>
              {!!StudentActivities.length && (
                <div className="mmd:hidden mt-[32px]">
                  <NavLink className="" to={"/last-activity"}>
                    <div className="flex smm:hidden text-green text-1.4 md:text-1.2 hover:text-themeGreenHover">
                      <button className="border flex bg-white border-green bg-opacity-10 p-8 rounded-8 w-full justify-center  text-1.4 md:text-1.2 text-green hover:bg-[#17A4541A]">
                        Voir tout
                      </button>
                    </div>
                  </NavLink>
                </div>
              )}
            </section>
          )}
          {/* CLA Staff Only --> Student And Teacher Last Activity */}
          {user.user_type === 1 && (
            <section className="bg-white md:p-16 p-10 rounded-12 mb-10 lg:mb-0 xlm:mb-8">
              <NavLink className="" to={"/last-activity"}>
                <div className="flex justify-between items-center">
                  <div>
                    <h1 className="text-20 text-iconBlue sm:pb-0 leading-8 md:text-1.8 md:pt-8 ">
                      Dernières activités – élèves et professeurs
                    </h1>
                  </div>

                  {!!StudentTeacherActivities.length && (
                    <div className="flex sm:hidden text-green text-1.4 md:text-1.2 hover:text-themeGreenHover">
                      <h2 className="">Voir tout</h2>
                      <img
                        alt="icon"
                        src={`${process.env.PUBLIC_URL}/images/sideicon.svg`}
                      ></img>
                    </div>
                  )}
                </div>
              </NavLink>
              <div className="">
                <TeacherActivityGrid
                  loading={loadingStudentTeacherLastActivity}
                  data={StudentTeacherActivities}
                />
              </div>
              {!!StudentTeacherActivities.length && (
                <div className="smm:hidden mt-[32px]">
                  <NavLink className="" to={"/last-activity"}>
                    <button className="border flex bg-white border-green bg-opacity-10 p-8 rounded-8 w-full justify-center text-1.4 md:text-1.2 text-green hover:bg-[#17A4541A]">
                      Voir tout
                    </button>
                  </NavLink>
                </div>
              )}
            </section>
          )}
          {/* End */}
        </div>

        <div className="col-span-4 mld:ml-10 mld:mb-10 gap-10 md:gap-16 mb-10 md:mb-[16px] lg:mt-[16px]">
          <div className={styles.wrapper}>
            <section className="bg-white md:p-16 p-10 rounded-12 mdg:flex mlg:flex-row mdg:gap-10">
              <div>
                <div>
                  <h1 className="text-20 text-blue md:text-1.8 pb-10 leading-20 md:pt-8">
                    Calendrier
                  </h1>
                </div>
                <div>
                  <Calendar
                    workshops={
                      user?.user_type === 3
                        ? upcomingStudentWorkshops
                        : upcomingWorkshopList
                    }
                  />
                </div>
              </div>

              {((!!upcomingStudentWorkshops?.length && user.user_type === 3) ||
                (!!upcomingWorkshopList?.length && user.user_type !== 3)) && (
                <div className="RIGHT-SIDE">
                  <div className={styles["rightSide-section"]}>
                    <h1 className="text-20 text-blue md:text-1.8 leading-20 md:pt-8">
                      Au programme
                    </h1>
                  </div>
                  {/* Student Only */}
                  {user.user_type === 3 && (
                    <div>
                      {upcomingStudentWorkshops.map((item, index) => (
                        <CLAUpcomingRS
                          {...item}
                          key={`upcoming-rs-students-${index}`}
                        />
                      ))}
                    </div>
                  )}
                  {/* CLA STAFF & TEACHER */}
                  {(user.user_type === 1 || user.user_type === 2) && (
                    <div>
                      {upcomingWorkshopList
                        .slice(0, upcomingSize)
                        .map((item, index) => (
                          <CLAUpcomingRS
                            {...item}
                            key={`upcoming-rs-staff-teacher-${index}`}
                          />
                        ))}
                    </div>
                  )}
                  {!!upcomingWorkshopList.length && (
                    <NavLink className="" to={"/upcoming-workshops"}>
                      <div
                        className={cx("w-full pt-16 sm:pt-8", styles.upcomingButton)}
                      >
                        <Button text="Voir tout" />
                      </div>
                    </NavLink>
                  )}
                </div>
              )}
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

export default Overview;
