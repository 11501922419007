import cx from "classnames";
import React from "react";
import { LoadingScreen } from "../LoadingScreen/LoadingScreen";
import { NoData } from "../NoData/NoData";
import { TeacherActivityRow } from "../TeacherActivityRow/TeacherActivityRow";
import styles from "./TeacherActivityGrid.module.scss";

export const TeacherActivityGrid = (props) => {
  return (
    <div className="col-span-3 md:p-0  px-0 pb-0">
      <div id={styles["teacher-activity-grid"]}>
        {props.loading ? (
          <div className="pb-4">
            <LoadingScreen />
          </div>
        ) : props.data?.length ? (
          <>
            <div className={cx(styles.row, "row", styles.activityGrid)}>
              <div
                className={cx(
                  styles.col,
                  "col",
                  "col-12",
                  "col-lg-4",
                  styles.teacher
                )}
              >
                Nom
              </div>
              <div
                className={cx(
                  styles.col,
                  "col",
                  "col-12",
                  "col-lg-4",
                  styles.activity
                )}
              >
                Atelier
              </div>
              <div
                className={cx(
                  styles.col,
                  "col",
                  "col-12",
                  "col-lg-4",
                  styles.activity
                )}
              >
                Activité
              </div>
            </div>

            {props.data?.map((item, index) => (
              <TeacherActivityRow key={`row-${index}`} {...item} />
            ))}
          </>
        ) : (
          <NoData />
        )}
      </div>
    </div>
  );
};
