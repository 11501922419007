import cx from "classnames";
import React from "react";
import styles from "./Label.module.scss";

export const Label = (props) => {
  const today = new Date();
  const workshopDate = new Date(props.workshopData?.date);
  var assignOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  // console.log("dhcsdcdsjsdl", props.hideLabels);
  const tempWorkshopDate = new Date(props.workshopData?.date).toLocaleString(
    "en-US",
    assignOptions
  );
  return (
    <div className={cx(styles.row, "row")}>
      <div className={cx("", styles.flex)}>
        {!props.hideLabels && props.workshopData.date && (
          <>
            {workshopDate > today ? (
              <span
                className={cx(
                  styles.due,
                  "whitespace-nowrap text-ellipsis overflow-hidden items-center text-1.2 leading-16"
                )}
              >
                <span className="">
                  <img
                    alt="clock icon"
                    src={`${process.env.PUBLIC_URL}/images/clock.svg`}
                    className={styles["img-style"]}
                  />
                </span>
                <span className="text-1.2 leading-14">{tempWorkshopDate}</span>
              </span>
            ) : (
              <span
                className={cx(
                  styles.completed,
                  "whitespace-nowrap text-ellipsis overflow-hidden items-center text-1.2 leading-16"
                )}
              >
                <span className="">
                  <img
                    alt="clock icon"
                    src={`${process.env.PUBLIC_URL}/images/tick.svg`}
                    className={styles["img-style"]}
                  />
                </span>
                <span className="text-1.2 leading-14">complété</span>
              </span>
            )}
          </>
        )}
        {props.workshopData.type && (
          <span
            className={cx(
              styles.blue,
              "whitespace-nowrap text-ellipsis overflow-hidden text-1.2 leading-16"
            )}
          >
            {props.workshopData.type}
          </span>
        )}
        {props.workshopData.level && (
          <span
            className={cx(
              styles.level,
              "whitespace-nowrap text-ellipsis overflow-hidden text-1.2 leading-16"
            )}
          >
            Niveau {props.workshopData.level}
          </span>
        )}
      </div>
    </div>
  );
};
