import cx from "classnames";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { WorkshopBanner } from "../../components/WorkshopBanner/WorkshopBanner";
import { WorkshopDetailContent } from "../../components/WorkshopDetailContent/WorkshopDetailContent";
import { WorkshopPeople } from "../../components/WorkshopPeople/WorkshopPeople";
import AuthContext from "../../context/AuthContext";
import styles from "./WorkshopDetail.module.scss";

export const WorkshopDetail = () => {
  const { authTokens } = useContext(AuthContext);
  const [selectedScreen, setselectedScreen] = useState("WorkshopDetailContent");

  var requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Token ${authTokens.token}`,
    },
  };
  const params = useParams();
  const [detailWorkshop, setdetailWorkshop] = useState([]);
  const getWorkshopData = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}workshop/${params.id}`,
      requestOptions
    ).then((response) => response.json());
    setdetailWorkshop(response);
  };
  useEffect(() => {
    getWorkshopData();
  }, [params.id]);

  return (
    <>
      <div className="">
        <div className="bg-white rounded-12">
          <div>
            <WorkshopBanner {...detailWorkshop} />
          </div>
        </div>
        <div className="flex justify-center gap-64 md:gap-16 bg-white mt-10 rounded-t-xl relative md:mt-[16px]">
          <button
            onClick={() => {
              setselectedScreen("WorkshopDetailContent");
            }}
            className={cx(
              "text-1.4 py-10",
              selectedScreen === "WorkshopDetailContent"
                ? styles.activeScreen
                : styles.normal
            )}
          >
            Atelier
          </button>
          <button
            onClick={() => {
              setselectedScreen("WorkshopPeople");
            }}
            className={cx(
              "text-1.4 py-10",
              selectedScreen === "WorkshopPeople"
                ? styles.activeScreen
                : styles.normal
            )}
          >
            Profils
          </button>
        </div>
        <hr></hr>
        {selectedScreen === "WorkshopDetailContent" && (
          <div className="">
            <WorkshopDetailContent
              attachments={detailWorkshop.workshopsimages}
              workshopId={params.id}
              detailWorkshop={getWorkshopData}
              getWorkshopData={getWorkshopData}
              setselectedScreen={setselectedScreen}
            />
          </div>
        )}
        {selectedScreen === "WorkshopPeople" && (
          <div className="grids grid-cols-12 bg-white mb-10 rounded-b-12">
            <WorkshopPeople
              workshopId={params.id}
              setselectedScreen={setselectedScreen}
            />
          </div>
        )}
      </div>
    </>
  );
};
