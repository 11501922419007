import cx from "classnames";
import React, { useContext, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import Modal from "react-modal";
import { toast } from "react-toastify";
import AuthContext from "../../context/AuthContext";
import useOutsideAlerter from "../../hooks/outsideClickHook";
import { DeleteCard } from "../DeleteCard/DeleteCard";
import { FileInput } from "../FileInput/FileInput";
import { NoData } from "../NoData/NoData";
import { WContentOptions } from "../WContentOptions/WContentOptions";
import styles from "./WorkshopContent.module.scss";

export const WorkshopContent = (props) => {
  const { authTokens, user } = useContext(AuthContext);
  const [isDrop, setIsDrop] = useState(false);
  const dropperRef = useRef(null);
  useOutsideAlerter(dropperRef, () => {
    setIsDrop(-1);
  });

  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal(referenceId) {
    setToBeDeleted(referenceId);
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }
  const DeleteItems = [
    {
      cross: "cross1.svg",
      icon: "DelTrash.svg",
      title: "Delete Attachment?",
      text: "Deleteing this attachment will move it to the trash permanentaly.",
    },
  ];

  const methods = useForm();
  const { setError } = useForm();
  const onSubmit = (data) => {
    let formData = new FormData();
    formData.append("workshop", parseInt(props.workshopId));
    formData.append("document", data.cover_img?.pop());
    var requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Token ${authTokens.token}`,
      },
      body: formData,
    };

    fetch(process.env.REACT_APP_API_URL + "workshop-view/", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (!result?.length) {
          Object.keys(result).forEach((key) =>
            setError(key, { type: "custom", message: result[key] })
          );
        } else {
          toast.success("Ajouté");
          methods.reset();
          props.detailWorkshop();
        }
      });
  };

  const [toBeDeleted, setToBeDeleted] = useState(null);
  const deleteContent = () => {
    var requestOptions = {
      method: "DELETE",
      redirect: "follow",
      headers: {
        Accept: "application/json",
        Authorization: `Token ${authTokens.token}`,
        "Content-Type": "application/json",
      },
    };
    fetch(
      `${process.env.REACT_APP_API_URL}workshop-view/${toBeDeleted}/`,
      requestOptions
    ).then((response) => {
      if (response.ok) {
        toast.success("Pièce-jointe supprimée");
        props.getWorkshopData();
        closeModal();
      }
    });
  };

  return (
    <>
      <div className=" mt-1.6">
        {user.user_type === 1 && (
          <div className="">
            <FormProvider {...methods}>
              <form
                onChange={methods.handleSubmit(onSubmit)}
                encType="multipart/form-data"
              >
                <FileInput
                  accept={{
                    "application/pdf": [".pdf"],
                    "application/msword": [".doc"],
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                      [".docx"],
                    "application/vnd.ms-excel": [".xls"],
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                      [".xlsx"],
                    "application/vnd.ms-powerpoint": [".ppt"],
                    "application/vnd.openxmlformats-officedocument.presentationml.presentation":
                      [".pptx"],
                    "text/csv": [".csv"],
                    "text/rtf": [".rtf"],
                    "application/vnd.oasis.opendocument.text": [".odt"],
                  }}
                  name="cover_img"
                  onChangeHandler={methods.handleSubmit(onSubmit)}
                />
              </form>
            </FormProvider>
          </div>
        )}
        {props.documentData?.length ? (
          props.documentData?.map((content, index) => (
            <div
              key={`document-data-${content.document}-${index}`}
              className={cx("assignment", "border rounded-12 mt-6")}
            >
              <div className="relative justify-between flex p-10 sm:px-12 sm:py-16 ssm:px-8">
                <div className="flex items-center w-[400] text-ellipsis whitespace-nowrap">
                  <div className="">
                    <img
                      alt="content icon"
                      src={`${process.env.PUBLIC_URL}/images/content.svg`}
                      className={cx(styles["img-style"])}
                    />
                  </div>
                  <div className="w-[70%] text-ellipsis whitespace-nowrap">
                    <div className={cx("lmd:text-16 text-1.4 pl-16 ", styles.text)}>
                      {content.document.split("/").pop()}
                    </div>
                    <div className="flex pl-16 sm:pl-8">
                      <div className="lmd:text-1.4 text-1.2 text-ashgray ssm:text-1.1">
                        {props.meta}
                      </div>
                      <div className="lmd:text-1.4 text-1.2 text-ashgray pl-5 ssm:pl-2 ssm:text-1.1">
                        {props.date}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex items-center">
                  <div className="flex gap-16 sm:gap-8 sm:hidden ">
                    <a
                      className=""
                      target={"_blank"}
                      href={content.document}
                      rel="noreferrer"
                    >
                      <button
                        className={cx(
                          styles.buttonBg,
                          "lmd:text-1.4 text-1.2 text-blue flex  items-center"
                        )}
                      >
                        <div className="lmd:pr-8 pr-6 md:pr-0">
                          <img
                            alt="download icon"
                            src={`${process.env.PUBLIC_URL}/images/download.svg`}
                            className={cx(styles["icon"])}
                          />
                        </div>
                        <div className="md:hidden">Télécharger</div>
                      </button>
                    </a>
                  </div>
                  <div className={cx("", styles.button)}>
                    <button
                      onClick={() => {
                        setIsDrop(index);
                      }}
                      className={styles.content}
                    >
                      <img
                        alt="options icon"
                        src={`${process.env.PUBLIC_URL}/images/dots.svg`}
                        className=""
                      />
                    </button>
                  </div>
                </div>
              </div>
              {isDrop === index && (
                <div ref={dropperRef} className={cx("relative")}>
                  <div
                    className={cx("bg-white rounded-8  py-12 absolute", styles.uc)}
                  >
                    <WContentOptions
                      document={content}
                      openModal={() => {
                        openModal(content.id);
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          ))
        ) : (
          <NoData />
        )}
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className={styles.DeleteProfile}
      >
        {DeleteItems.map((item, index) => (
          <DeleteCard
            key={`delete-card-${index}`}
            {...item}
            deleteHandler={deleteContent}
          />
        ))}
      </Modal>
    </>
  );
};
