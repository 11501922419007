import cx from "classnames";
import React, { useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { useFormContext } from "react-hook-form";
import { toast } from "react-toastify";

export const FileInput = (props) => {
  const { name } = props;
  const { register, unregister, setValue, watch } = useFormContext();
  const files = watch(name);
  const onDropAccepted = useCallback(
    (droppedFiles) => {
      setValue(name, droppedFiles, { shouldValidate: true });
      props.onChangeHandler && props.onChangeHandler();
    },
    [(setValue, name)]
  );
  const onDropRejected = useCallback((rejectedFiles) => {
    toast.error(rejectedFiles[0].errors[0].message);
  }, []);
  const { getRootProps, getInputProps } = useDropzone({
    onDropAccepted,
    onDropRejected,
    accept: props.accept,
    maxSize: 10000000,
  });
  useEffect(() => {
    register(name);
    return () => {
      unregister(name);
    };
  }, [register, unregister, name]);
  return (
    <>
      <div
        className={cx(
          "border-dashed border border-[#D4E6FF] rounded-12 text-center bg-bg",
          { "!border-red-500 ": props.isError }
        )}
      >
        {!(files?.length || props.default_value) && (
          <div className="h-[134px] flex flex-col justify-center">
            <div {...getRootProps()} className="cursor-pointer">
              <div className="flex justify-center">
                {" "}
                <img
                  alt="placeholder"
                  src={`${process.env.PUBLIC_URL}/images/photo.svg`}
                  className="mb-4 mt-4"
                />
              </div>

              <input
                {...props}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id={name}
                {...getInputProps()}
                multiple={false}
              />
              <div className={"w-full p-2"}>
                <p className="text-center my-2 text-1.2">
                  Dépôt de fichiers par glisser-déposer ou en cliquant sur
                  <span className="text-1.2 text-blue font-medium"> Parcourir</span>
                </p>
              </div>
            </div>
          </div>
        )}
        {/* Optionally you may display a preview of the file(s) */}
        <div className="relative">
          {(files?.length || props.defaultValue) && (
            <button
              onClick={() => {
                setValue(name, null);
              }}
              className="absolute right-[5px] top-[5px] z-[1]"
            >
              <img
                alt="icon"
                src={`${process.env.PUBLIC_URL}/images/cross.svg`}
                className="max-w-[20px]"
              />
            </button>
          )}
          {files?.length && (
            <div className="relative">
              <div key={`file-${files.name}`}>
                <img
                  src={
                    "File" in window && files[0] instanceof File
                      ? URL.createObjectURL(files[0])
                      : files
                  }
                  alt={""}
                  style={{
                    width: "100%",
                    height: "134px",
                    objectFit: "cover",
                    borderRadius: "12px",
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
