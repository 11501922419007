import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../context/AuthContext";
import { WorkshopPerformance } from "../WorkshopPerformance/WorkshopPerformance";

export const PerformanceCard = () => {
  const { authTokens, user } = useContext(AuthContext);

  //Workshop Performance --> (CLA Staff Page Only)
  const [workshopPerformance, setworkshopPerformance] = useState([]);
  const [loadingWorkshopPerformance, setloadingWorkshopPerformance] =
    useState(false);
  const getWorkshopPerformance = async () => {
    var requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Token ${authTokens.token}`,
      },
    };
    setloadingWorkshopPerformance(true);
    const response = await fetch(
      process.env.REACT_APP_API_URL + "workshops-performance/",
      requestOptions
    ).then((response) => response.json());
    setloadingWorkshopPerformance(false);
    // update the state
    setworkshopPerformance(response);
  };
  const fetchData = async () => {
    await getWorkshopPerformance();
  };
  useEffect(() => {
    fetchData();
  }, []);
  //End
  return (
    <>
      {user.user_type !== 3 && (
        <section className="bg-white pt-10 px-10 pb-12 mt-10 rounded-12 mb-10">
          <div>
            <h1 className="text-20 text-blue  leading-20 md:text-1.8">
              Workshop Performance
            </h1>
          </div>
          <div className="">
            <WorkshopPerformance
              loading={loadingWorkshopPerformance}
              data={workshopPerformance}
            />
          </div>
        </section>
      )}
    </>
  );
};
