import cx from "classnames";
import React, { useContext, useRef, useState } from "react";
import { useDrag } from "react-dnd";
import Modal from "react-modal";
import Select from "react-select";
import AuthContext from "../../context/AuthContext";
import useOutsideAlerter from "../../hooks/outsideClickHook";
import { StudentList } from "../StudentList/StudentList";
import { WorkshopUCMenu } from "../WorkshopUCMenu/WorkshopUCMenu";
import styles from "./WorkshopPeopleList.module.scss";

export const WorkshopPeopleList = (props) => {
  const { user } = useContext(AuthContext);
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }
  const removeHandler = () => {
    props.removeStudentFromWorkshopHandler(props.referenceId);
  };
  const [isDrop, setIsDrop] = useState(false);
  const [collected, dragRef] = useDrag(
    () => ({
      type: "card",
      item: { ...props },
      canDrag: () => props.canDrag && user?.user_type === 1,
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.5 : 1,
        data: props.id,
      }),
    }),
    []
  );

  const dropperRef = useRef(null);
  useOutsideAlerter(dropperRef, () => {
    setIsDrop(false);
  });

  const generatedOptions = props.availableVacancies;
  const onChangeHandler = (val) => {
    props.addStudentToGroupHandler(val.value, props.id);
  };
  const customStyles = {
    control: (base) => ({
      ...base,
      width: "122px",
      minHeight: 0,
      borderColor: "rgb(204 204 204 / 50%)",
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      padding: "5px 4px",
    }),
    clearIndicator: (styles) => ({
      ...styles,
      padding: "5px 4px",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    menuList: (base) => ({
      ...base,
      paddingTop: "0 !important",
      paddingBottom: "0 !important",
      borderRadius: "4px",
    }),
    option: (base) => ({
      ...base,
      color: "#020422",
    }),
  };
  return (
    <>
      <div className="" style={{ opacity: collected.opacity }}>
        <div className="pt-8 pb-8 ">
          <div
            className="border px-10 py-16 justify-between flex rounded-12 sm:px-20 sm:py-16 items-center"
            ref={dragRef}
            {...collected}
          >
            <div className="flex gap-4 justify-between items-center sm:gap-3 w-[calc(100%-45px)]">
              <div className="flex gap-x-4 w-[calc(100%-110px)]">
                <div className="rounded-12 overflow-hidden min-w-[40px] h-[40px]">
                  <img
                    alt="profile"
                    src={props.profile_pic}
                    className={cx(styles.profile)}
                  />
                </div>
                <div
                  className={cx(
                    "text-16 flex  md:text-1.4  flex-col w-[calc(100%-30px) whitespace-nowrap text-ellipsis overflow-hidden",
                    styles.name
                  )}
                >
                  <p
                    title={`${props.first_name} ${props.last_name}`}
                    className=" whitespace-nowrap text-ellipsis overflow-hidden"
                  >
                    {props.first_name} {props.last_name}
                  </p>
                  <p
                    title={props.email}
                    className="w-[200px] text-1.3 opacity-40  whitespace-nowrap text-ellipsis overflow-hidden leading-16 font-light"
                  >
                    {props.email}
                  </p>
                </div>
              </div>

              {user?.user_type === 1 && (
                <Select
                  styles={customStyles}
                  placeholder="Déménager à"
                  noOptionsMessage={() => "Please add Groups"}
                  options={generatedOptions}
                  onChange={onChangeHandler}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: "8px",
                    colors: {
                      ...theme.colors,
                      primary25: "#D4E6FF",
                      primary: "#93C1FF",
                      color: "#020422",
                    },
                  })}
                />
              )}
            </div>

            <div className="flex ml-4">
              <button
                onClick={() => {
                  setIsDrop(!isDrop);
                }}
              >
                <img
                  alt="options icon"
                  src={`${process.env.PUBLIC_URL}/images/dots.svg`}
                  className={cx(styles["img-style"])}
                />
              </button>
            </div>
          </div>
        </div>
      </div>
      {isDrop && (
        <div ref={dropperRef} className={cx("relative")}>
          <div className={cx("bg-white rounded-8 py-12 absolute", styles.uc)}>
            <WorkshopUCMenu
              data={props}
              openDelModal={removeHandler}
              openModal={openModal}
              removeLabel
            />
          </div>
        </div>
      )}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className={styles.EditProfile}
      >
        <StudentList
          label="Student"
          peopleData={props}
          getpeopleData={props.getpeopleData}
          closeModal={closeModal}
        />
      </Modal>
    </>
  );
};
