import React from "react";
import { DeleteButtons } from "../DeleteButtons/DeleteButtons";

export const DeleteUserCard = (props) => {
  const DeleteButtonItems = [
    {
      text: "Supprimer",
    },
  ];
  return (
    <>
      <div className="text-center relative bg-white p-10 rounded-12">
        <div className="flex justify-end">
          <button onClick={props.closeModal}>
            <img
              alt="cross icon"
              src={`${process.env.PUBLIC_URL}/images/cross.svg`}
              className=""
            />
          </button>
        </div>
        <div className="justify-center flex">
          <img
            alt="delete icon"
            src={`${process.env.PUBLIC_URL}/images/DelTrash.svg`}
            className=""
          />
        </div>
        <div className="text-16 pt-32 pb-8">Supprimer{props.multiple && "s"}</div>
        <div className="text-1.4 text-ashgray pb-10">
          Cette étape est irréversible. Êtes vous certain?
        </div>
        <div className="flex justify-center gap-16">
          {DeleteButtonItems.map((item, index) => (
            <DeleteButtons
              key={`delete-button-${index}`}
              {...item}
              closeModal={props.closeModal}
              clickHandler={props.deleteHandler}
            />
          ))}
        </div>
      </div>
    </>
  );
};
