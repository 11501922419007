import React, { useContext } from "react";
import AuthContext from "../../context/AuthContext";
import { WorkshopPeopleAddButton } from "../WorkshopPeopleAddButton/WorkshopPeopleAddButton";

export const WorkshopPeopleLabel = (props) => {
  const { user } = useContext(AuthContext);
  const PeopleAddButtonItems = [
    {
      text: "Ajouter élèves",
      icon: "Plus.svg",
    },
  ];

  return (
    <>
      <div className="flex justify-between ">
        <div className="flex gap-10 sm:gap-4 sm:flex-col">
          {user.user_type === 1 && (
            <div className="">
              {PeopleAddButtonItems.map((item, index) => (
                <WorkshopPeopleAddButton
                  key={`workshop-people-add-button-${index}`}
                  selectedOptions={props.selectedOptions}
                  {...item}
                  createWorkshopGroupsHandler={props.createWorkshopGroupsHandler}
                  workshopId={props.workshopId}
                  getPeople={props.getPeople}
                  workshopGroups={props.workshopGroups}
                  studentsInGroups={props.studentsInGroups}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
