import { useEffect } from "react";

/**
 * Hook that alerts clicks outside of the passed ref
 */
const useOutsideAlerter = (ref, callback) => {
  const isClickOutside = () => {
    let clickOutside = false;
    if (
      ref.forEach((elm) => {
        if (elm.current && !elm.current.contains(event.target)) {
          clickOutside = true;
        }
      })
    )
      return clickOutside;
  };
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref?.length) {
        if (isClickOutside()) {
          callback();
        }
      } else {
        if (ref.current && !ref.current.contains(event.target)) {
          callback();
        }
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
};
export default useOutsideAlerter;
