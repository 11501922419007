import cx from "classnames";
import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import { ProfileLabels } from "../ProfileLabels/ProfileLabels";
import styles from "./ProfileMenu.module.scss";

export const ProfileMenu = (props) => {
  const { user, logoutUser } = useContext(AuthContext);
  const ProfileSummaryItems = [
    {
      icon: "avatar1.svg",
      title: "Voir le un profil",
      link: "/student-profile",
    },
    {
      icon: "Question.svg",
      title: "Support",
    },
    {
      icon: "logout.svg",
      title: "Se déconnecter",
      link: "",
      onClickHandler: () => {
        logoutUser();
      },
    },
  ];
  return (
    <>
      <div
        className={cx(
          " flex bg-white rounded-xl w-[27rem] pt-10 pb-12 flex-col",
          styles.wrapper
        )}
        onClick={() => {
          props.setIsDrop(false);
        }}
      >
        {/* <div className="col-span-5 flex bg-white rounded-xl"> */}
        <NavLink to={"/student-profile"}>
          <div className="flex gap-4 px-10 hover:bg-[rgba(104,213,219,0.05)]">
            <div className={cx("flex")}>
              <img
                alt="profile icon"
                src={`${user.profile_pic}`}
                className={cx(styles["img-style"])}
              />
            </div>
            <div className="w-[calc(100%-50px)]">
              <div className="col-span-2 text-16 text-opacity-15 md:text-1.4 text-ellipsis whitespace-nowrap overflow-hidden">
                {user.first_name} {user.last_name}
              </div>
              <div
                className={cx(
                  " text-ashgray text-1.2 leading-14 md:text-1.1",
                  styles.email
                )}
              >
                {user.email}
              </div>
            </div>
          </div>
        </NavLink>
        <div className="col-span-5 pt-16">
          {ProfileSummaryItems?.map((item, index) => (
            <ProfileLabels key={`profile-labels-${index}`} {...item} />
          ))}
        </div>
      </div>

      {/* </div> */}
    </>
  );
};
