import cx from "classnames";
import React, { useContext, useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { EventWorkshop } from "../../components/EventWorkshop/EventWorkshop";
import { FileInput } from "../../components/FileInput/FileInput";
import AuthContext from "../../context/AuthContext";
import styles from "./RequestWorkshop.module.scss";

const customStyles = {
  control: (base) => ({
    ...base,
    padding: "6px 8px 6px 8px",
    borderColor: "#d4e6ff",
    "&:hover": {
      borderColor: "#2684ff",
    },
  }),
  menuList: (base) => ({
    ...base,
    paddingTop: "0 !important",
    paddingBottom: "0 !important",
    borderRadius: "4px",
  }),
  valueContainer: (base) => ({
    ...base,
    paddingTop: "0 !important",
    paddingBottom: "0 !important",
  }),
  singleValue: (base) => ({
    ...base,
    fontSize: "12px",
  }),
  option: (base) => ({
    ...base,
    color: "#020422",
  }),
};
export const RequestWorkshop = () => {
  const [workshopCats, setworkshopCats] = useState([]);
  const { authTokens, user } = useContext(AuthContext);
  const getCatsData = async () => {
    const response = await fetch(
      process.env.REACT_APP_API_URL + "add-category"
    ).then((response) => response.json());
    setworkshopCats(response);
  };
  useEffect(() => {
    getCatsData();
  }, []);

  //API CALL FOR REQUESTED WORKSHOPS
  const [requestedWorkshop, setrequestedWorkshop] = useState([]);
  const getRequestedData = async () => {
    var requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Token ${authTokens.token}`,
      },
    };
    const response = await fetch(
      process.env.REACT_APP_API_URL + `add-workshop/?requested_by=${user.id}`,
      requestOptions
    ).then((response) => response.json());
    setrequestedWorkshop(response);
  };
  useEffect(() => {
    getRequestedData();
  }, []);

  //Type API
  const [workshopType, setworkshopType] = useState([]);
  const getTypeData = async () => {
    const response = await fetch(process.env.REACT_APP_API_URL + "ws-types/").then(
      (response) => response.json()
    );
    setworkshopType(response);
  };
  useEffect(() => {
    getTypeData();
  }, []);

  //Level API
  const [workshopLevel, setworkshopLevel] = useState([]);
  const getLevelData = async () => {
    const response = await fetch(process.env.REACT_APP_API_URL + "ws-levels/").then(
      (response) => response.json()
    );
    setworkshopLevel(response);
  };
  useEffect(() => {
    getLevelData();
  }, []);

  const methods = useForm();
  const errors = methods.formState.errors;
  const navigate = useNavigate();
  const onSubmit = (data) => {
    console.log(data);
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      if (key === "cover_img") {
        formData.append(key, data[key] && data[key][0]);
      } else formData.append(key, data[key]);
    });

    //POST API
    var requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: {
        Accept: "application/json",
        Authorization: `Token ${authTokens.token}`,
      },
      body: formData,
    };

    fetch(process.env.REACT_APP_API_URL + "add-workshop/", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (!result.id) {
          Object.keys(result).forEach((key) =>
            methods.setError(key, { type: "custom", message: result[key] })
          );
        } else {
          toast.success("La demande d’atelier a bien été envoyé");
          methods.reset();
          navigate("/");
        }
      });
  };

  return (
    <>
      <div className=" bg-white rounded-12 p-10 mb-10 md:p-16">
        <h1 className="text-20 text-blue pb-8 md:text-1.8">Demander un atelier</h1>
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onSubmit)}
            encType="multipart/form-data"
          >
            {/* {JSON.stringify(errors)} */}
            <div className="grid grid-cols-12 md:grid-cols-6">
              <div className="col-span-6 pr-16 pt-10">
                <div className="pb-16">
                  <h1 className="pb-16 text-1.4 md:text-1.2 leading-16 md:pb-8">
                    Titre
                  </h1>
                  <input
                    className={cx(
                      "border rounded-8 px-16 py-17 max-w-full w-full mb-[4px] text-1.2 leading-14",
                      styles.input
                    )}
                    placeholder="Écrire le titre de l’atelier"
                    {...methods.register("workshop_name", {
                      required: "This field may not be blank.",
                    })}
                  />
                  <div className="text-red-500 h-[12px]">
                    {errors?.workshop_name?.message && errors.workshop_name.message}
                  </div>
                </div>
                <div className="pb-16">
                  <h1 className="pb-16 text-1.4 md:text-1.2 leading-16 md:pb-8">
                    Description
                  </h1>
                  <textarea
                    type="textarea"
                    className="border rounded-12 px-16 py-17 max-w-full w-full h-[132px] mb-[4px] text-1.2 leading-14"
                    placeholder="Écrire la Description de l’atelier
                    "
                    {...methods.register("description")}
                  />
                  <div className="text-red-500 h-[12px]">
                    {errors?.description?.message && errors.description.message}
                  </div>
                </div>
                <div className="pb-16">
                  <h1 className="pb-16 text-1.4 md:text-1.2 leading-16 md:pb-8">
                    Thème
                  </h1>
                  <label htmlFor="category"></label>
                  <Controller
                    style={styles.select}
                    className="border rounded-12 px-16 py-17 max-w-full  w-full"
                    control={methods.control}
                    name="theme"
                    rules={{
                      required: "This field may not be blank.",
                    }}
                    render={({ field: { onChange, ref } }) => (
                      <Select
                        className="react-select-container mb-[4px]"
                        inputRef={ref}
                        menuPlacement="auto"
                        styles={customStyles}
                        placeholder="Sélectionner thème.."
                        classNamePrefix="react-select"
                        getOptionLabel={(option) => option.category_name}
                        getOptionValue={(option) => option.id}
                        options={workshopCats}
                        onChange={(val) => onChange(val.id)}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: "8px",
                          colors: {
                            ...theme.colors,
                            primary25: "#D4E6FF",
                            primary: "#93C1FF",
                            color: "#020422",
                          },
                        })}
                      />
                    )}
                  />
                  <div className="text-red-500 h-[12px]">
                    {errors?.theme?.message && errors.theme.message}
                  </div>
                </div>
              </div>
              <div className="col-span-6 pl-16 md:pl-0 pt-10 md:pt-0">
                <div className="pb-16 ">
                  <h1 className="pb-16 text-1.4 md:text-1.2 leading-16 md:pb-8">
                    Image
                  </h1>

                  <FileInput
                    accept={{
                      "image/*": [".jpeg", ".jpg", ".png", ".webp"],
                    }}
                    name="cover_img"
                    {...methods.register("cover_img", {
                      required: "This field may not be blank.",
                    })}
                  />
                  <div className="text-red-500 mt-[4px] h-[12px]">
                    {errors?.cover_img?.message && errors.cover_img.message}
                  </div>
                </div>
                <div className="pb-16">
                  <h1 className="pb-16 text-1.4 md:text-1.2 leading-16 md:pb-8">
                    Type
                  </h1>
                  <label htmlFor="type"></label>
                  <Controller
                    style={styles.select}
                    className="border rounded-12 px-16 py-17 max-w-full w-full"
                    control={methods.control}
                    name="type"
                    rules={{
                      required: "This field may not be blank.",
                    }}
                    render={({ field: { onChange, ref } }) => (
                      <Select
                        className="react-select-container mb-[4px]"
                        inputRef={ref}
                        menuPlacement="auto"
                        styles={customStyles}
                        placeholder="Sélectionner type.."
                        classNamePrefix="react-select"
                        getOptionLabel={(option) => option.type_name}
                        getOptionValue={(option) => option.id}
                        options={workshopType}
                        onChange={(val) => onChange(val.id)}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: "8px",
                          colors: {
                            ...theme.colors,
                            primary25: "#D4E6FF",
                            primary: "#93C1FF",
                            color: "#020422",
                          },
                        })}
                      />
                    )}
                  />
                  <div className="text-red-500 h-[12px]">
                    {errors?.type?.message && errors.type.message}
                  </div>
                </div>
                <div className="">
                  <h1 className="pb-16 text-1.4 md:text-1.2 leading-16 md:pb-8">
                    Niveau
                  </h1>
                  <label htmlFor="level"></label>
                  <Controller
                    style={styles.select}
                    className="border rounded-12 px-16 py-17 max-w-full w-full"
                    control={methods.control}
                    name="level"
                    rules={{
                      required: "This field may not be blank.",
                    }}
                    render={({ field: { onChange, ref } }) => (
                      <Select
                        className="react-select-container mb-[4px]"
                        inputRef={ref}
                        menuPlacement="auto"
                        placeholder="Sélectionner niveau.."
                        styles={customStyles}
                        classNamePrefix="react-select"
                        getOptionLabel={(option) => option.level_name}
                        getOptionValue={(option) => option.id}
                        options={workshopLevel}
                        onChange={(val) => onChange(val.id)}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: "8px",
                          colors: {
                            ...theme.colors,
                            primary25: "#D4E6FF",
                            primary: "#93C1FF",
                            color: "#020422",
                          },
                        })}
                      />
                    )}
                  />
                  <div className="text-red-500 h-[12px]">
                    {errors?.level?.message && errors.level.message}
                  </div>
                </div>
              </div>
            </div>
            <div className="pt-10 pb-32 md:pt-16 md:pb-16">
              <button
                className={cx("text-1.4 md:text-1.2", styles.button)}
                type="submit"
              >
                Demander un atelier
              </button>
            </div>
          </form>
        </FormProvider>
      </div>
      <div className="bg-white p-10 rounded-12 md:p-16 mb-10 md:mb-[16px]">
        <div className="">
          <h1 className="text-20 text-blue mb-10 md:text-1.8 !font-medium">
            Ateliers demandés
          </h1>

          <div className="grid grid-cols-3 gap-10 md:gap-16 sm:flex sm:flex-col smd:grid-cols-2 xlm:grid-cols-2 ">
            {requestedWorkshop.map((item, index) => (
              <EventWorkshop
                hideOptions
                showStatus
                hideLabels={true}
                noLink
                {...item}
                key={`request-workshops-${index}`}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
