import React, { createContext, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();

  const [authTokens, setAuthTokens] = useState(() =>
    localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens"))
      : null
  );
  const [user, setUser] = useState(() =>
    localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null
  );
  const [loading, setLoading] = useState(true);

  const history = useNavigate();
  const updateUser = (user) => {
    localStorage.setItem("user", JSON.stringify(user));
    setUser(user);
  };
  const loginUser = async (email, password, setError) => {
    await fetch(process.env.REACT_APP_API_URL + "dj-rest-auth/login/", {
      method: "POST",
      redirect: "follow",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        password,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result?.key) {
          const tempTokens = {
            token: result.key,
          };
          setAuthTokens(tempTokens);
          if (result.user.user_type === 1) {
            result.user.user_type_str = "équipe CLA";
          } else if (result.user.user_type === 2) {
            result.user.user_type_str = "professeur";
          } else if (result.user.user_type === 3) {
            result.user.user_type_str = "élèves";
          }
          setUser(result.user);
          localStorage.setItem("authTokens", JSON.stringify(tempTokens));
          localStorage.setItem("user", JSON.stringify(result.user));
          navigate("/");
        } else {
          if (result.non_field_errors?.length) {
            setError(result.non_field_errors[0]);
          }
        }
        if (result?.key) {
          localStorage.setItem("user", JSON.stringify(result.user));
        }
      });
  };

  const registerUser = async (username, password, password2) => {
    const response = await fetch("http://127.0.0.1:8000/api/register/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username,
        password,
        password2,
      }),
    });
    if (response.status === 201) {
      history.push("/login");
    } else {
      alert("Something went wrong!");
    }
  };

  const logoutUser = () => {
    setAuthTokens(null);
    setUser(null);
    localStorage.removeItem("authTokens");
    localStorage.removeItem("user");
    navigate("/login");
  };

  const contextData = {
    user,
    setUser,
    authTokens,
    setAuthTokens,
    registerUser,
    loginUser,
    logoutUser,
    updateUser,
  };

  useEffect(() => {
    if (user && authTokens) {
      setUser(user);
    }
    setLoading(false);
  }, [authTokens, loading, user]);

  return (
    <AuthContext.Provider value={contextData}>
      {loading ? null : children}
    </AuthContext.Provider>
  );
};
