import cx from "classnames";
import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import AuthContext from "../../context/AuthContext";

export const AddTheme = (props) => {
  const { authTokens } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);

  let endpoint = "";
  let fieldName = "";
  if (props.type === "Thème") {
    endpoint = "add-category";
    fieldName = "category_name";
  } else if (props.type === "Format") {
    endpoint = "ws-types";
    fieldName = "type_name";
  } else if (props.level === "Niveau") {
    endpoint = "ws-levels";
    fieldName = "level_name";
  } else {
    endpoint = "add-schools";
    fieldName = "school_name";
  }

  const { register, handleSubmit, setError, reset } = useForm();

  const onSubmit = (data) => {
    var requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: {
        Accept: "application/json",
        Authorization: `Token ${authTokens.token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...data,
      }),
    };
    setIsLoading(true); //Disable/able the Add Button
    fetch(`${process.env.REACT_APP_API_URL}${endpoint}/`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (!result.id) {
          Object.keys(result).forEach((key) =>
            setError(key, { type: "custom", message: result[key] })
          );
        } else {
          toast.success(`${props.type} ajouté`);
          props.getworkshopTheme();
          props.getTypeData();
          props.getLevelData();
          props.getSchool();
          props.closeModal();
          reset();
        }
        setIsLoading(false);
      })
      .catch((error) => {
        toast.error(error.message);
        setIsLoading(false);
      });
  };

  return (
    <div className="bg-white p-10 rounded-12">
      <div className="pt-40 pb-40 flex justify-center">
        <img
          alt="logo"
          src={`${process.env.PUBLIC_URL}/images/logo1.svg`}
          className={cx("logoStyle", "")}
        />
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="">
        <h1 className="pb-16 text-1.4">Ateliers {props.type}</h1>
        <input
          {...register(fieldName)}
          type="text"
          placeholder={`Entrer un ${props.type}`}
          className="border rounded-8 px-16 py-17 w-full"
        ></input>
        <div className="gap-16 flex pt-48 justify-center">
          <button
            onClick={props.closeModal}
            className="border flex !font-medium bg-white px-10 py-8 rounded-8 text-green border-green text-1.4 w-full justify-center hover:bg-[#17A4541A]"
          >
            Annuler
          </button>
          <button
            type="submit"
            disabled={isLoading}
            className="border flex !font-medium bg-green px-10 py-8 rounded-8 text-white text-1.4 w-full justify-center hover:bg-[#037f39]"
          >
            jouter un {props.type}
          </button>
        </div>
      </form>
    </div>
  );
};
