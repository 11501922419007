import cx from "classnames";
import React, { useContext } from "react";
import { toast } from "react-toastify";
import AuthContext from "../../context/AuthContext";
import styles from "./WorkshopUCMenu.module.scss";

export const WorkshopUCMenu = (props) => {
  const { user } = useContext(AuthContext);
  return (
    <>
      <div className="">
        <div className=" px-16 flex gap-1.2 py-6 items-center hover:bg-[rgba(104,213,219,0.05)]">
          <a className="flex gap-1.2" href={`mailto:${props?.data?.email}`}>
            <div className="">
              <img
                alt="down arrow icon"
                src={`${process.env.PUBLIC_URL}/images/sms.svg`}
                className={cx(styles["img-style"])}
              />
            </div>
            <p className="text-1.2">Email</p>
          </a>
        </div>
        <button
          onClick={() => {
            navigator.clipboard.writeText(
              `${window?.location?.origin}${process.env.REACT_APP_SUBPAGE}/student-profile/${props?.data?.id}`
            );
            toast.success("Copié dans le presse-papier");
          }}
          className="px-16 flex gap-1.2 py-6 items-center hover:bg-[rgba(104,213,219,0.05)]"
        >
          <div className="">
            <img
              alt="link circle icon"
              src={`${process.env.PUBLIC_URL}/images/link-circle.svg`}
              className={cx(styles["img-style"])}
            />
          </div>
          <p className="text-1.2">Copier le lien</p>
        </button>
        {user.user_type === 1 && (
          <button
            onClick={props.openModal}
            className="px-16 flex gap-1.2 py-6 items-center w-full hover:bg-[rgba(104,213,219,0.05)]"
          >
            <div className="">
              <img
                alt="down arrow icon"
                src={`${process.env.PUBLIC_URL}/images/edit2.svg`}
                className={cx(styles["img-style"])}
              />
            </div>
            <p className="text-1.2">Éditer</p>
          </button>
        )}
        {user?.user_type === 1 && (
          <div className="px-16 flex w-full py-6 items-center hover:bg-[rgba(104,213,219,0.05)]">
            <button onClick={props.openDelModal} className="flex w-full gap-1.2">
              <div className="">
                <img
                  alt="delete icon"
                  src={`${process.env.PUBLIC_URL}/images/Trash.svg`}
                  className={cx(styles["img-style"])}
                />
              </div>
              <p className="text-1.2">{props.removeLabel ? "Remove" : "Delete"}</p>
            </button>
          </div>
        )}
      </div>
    </>
  );
};
