import React from "react";

export const StudentProfileMenu = (props) => {
  return (
    <div className="">
      <div className="flex gap-16 sm:gap-8 items-center">
        <div className="min-w-[40px]">
          <img
            alt="icon"
            src={`${process.env.PUBLIC_URL}/images/${props.icon}`}
            className=""
          />
        </div>
        <div className="w-[calc(100%-60px)]">
          <div className=" pb-1 text-1.2 text-ashgray sm:text-1.1">
            {props.subTitle}
          </div>
          <div className="w-full text-ellipsis overflow-hidden whitespace-nowrap text-1.4 sm:text-1.2">
            {props.text}
          </div>
        </div>
      </div>
    </div>
  );
};
