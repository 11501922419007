import cx from "classnames";
import React, { useContext, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import useOutsideAlerter from "../../hooks/outsideClickHook";
import { LeftSideBar } from "../LeftSideBar/LeftSideBar";
import { ProfileMenu } from "../ProfileMenu/ProfileMenu";
import { SearchBox } from "../SearchBox/SearchBox";
import styles from "./MobileMenu.module.scss";

export const MobileMenu = (props) => {
  const { user } = useContext(AuthContext);

  const MainItems = [
    {
      text: "Vue d’ensemble",
      link: "/",
      icon: "category.svg",
      activeIcon: "active-category.svg",
      visibility: [1, 2, 3],
    },
    {
      text: "Ateliers",
      link: "/workshops",
      icon: "note.svg",
      activeIcon: "active-note.svg",
      visibility: [1, 2, 3],
    },
    {
      text: "Ateliers programmés",
      link: "/upcoming-workshops",
      icon: "calendar.svg",
      activeIcon: "active-calendar.svg",
      visibility: [1, 2, 3],
    },
    {
      text: "Ateliers dupliqués",
      link: "/cloned-workshops",
      icon: "Clone.svg",
      activeIcon: "ActiveClone.svg",
      visibility: [1],
    },
    {
      text: "Demander un atelier",
      link: "/workshop-request",
      icon: "add-circle.svg",
      activeIcon: "active-add-circle.svg",
      visibility: [2],
    },
    {
      text: "Ateliers demandés",
      link: "/requested-workshops",
      icon: "add-circle.svg",
      activeIcon: "active-add-circle.svg",
      visibility: [1],
    },
    {
      text: "Créer un atelier",
      link: "/create-workshop",
      icon: "add-circle.svg",
      activeIcon: "active-add-circle.svg",
      visibility: [1],
    },
    {
      text: "Profils",
      link: "/people",
      icon: "profile-2user.svg",
      activeIcon: "active-profile-2user.svg",
      visibility: [1],
    },
    {
      text: "Paramètres",
      link: "/workshop-settings",
      icon: "setting.svg",
      activeIcon: "active-setting.svg",
      visibility: [1],
    },
  ];
  const FooterItems = [
    {
      text: "Se déconnecter",
      onClickHandler: () => {},
      link: "",
      icon: "logout.png",
      showAtBottom: true,
    },
  ];
  const ProfileSummary = [
    {
      profileImage: "profile.png",
      profileName: "Georgie Ellis",
      email: "georgie@cla.ulb.be",
    },
  ];
  const [isDrop, setIsDrop] = useState(false);
  const dropperRef = useRef(null);
  const dropperRef2 = useRef(null);
  useOutsideAlerter([dropperRef, dropperRef2], () => {
    setIsDrop(false);
  });

  const [isSearchDrop, setIsSearchDrop] = useState(false);
  const dropperSearchRef = useRef(null);
  const dropperSearchRef2 = useRef(null);
  useOutsideAlerter([dropperSearchRef, dropperSearchRef2], () => {
    setIsSearchDrop(false);
  });
  return (
    <>
      <div className="flex items-center bg-white mb-10 md:mb-[16px] gap-10 px-10 py-16 rounded-xl justify-between">
        <div className=" pl-0 flex items-center gap-10">
          <div
            onClick={() => {
              props.setIsShown(!props.isShown);
            }}
            className={cx("mobile-menu, space-y-2")}
          >
            <div className="w-8 h-[1.5px] bg-[#0D4B95]"></div>
            <div className="w-[80%] h-[1.5px] bg-[#0D4B95]"></div>
            <div className="w-8 h-[1.5px] bg-[#0D4B95]"></div>
          </div>
          <NavLink to={"/"}>
            <img
              alt="logo"
              src={`${process.env.PUBLIC_URL}/images/${props.logo}`}
              className="max-w-50 "
            />
          </NavLink>
        </div>
        <div className={cx("justify-end flex w-[70%]", styles.searchbar)}>
          <button
            onClick={() => {
              setIsSearchDrop(!isSearchDrop);
            }}
            className="relative w-8"
          >
            <img
              alt="icon"
              src={`${process.env.PUBLIC_URL}/images/${props.icon}`}
              className=""
            />
          </button>
        </div>
        <div className="flex items-center gap-10">
          <NavLink className="" to={"/student-profile"}>
            <div className="text-right max-w-md">
              <img
                alt="icon"
                src={`${user.profile_pic}`}
                className={cx(styles["img-style"])}
              />
            </div>
          </NavLink>
          <div className="">
            <div
              className={cx(
                styles.dropdown,
                "border-none bg-transparent items-center min-w-[2.4rem]"
              )}
            >
              <button
                onClick={() => {
                  setIsDrop(!isDrop);
                }}
                ref={dropperRef}
                className=""
              >
                <img
                  alt="options icon"
                  src={`${process.env.PUBLIC_URL}/images/${props.dotIcon}`}
                  className={cx(styles.dropdown, "inline-block ")}
                />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className={cx(props.isShown ? styles["mobile-active"] : styles.normal, {
          [styles.sticky]: props.stickyHeader,
        })}
      >
        <LeftSideBar
          setIsShown={props.setIsShown}
          mainNav={MainItems}
          footerNav={FooterItems}
        />
      </div>
      {isDrop && (
        <div
          ref={dropperRef2}
          className={cx(
            "absolute right-0 md:top-[83px] md:right-[17px] z-[2]",
            styles.profile
          )}
        >
          <div>
            {ProfileSummary.map((item, index) => (
              <ProfileMenu
                setIsDrop={setIsDrop}
                key={`profile-menu-${index}`}
                {...item}
              />
            ))}
          </div>
        </div>
      )}

      {isSearchDrop && (
        <div
          ref={dropperSearchRef2}
          className={cx(
            "absolute justify-center items-center w-[calc(100%-32px)] top-[80px]",
            styles.profile
          )}
        >
          <div className="p-16 bg-white rounded-8 mt-[4px] shadow-search">
            <SearchBox />
          </div>
        </div>
      )}
    </>
  );
};
