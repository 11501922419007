import React, { useContext, useEffect, useState } from "react";
import { TeacherActivityGrid } from "../../components/TeacherActivityGrid/TeacherActivityGrid";
import AuthContext from "../../context/AuthContext";

export const LastActivityCard = () => {
  const { authTokens, user } = useContext(AuthContext);
  var requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Token ${authTokens.token}`,
    },
  };
  //User API to get user details
  const [peopleData, setpeopleData] = useState([]);
  const getPeopleData = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}user/`,
      requestOptions
    ).then((response) => response.json());
    setpeopleData(response);
  };

  const [upcomingWorkshop, setupcomingWorkshop] = useState([]);
  const getWorkshopData = async () => {
    const response = await fetch(
      process.env.REACT_APP_API_URL + "add-workshop/",
      requestOptions
    ).then((response) => response.json());
    setupcomingWorkshop(response);
  };
  //Students and Teachers Activity (CLA Staff Page)
  const [studentTeacherLastActivity, setstudentTeacherLastActivity] = useState([]);
  const [loadingStudentTeacherLastActivity, setLoadingStudentTeacherLastActivity] =
    useState(false);
  const getstudentTeacherLastActivity = async () => {
    var requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Token ${authTokens.token}`,
      },
    };
    setLoadingStudentTeacherLastActivity(true);
    const response = await fetch(
      process.env.REACT_APP_API_URL + "students-teachers-activities/",
      requestOptions
    ).then((response) => response.json());
    setLoadingStudentTeacherLastActivity(false);
    setstudentTeacherLastActivity(response);
  };
  //Object
  let StudentTeacherActivities = studentTeacherLastActivity.map((activity) => {
    activity.user_details_object =
      activity.user_details &&
      peopleData.filter((user) => user.id === activity.user_details)[0];
    activity.workshop_object =
      activity.workshop &&
      upcomingWorkshop?.filter(
        (workshopdetail) => workshopdetail.id === activity.workshop
      )[0];

    return activity;
  });
  //Teacher Page --> CLA Student Last Activity
  const [studentLastActivity, setstudentLastActivity] = useState([]);
  const getstudentLastActivity = async () => {
    const response = await fetch(
      process.env.REACT_APP_API_URL + "students-activities",
      requestOptions
    ).then((response) => response.json());
    setstudentLastActivity(response);
  };
  //Teacher Page --> CLA Staff Last Activity
  const [teacherStaffLastAvtivity, setteacherStaffLastAvtivity] = useState([]);
  const getTeacherStaffLastAvtivity = async () => {
    const response = await fetch(
      process.env.REACT_APP_API_URL + "cla-activities/?limit=true",
      requestOptions
    ).then((response) => response.json());
    setteacherStaffLastAvtivity(response);
  };
  //Object
  let TeacherActivities = teacherStaffLastAvtivity.map((activity) => {
    activity.user_details_object =
      activity.user_details &&
      peopleData.filter((user) => user.id === activity.user_details)[0];
    activity.workshop_object =
      activity.workshop &&
      upcomingWorkshop?.filter(
        (workshopdetail) => workshopdetail.id === activity.workshop
      )[0];

    return activity;
  });
  //Object
  let StudentActivities = studentLastActivity.map((activity) => {
    activity.user_details_object =
      activity.user_details &&
      peopleData.filter((user) => user.id === activity.user_details)[0];
    activity.workshop_object =
      activity.workshop &&
      upcomingWorkshop?.filter(
        (workshopdetail) => workshopdetail.id === activity.workshop
      )[0];

    return activity;
  });
  //END
  //CLA STAFF LAST ACTIVITY --> (Students Page)
  const [claStaffLastActivity, setclaStaffLastActivity] = useState([]);
  const getclaStaffLastActivity = async () => {
    const response = await fetch(
      process.env.REACT_APP_API_URL + "cla-activities/?limit=true",
      requestOptions
    ).then((response) => response.json());
    setclaStaffLastActivity(response);
  };
  //Object
  let CLAstaffActivities = claStaffLastActivity.map((activity) => {
    activity.user_details_object =
      activity.user_details &&
      peopleData.filter((user) => user.id === activity.user_details)[0];
    activity.workshop_object =
      activity.workshop &&
      upcomingWorkshop?.filter(
        (workshopdetail) => workshopdetail.id === activity.workshop
      )[0];
    return activity;
  });
  //END
  const fetchData = async () => {
    await getstudentTeacherLastActivity(),
      getPeopleData(),
      getWorkshopData(),
      getstudentLastActivity(),
      getTeacherStaffLastAvtivity(),
      getclaStaffLastActivity();
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <>
      {user.user_type === 1 && (
        <section className="bg-white pt-10 px-10 pb-12 mt-10 rounded-12 mb-10">
          <div>
            <h1 className="text-20 text-blue md:text-1.8">
              Students And Teachers Last Activity
            </h1>
          </div>
          <div className="">
            <TeacherActivityGrid
              loading={loadingStudentTeacherLastActivity}
              data={StudentTeacherActivities}
            />
          </div>
        </section>
      )}
      {user.user_type === 2 && (
        <section className="bg-white pt-10 px-10 pb-12 mt-10 rounded-12 mb-10">
          <div>
            <h1 className="text-20 text-blue md:text-1.8">Students Activity</h1>
          </div>
          <div className="">
            <TeacherActivityGrid data={StudentActivities} />
          </div>
        </section>
      )}
      {user.user_type === 2 && (
        <section className="bg-white pt-10 px-10 pb-12 mt-10 rounded-12 mb-10">
          <div>
            <h1 className="text-20 text-blue md:text-1.8">CLA Staff Activity</h1>
          </div>
          <div className="">
            <TeacherActivityGrid data={TeacherActivities} />
          </div>
        </section>
      )}
      {user.user_type === 3 && (
        <section className="bg-white p-10 mt-10 rounded-12 mb-10">
          <div>
            <h1 className="text-20 text-blue md:text-1.8 md:mb-5 leading-8">
              CLA Staff Activity
            </h1>
          </div>
          <div className="">
            <TeacherActivityGrid data={CLAstaffActivities} />
          </div>
        </section>
      )}
    </>
  );
};
