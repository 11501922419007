import cx from "classnames";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { AddTheme } from "../../components/AddTheme/AddTheme";
import { LevelCard } from "../../components/LevelCard/LevelCard";
import { LoadingScreen } from "../../components/LoadingScreen/LoadingScreen";
import { NoData } from "../../components/NoData/NoData";
import { SchoolCard } from "../../components/SchoolCard/SchoolCard";
import { ThemesCard } from "../../components/ThemesCard/ThemesCard";
import { TypesCard } from "../../components/TypesCard/TypesCard";
import styles from "./WorkshopSettings.module.scss";

export const WorkshopSettings = () => {
  const [workshopTheme, setworkshopTheme] = useState([]);
  const [typeToAdd, setTypeToAdd] = useState(null);
  const [loading, setLoading] = useState(false);

  const getworkshopTheme = async () => {
    setLoading(true);
    const response = await fetch(
      process.env.REACT_APP_API_URL + "add-category"
    ).then((response) => response.json());
    setLoading(false);
    setworkshopTheme(response);
  };
  useEffect(() => {
    getworkshopTheme();
  }, []);

  const [workshopType, setworkshopType] = useState([]);
  const getTypeData = async () => {
    setLoading(true);
    const response = await fetch(process.env.REACT_APP_API_URL + "ws-types/").then(
      (response) => response.json()
    );
    setLoading(false);
    setworkshopType(response);
  };
  useEffect(() => {
    getTypeData();
  }, []);

  const [workshopLevel, setworkshopLevel] = useState([]);
  const getLevelData = async () => {
    setLoading(true);
    const response = await fetch(process.env.REACT_APP_API_URL + "ws-levels/").then(
      (response) => response.json()
    );
    setLoading(false);
    setworkshopLevel(response);
  };
  useEffect(() => {
    getLevelData();
  }, []);

  const [schoolOptions, setschoolOptions] = useState([]);
  const getSchool = async () => {
    const response = await fetch(process.env.REACT_APP_API_URL + "add-schools").then(
      (response) => response.json()
    );
    setschoolOptions(response);
  };
  useEffect(() => {
    getSchool();
  }, []);

  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : workshopTheme?.length || workshopLevel?.length || workshopType?.length ? (
        <div className="gap-10 my-10 md:my-[16px] md:gap-16 ">
          <div className="grid grid-cols-2 gap-10 sm:grid-cols-1 md:gap-[16px]">
            <div
              className={cx(
                "bg-white p-10 md:p-16 mb-10 md:mb-[16px] sm:mb-[0] rounded-12 flex flex-col justify-between ",
                styles.card
              )}
            >
              <div className="h-[calc(100%-59px)]">
                <h1 className="text-20 text-blue pb-16 md:text-1.8 md:pb-8">
                  Thèmes
                </h1>
                <div className="-mr-[15px] pr-[15px] ">
                  {workshopTheme.map((item, index) => (
                    <ThemesCard
                      key={`themes-card-${index}`}
                      {...item}
                      getworkshopTheme={getworkshopTheme}
                      workshopTheme={workshopTheme}
                    />
                  ))}
                </div>
              </div>
              <div className=" pt-10 md:px-0 md:pb-8">
                <button
                  onClick={() => {
                    setTypeToAdd("Thème");
                    openModal();
                  }}
                  className="border flex bg-white border-green bg-opacity-10 p-8 rounded-8 w-full justify-center text-1.4 text-green hover:bg-[#17A4541A] md:text-1.2"
                >
                  <img
                    alt="add new theme"
                    src={`${process.env.PUBLIC_URL}/images/Plus.svg`}
                    className="max-w-20"
                  />
                  Ajouter thème
                </button>
              </div>
            </div>
            <div
              className={cx(
                "bg-white p-10 md:p-16  mb-10 md:mb-[16px] rounded-12 flex flex-col justify-between",
                styles.card
              )}
            >
              <div className="h-[calc(100%-59px)]">
                <h1 className="text-20 text-blue pb-16 md:pb-8 md:text-1.8">
                  Format
                </h1>
                <div className="-mr-[15px] pr-[15px] ">
                  {workshopType.map((item, index) => (
                    <TypesCard
                      key={`types-card-${index}`}
                      {...item}
                      getTypeData={getTypeData}
                    />
                  ))}
                </div>
              </div>
              <div className=" pt-10 md:px-0 md:pb-8">
                <button
                  onClick={() => {
                    setTypeToAdd("Format");
                    openModal();
                  }}
                  className="border flex bg-white border-green bg-opacity-10 p-8 rounded-8 w-full justify-center  text-1.4 md:text-1.2 text-green hover:bg-[#17A4541A]"
                >
                  <img
                    alt="add new type"
                    src={`${process.env.PUBLIC_URL}/images/Plus.svg`}
                    className="max-w-20"
                  />
                  Ajouter format
                </button>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-10 sm:grid-cols-1 md:gap-[16px]">
            <div
              className={cx(
                "bg-white p-10 md:p-16 rounded-12 justify-between flex flex-col ",
                styles.card
              )}
            >
              <div className="h-[calc(100%-59px)]">
                <h1 className="text-20 text-blue pb-16 md:pb-8 md:text-1.8">
                  Niveau
                </h1>
                <div className="-mr-[15px] pr-[15px] ">
                  {workshopLevel.map((item, index) => (
                    <LevelCard
                      key={`level-card-${index}`}
                      {...item}
                      getLevelData={getLevelData}
                    />
                  ))}
                </div>
              </div>
              <div className="pt-[20px] md:px-0 md:pb-8">
                <button
                  onClick={() => {
                    setTypeToAdd("Niveau");
                    openModal();
                  }}
                  className="border flex bg-white border-green bg-opacity-10 p-8 rounded-8 w-full justify-center md:text-1.2 text-1.4 text-green hover:bg-[#17A4541A]"
                >
                  <img
                    alt="add new level"
                    src={`${process.env.PUBLIC_URL}/images/Plus.svg`}
                    className="max-w-20"
                  />
                  Ajouter niveau
                </button>
              </div>
            </div>
            <div
              className={cx(
                "bg-white p-10 md:p-16 rounded-12 flex flex-col justify-between ",
                styles.card
              )}
            >
              <div className="h-[calc(100%-59px)]">
                <h1 className="text-20 text-blue pb-16 md:text-1.8 md:pb-8">
                  Établissement scolaire
                </h1>
                <div className="-mr-[15px] pr-[15px] ">
                  {schoolOptions.map((item, index) => (
                    <SchoolCard
                      key={`themes-card-${index}`}
                      {...item}
                      getSchool={getSchool}
                      schoolOptions={schoolOptions}
                    />
                  ))}
                </div>
              </div>
              <div className="pt-10 md:px-0 md:pb-8">
                <button
                  onClick={() => {
                    setTypeToAdd("Scolaire");
                    openModal();
                  }}
                  className="border flex bg-white border-green bg-opacity-10 p-8 rounded-8 w-full justify-center text-1.4 text-green hover:bg-[#17A4541A] md:text-1.2"
                >
                  <img
                    alt="add new school"
                    src={`${process.env.PUBLIC_URL}/images/Plus.svg`}
                    className="max-w-20"
                  />
                  Ajouter scolaire
                </button>
              </div>
            </div>
          </div>
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            className={styles.EditProfile}
          >
            <AddTheme
              type={typeToAdd}
              closeModal={closeModal}
              getworkshopTheme={getworkshopTheme}
              getTypeData={getTypeData}
              getLevelData={getLevelData}
              getSchool={getSchool}
            />
          </Modal>
        </div>
      ) : (
        <NoData />
      )}
    </>
  );
};
