import cx from "classnames";
import React from "react";
import Modal from "react-modal";
import { AddStudentDropDown } from "../AddStudentDropDown/AddStudentDropDown";
import styles from "./WorkshopPeopleAddButton.module.scss";

export const WorkshopPeopleAddButton = (props) => {
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }
  return (
    <>
      <button
        onClick={openModal}
        className="flex px-12 py-8 bg-bgGreen rounded-4 gap-8 md:px-4 md:py-6 md:rounded-xl"
      >
        <div className="text-green text-1.2 leading-15 md:text-1.1 mt-1 !font-medium">
          {props.text}
        </div>
        <div className="">
          <img
            alt="icon"
            src={`${process.env.PUBLIC_URL}/images/${props.icon}`}
            className={cx(styles["img-style"])}
          />
        </div>
      </button>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className={styles.EditProfile}
      >
        <AddStudentDropDown
          selectedOptions={props.selectedOptions}
          closeModal={closeModal}
          createWorkshopGroupsHandler={props.createWorkshopGroupsHandler}
          workshopId={props.workshopId}
          getPeople={props.getPeople}
          workshopGroups={props.workshopGroups}
          studentsInGroups={props.studentsInGroups}
        />
      </Modal>
    </>
  );
};
