import ReactSimpleImageVideoLightbox from "@santigp258/react-simple-lightbox-video-image";
import cx from "classnames";
import React, { useContext, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import Modal from "react-modal";
import { toast } from "react-toastify";
import AuthContext from "../../context/AuthContext";
import useOutsideAlerter from "../../hooks/outsideClickHook";
import { DeleteCard } from "../DeleteCard/DeleteCard";
import { FileInput } from "../FileInput/FileInput";
import { NoData } from "../NoData/NoData";
import { WVideoOptions } from "../WVideoOptions/WVideoOptions";
import styles from "./WorkshopVideoSection.module.scss";

export const WorkshopVideoSection = (props) => {
  const { authTokens, user } = useContext(AuthContext);
  const [isDrop, setIsDrop] = useState(false);
  const [showLightbox, setShowLightbox] = useState(-1);
  const dropperRef = useRef(null);
  useOutsideAlerter(dropperRef, () => {
    setIsDrop(-1);
  });
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function openModal(referenceId) {
    setToBeDeleted(referenceId);
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }
  const DeleteItems = [
    {
      cross: "cross1.svg",
      icon: "DelTrash.svg",
      title: "Delete Attachment?",
      text: "Deleteing this attachment will move it to the trash permanentaly.",
    },
  ];

  const methods = useForm();
  const onSubmit = (data) => {
    let formData = new FormData();
    formData.append("workshop", parseInt(props.workshopId));
    formData.append("document", data.document?.pop());
    var requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Token ${authTokens.token}`,
      },
      body: formData,
    };
    fetch(process.env.REACT_APP_API_URL + "workshop-view/", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (!result?.length) {
          Object.keys(result).forEach((key) =>
            methods.setError(key, { type: "custom", message: result[key] })
          );
        } else {
          toast.success("Ajouté");
          methods.reset();
          props.detailWorkshop();
        }
      });
  };
  const [toBeDeleted, setToBeDeleted] = useState(null);
  const deleteVideo = () => {
    var requestOptions = {
      method: "DELETE",
      redirect: "follow",
      headers: {
        Accept: "application/json",
        Authorization: `Token ${authTokens.token}`,
        "Content-Type": "application/json",
      },
    };
    fetch(
      `${process.env.REACT_APP_API_URL}workshop-view/${toBeDeleted}/`,
      requestOptions
    ).then((response) => {
      if (response.ok) {
        toast.success("Pièce-jointe supprimée");
        props.getWorkshopData();
        closeModal();
      }
    });
  };
  const mediaForLightbox = props.videoData?.map((mediaItem) => ({
    url: mediaItem.document,
    altTag: mediaItem.document.split("/").pop(),
    type: "photo",
  }));

  return (
    <>
      <div className=" mt-1.6 ">
        {user.user_type === 1 && (
          <div className="">
            <FormProvider {...methods}>
              <form
                onChange={methods.handleSubmit(onSubmit)}
                encType="multipart/form-data"
              >
                <FileInput
                  accept={{
                    "image/*": [".jpeg", ".jpg", ".png", ".webp"],
                    "video/*": [".mp4", ".mpeg", ".webp"],
                  }}
                  name="document"
                  onChangeHandler={methods.handleSubmit(onSubmit)}
                />
              </form>
            </FormProvider>
          </div>
        )}
        {props.videoData?.length ? (
          props.videoData?.map((content, index) => (
            <div
              key={`video-data-${content.document}-${index}`}
              className={cx("assignment", "border rounded-12 mt-6")}
            >
              <div className="flex justify-between p-10 sm:px-12 sm:py-16 ssm:px-8 ">
                <div className="flex items-center w-[400px] text-ellipsis whitespace-nowrap">
                  <div className="">
                    <img
                      alt="video icon"
                      src={`${process.env.PUBLIC_URL}/images/video.svg`}
                      className={cx(styles["img-style"])}
                    />
                  </div>
                  <div className="w-[70%] text-ellipsis whitespace-nowrap">
                    <div
                      className={cx(
                        "lmd:text-16 text-1.3 px-16 overflow-hidden text-ellipsis whitespace-nowrap",
                        styles.text
                      )}
                    >
                      {content.document.split("/").pop()}
                    </div>
                    <div className="flex pl-16 sm:pl-8">
                      <div className="lmd:text-1.4 text-1.2 text-ashgray ssm:text-1.1">
                        {props.meta}
                      </div>
                      <div className="lmd:text-1.4 text-1.2 text-ashgray pl-5 ssm:pl-2 ssm:text-1.1">
                        {props.date}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex items-center">
                  <div className="flex gap-16 sm:gap-8 sm:hidden ">
                    <button
                      onClick={() => setShowLightbox(index)}
                      className={cx(
                        styles.buttonBg,
                        "lmd:text-1.4 text-1.2 text-blue flex items-center"
                      )}
                    >
                      <div className="lmd:pr-8 pr-6 md:pr-0">
                        <img
                          alt="play icon"
                          src={`${process.env.PUBLIC_URL}/images/Play.svg`}
                          className={cx(styles["icon"])}
                        />
                      </div>
                      <div className="md:hidden">Play</div>
                    </button>
                    <a
                      className=""
                      target={"_blank"}
                      href={content.document}
                      rel="noreferrer"
                    >
                      <button
                        className={cx(
                          styles.buttonBg,
                          "lmd:text-1.4 text-1.2 text-blue flex  items-center"
                        )}
                      >
                        <div className="lmd:pr-8 pr-6 md:pr-0">
                          <img
                            alt="download icon"
                            src={`${process.env.PUBLIC_URL}/images/download.svg`}
                            className={cx(styles["icon"])}
                          />
                        </div>
                        <div className="md:hidden">Télécharger</div>
                      </button>
                    </a>
                  </div>
                  <div className={cx(styles.button)}>
                    <button
                      onClick={() => {
                        setIsDrop(index);
                      }}
                      className={styles.content}
                    >
                      <img
                        alt="options icon"
                        src={`${process.env.PUBLIC_URL}/images/dots.svg`}
                      />
                    </button>
                  </div>
                </div>
              </div>
              {isDrop === index && (
                <div ref={dropperRef} className={cx("relative")}>
                  <div
                    className={cx("bg-white rounded-8 py-12 absolute", styles.uc)}
                  >
                    <WVideoOptions
                      playHandler={() => setShowLightbox(index)}
                      openModal={() => {
                        openModal(content.id);
                      }}
                      document={content}
                    />
                  </div>
                </div>
              )}
            </div>
          ))
        ) : (
          <NoData />
        )}
      </div>
      {showLightbox !== -1 && (
        <ReactSimpleImageVideoLightbox
          data={mediaForLightbox}
          startIndex={showLightbox}
          CustomImage={(props) => {
            const videoTypes = ["mp4", "webm", "avi"];
            const ext = props.src.split(".").pop();
            const filename = props.src.split("/").pop().split(".")[0];
            if (videoTypes.includes(ext)) {
              return (
                <div>
                  <video controls autoPlay>
                    <source src={props.src} type={`video/${ext}`} />
                    Your browser does not support the video tag.
                  </video>
                </div>
              );
            } else {
              return <img src={props.src} alt={filename} />;
            }
          }}
          onCloseCallback={() => setShowLightbox(-1)}
        />
      )}

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className={styles.DeleteProfile}
      >
        {DeleteItems.map((item, index) => (
          <DeleteCard
            key={`delete-card${index}`}
            deleteHandler={deleteVideo}
            {...item}
          />
        ))}
      </Modal>
    </>
  );
};
