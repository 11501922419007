import cx from "classnames";
import React, { useContext } from "react";
import AuthContext from "../../context/AuthContext";
import styles from "./DashboardTeacher.module.scss";

export const DashboardTeacher = (props) => {
  const { user } = useContext(AuthContext);
  return (
    <div className="col-span-1 flex-grow flex-basis-0 min-w-0">
      <div
        className={cx(
          styles[props.theme],
          user.user_type === 1
            ? styles["dashboard-container"]
            : styles["dashboard-teacher-container"]
        )}
      >
        <h1 className={styles.heading}>{props.title}</h1>
        <p className={styles.text}>{props.subTitle}</p>
      </div>
    </div>
  );
};
