import cx from "classnames";
import React, { useContext } from "react";
import { useDrop } from "react-dnd";
import { toast } from "react-toastify";
import AuthContext from "../../context/AuthContext";
import { NoData } from "../NoData/NoData";
import { WorkshopGroupPeopleCard } from "../WorkshopGroupPeopleCard/WorkshopGroupPeopleCard";
import styles from "./WorkshopGroup.module.scss";

const WorkshopGroup = (props) => {
  const { user } = useContext(AuthContext);
  const rowCount = 3;

  const getRow = (index) =>
    props.data[index] ? (
      <div key={`group-row-${props.id}-${index}`}>
        <WorkshopGroupPeopleCard
          removeStudentFromGroupHandler={props.removeStudentFromGroupHandler}
          {...props.data[index]}
        />
      </div>
    ) : (
      user.user_type === 1 && (
        <div
          key={`group-row-${index}`}
          className="border-dashed border rounded-8 py-[12px] my-8 text-center mb-[16px]"
        >
          <p className="text-[#3D6FAA] text-1.2 leading-14">
            Traîne et laissez tomber
          </p>
        </div>
      )
    );
  const onDrop = (item) => {
    if (props.data?.length >= 3) {
      toast.error("Limite déjà atteinte");
      return;
    }
    props.addStudentToGroupHandler(props.id, item.id);
  };
  const [collectedProps, drop] = useDrop(
    () => ({
      accept: "card",
      options: {},
      drop: onDrop,
      canDrop: () => props.allowDrop || user.user_type === 1,
      collect: (monitor) => {
        return {
          isOver: monitor.isOver(),
          canDrop: monitor.canDrop(),
        };
      },
    }),
    [props.allowDrop]
  );
  return (
    <div
      ref={drop}
      className={cx("group/wrapper border rounded-12 pt-8 px-16 pb-16 mb-12 mt-8", {
        [styles["highlighted"]]: props.allowDrop && collectedProps.canDrop,
      })}
    >
      <div className="pb-8 flex justify-between items-center ">
        <span className="text-1.2  text-[#6E93BF] leading-[2rem]">
          Group {String.fromCharCode(65 + props.index)}
        </span>
        {user.user_type === 1 && (
          <button
            className="mmd:hidden group-hover/wrapper:flex"
            title="Delete group"
            onClick={() => {
              props.deleteGroupHandler(props.id);
            }}
          >
            <img
              alt="options icon"
              src={`${process.env.PUBLIC_URL}/images/dash.svg`}
              className={cx(styles["img-style"])}
            />
          </button>
        )}
      </div>

      <hr className="pb-8 w-full"></hr>
      <div className="mt-8">
        {props.data?.length || user.user_type === 1 ? (
          Array(rowCount)
            .fill(null)
            .map((_, index) => {
              return getRow(index);
            })
        ) : (
          <NoData />
        )}
      </div>
    </div>
  );
};

export default WorkshopGroup;
