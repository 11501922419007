import cx from "classnames";
import fr from "date-fns/locale/fr";
import React, { useContext, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { FileInput } from "../../components/FileInput/FileInput";
import AuthContext from "../../context/AuthContext";
import styles from "./CreateWorkshop.module.scss";
export const CreateWorkshop = () => {
  const [workshopCats, setworkshopCats] = useState([]);
  const { authTokens } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const getCatsData = async () => {
    const response = await fetch(
      process.env.REACT_APP_API_URL + "add-category/?status=true"
    ).then((response) => response.json());
    setworkshopCats(response);
  };
  useEffect(() => {
    getCatsData();
  }, []);

  const [workshopType, setworkshopType] = useState([]);
  const getTypeData = async () => {
    const response = await fetch(
      process.env.REACT_APP_API_URL + "ws-types/?status=true"
    ).then((response) => response.json());
    setworkshopType(response);
  };
  useEffect(() => {
    getTypeData();
  }, []);

  const [workshopLevel, setworkshopLevel] = useState([]);
  const getLevelData = async () => {
    const response = await fetch(
      process.env.REACT_APP_API_URL + "ws-levels/?status=true"
    ).then((response) => response.json());
    setworkshopLevel(response);
  };
  useEffect(() => {
    getLevelData();
  }, []);
  const getCustomStyles = (fieldName) => {
    const customStyles = {
      control: (base) => ({
        ...base,
        padding: "5px 8px 6px 8px",
        borderColor: errors[fieldName]?.message ? "red !important" : "#d4e6ff",
        boxShadow: "0 !important",
        "&:hover": {
          borderColor: "#2684ff",
        },
      }),
      valueContainer: (base) => ({
        ...base,
        paddingTop: "0 !important",
        paddingBottom: "0 !important",
      }),
      singleValue: (base) => ({
        ...base,
        fontSize: "12px",
      }),
      placeholder: (base) => ({
        ...base,
        fontSize: "12px",
        color: "#8892AD",
      }),
      menuList: (base) => ({
        ...base,
        paddingTop: "0 !important",
        paddingBottom: "0 !important",
        borderRadius: "4px",
      }),
      option: (base) => ({
        ...base,
        color: "#020422",
      }),
    };
    return customStyles;
  };
  const timeOptions = [
    { label: "08:00", value: "08:00" },
    { label: "08:15", value: "08:15" },
    { label: "08:30", value: "08:30" },
    { label: "08:45", value: "08:45" },
    { label: "09:00", value: "09:00" },
    { label: "09:15", value: "09:15" },
    { label: "09:30", value: "09:30" },
    { label: "09:45", value: "09:45" },
    { label: "10:00", value: "10:00" },
    { label: "10:15", value: "10:15" },
    { label: "10:30", value: "10:30" },
    { label: "10:45", value: "10:45" },
    { label: "11:00", value: "11:00" },
    { label: "11:15", value: "11:15" },
    { label: "11:30", value: "11:30" },
    { label: "11:45", value: "11:45" },
    { label: "12:00", value: "12:00" },
    { label: "12:15", value: "12:15" },
    { label: "12:30", value: "12:30" },
    { label: "12:45", value: "12:45" },
    { label: "13:00", value: "13:00" },
    { label: "13:15", value: "13:15" },
    { label: "13:30", value: "13:30" },
    { label: "13:45", value: "13:45" },
    { label: "14:00", value: "14:00" },
    { label: "14:15", value: "14:15" },
    { label: "14:30", value: "14:30" },
    { label: "14:45", value: "14:45" },
    { label: "15:00", value: "15:00" },
    { label: "15:15", value: "15:15" },
    { label: "15:30", value: "15:30" },
    { label: "15:45", value: "15:45" },
    { label: "16:00", value: "16:00" },
    { label: "16:15", value: "16:15" },
    { label: "16:30", value: "16:30" },
    { label: "16:45", value: "16:45" },
    { label: "17:00", value: "17:00" },
    { label: "17:15", value: "17:15" },
    { label: "17:30", value: "17:30" },
    { label: "17:45", value: "17:45" },
    { label: "18:00", value: "18:00" },
    { label: "18:15", value: "18:15" },
    { label: "18:30", value: "18:30" },
    { label: "18:45", value: "18:45" },
  ];
  const statusOptions = [
    { label: "Actif", value: "ACTIVE" },
    { label: "Brouillon", value: "DRAFT" },
  ];
  const methods = useForm();
  const errors = methods.formState.errors;
  const onSubmit = (data) => {
    const formData = new FormData();
    const tempWorkshopDate = new Date(data.workshop_date);
    data.workshop_date = `${tempWorkshopDate.toLocaleString("default", {
      month: "long",
    })} ${tempWorkshopDate.getDate()}, ${tempWorkshopDate.getFullYear()}`;
    data.workshop_date = `${tempWorkshopDate.getFullYear()}-${
      tempWorkshopDate.getMonth() + 1
    }-${tempWorkshopDate.getDate()}`;
    Object.keys(data).forEach((key) => {
      if (key === "cover_img") {
        formData.append(key, data[key] && data[key][0]);
      } else formData.append(key, data[key]);
    });

    var requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: {
        Accept: "application/json",
        Authorization: `Token ${authTokens.token}`,
      },
      body: formData,
    };
    setIsLoading(true);
    fetch(process.env.REACT_APP_API_URL + "add-workshop/", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (!result.id) {
          Object.keys(result).forEach((key) =>
            methods.setError(key, { type: "custom", message: result[key] })
          );
        } else {
          toast.success("Atelier créé");
          methods.reset();
          navigate("/workshops");
        }
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <div className=" bg-white rounded-12 p-10 mb-10 md:p-16 md:mb-[16px]">
        <h1 className="text-20 text-blue pb-10 md:text-1.8">Crée un atelier</h1>
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onSubmit)}
            encType="multipart/form-data"
          >
            <div className="grid grid-cols-12 md:grid-cols-6">
              <div className="col-span-6 pr-16 md:pr-0">
                <div className="pb-16">
                  <h1 className="mb-[16px] text-1.4 md:text-1.2 leading-16 md:mb-8">
                    Titre
                  </h1>
                  <input
                    className={cx(
                      "border rounded-8 px-16 py-17 max-w-full w-full mb-[4px] text-1.2 leading-14",
                      { "!border-red-500": errors?.workshop_name?.message }
                    )}
                    placeholder="Écrire le titre de l’atelier"
                    {...methods.register("workshop_name", {
                      required: "This field may not be blank.",
                    })}
                  />
                  <div className="text-red-500 h-[12px]">
                    {errors?.workshop_name?.message && errors.workshop_name.message}
                  </div>
                </div>
                <div className="pb-16">
                  <h1 className="mb-[16px] text-1.4 md:text-1.2 leading-16 md:mb-8">
                    Description
                  </h1>
                  <textarea
                    type="textarea"
                    className={
                      errors?.description?.message
                        ? "border-red"
                        : "border rounded-12 p-16 max-w-full w-full h-[136px] mb-[4px] text-1.2 leading-14"
                    }
                    placeholder="Écrire la description de l’atelier"
                    {...methods.register("description")}
                  />

                  <div className="text-red-500 h-[12px]">
                    {errors?.description?.message && errors.description.message}
                  </div>
                </div>
                <div className="pb-16">
                  <h1 className="mb-[16px] text-1.4 md:text-1.2 leading-16 md:mb-8">
                    Thème
                  </h1>
                  <label htmlFor="category"></label>
                  <Controller
                    style={styles.select}
                    className="border rounded-12 px-16 py-17 max-w-full w-full"
                    control={methods.control}
                    name="category"
                    rules={{
                      required: "This field may not be blank.",
                    }}
                    render={({ field: { onChange, ref } }) => (
                      <Select
                        className="react-select-container mb-[4px]"
                        inputRef={ref}
                        menuPlacement="auto"
                        placeholder="Sélectionner un thème.."
                        styles={getCustomStyles("category")}
                        classNamePrefix="react-select"
                        getOptionLabel={(option) => option.category_name}
                        getOptionValue={(option) => option.id}
                        options={workshopCats}
                        onChange={(val) => onChange(val.id)}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: "8px",
                          colors: {
                            ...theme.colors,
                            primary25: "#D4E6FF",
                            primary: "#93C1FF",
                            color: "#020422",
                          },
                        })}
                      />
                    )}
                  />
                  <div className="text-red-500 h-[12px]">
                    {errors?.category?.message && errors.category.message}
                  </div>
                </div>

                <div className="flex gap-16 grid-cols-12 pb-16 md:flex-col md:grid-cols-2">
                  <div className="col-span-4 md:col-span-6 w-full">
                    <h1 className="mb-[16px] text-1.4 md:text-1.2 leading-16 md:mb-8">
                      Type
                    </h1>
                    <label htmlFor="type"></label>
                    <Controller
                      style={styles.select}
                      className="border rounded-12 px-16 py-17 max-w-full w-full"
                      control={methods.control}
                      name="type"
                      rules={{
                        required: "This field may not be blank.",
                      }}
                      render={({ field: { onChange, ref } }) => (
                        <Select
                          className="react-select-container mb-[4px]"
                          inputRef={ref}
                          menuPlacement="auto"
                          placeholder="Sélectionner type .."
                          styles={getCustomStyles("type")}
                          classNamePrefix="react-select"
                          getOptionLabel={(option) => option.type_name}
                          getOptionValue={(option) => option.id}
                          options={workshopType}
                          onChange={(val) => onChange(val.id)}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: "8px",
                            colors: {
                              ...theme.colors,
                              primary25: "#D4E6FF",
                              primary: "#93C1FF",
                              color: "#020422",
                            },
                          })}
                        />
                      )}
                    />
                    <div className="text-red-500 h-[12px]">
                      {errors?.type?.message && errors.type.message}
                    </div>
                  </div>
                  <div className="col-span-6 md:col-span-6 w-full">
                    <h1 className="mb-[16px] text-1.4 md:text-1.2 leading-16 md:mb-8">
                      Niveau
                    </h1>
                    <label htmlFor="level"></label>
                    <Controller
                      style={styles.select}
                      className="border rounded-12 px-16 py-17 max-w-full w-full"
                      control={methods.control}
                      name="level"
                      rules={{
                        required: "This field may not be blank.",
                      }}
                      render={({ field: { onChange, ref } }) => (
                        <Select
                          className="react-select-container mb-[4px]"
                          inputRef={ref}
                          // menuIsOpen
                          menuPlacement="auto"
                          placeholder="Sélectionner niveau.."
                          styles={getCustomStyles("level")}
                          classNamePrefix="react-select"
                          getOptionLabel={(option) => option.level_name}
                          getOptionValue={(option) => option.id}
                          options={workshopLevel}
                          onChange={(val) => onChange(val.id)}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: "8px",
                            colors: {
                              ...theme.colors,
                              primary25: "#D4E6FF",
                              primary: "#93C1FF",
                              color: "#020422",
                            },
                          })}
                        />
                      )}
                    />
                    <div className="text-red-500 h-[12px]">
                      {errors?.level?.message && errors.level.message}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-6 pl-16 md:pl-0">
                <div className="pb-16">
                  <h1 className="mb-[16px] text-1.4 md:text-1.2 leading-16 md:mb-8">
                    Image
                  </h1>
                  <FileInput
                    accept={{
                      "image/*": [".jpeg", ".jpg", ".png", ".webp"],
                    }}
                    name="cover_img"
                    {...methods.register("cover_img", {
                      required: "This field may not be blank.",
                    })}
                    isError={errors?.cover_img?.message}
                  />
                  <div className="text-red-500 h-[12px] mt-[4px]">
                    {errors?.cover_img?.message && errors.cover_img.message}
                  </div>
                </div>
                <div className="pb-16">
                  <h1 className="text-1.4 md:text-1.2 leading-16">Date</h1>
                  <Controller
                    control={methods.control}
                    name="workshop_date"
                    rules={{
                      required: "This field may not be blank.",
                    }}
                    render={({ field }) => (
                      <DatePicker
                        wrapperClassName={cx(
                          errors?.workshop_date?.message
                            ? styles.error
                            : styles.DatePicker
                        )}
                        dateFormat="MMMM dd, yyyy"
                        locale={fr}
                        placeholderText="Sélectionner une date"
                        onChange={(date) => field.onChange(date)}
                        selected={field.value}
                        className="mb-[4px] text-1.2 text-ashgray"
                      />
                    )}
                  />
                  <div className="text-red-500 h-[12px]">
                    {/* {errors?.wsstatustype?.message && errors.wsstatustype.message} */}
                  </div>
                </div>
                <div className="lex gap-16 grid grid-cols-12 pb-16 md:flex-col md:grid-cols-2">
                  <div className="col-span-6">
                    <h1 className="mb-[16px] mt-[2px] text-1.4 md:text-1.2 leading-16 md:mb-8">
                      Début
                    </h1>
                    <label htmlFor="start_name"></label>
                    <Controller
                      style={styles.select}
                      className="border rounded-12 px-16 py-17 max-w-full w-full"
                      control={methods.control}
                      name="start_time"
                      rules={{
                        required: "This field may not be blank.",
                      }}
                      render={({ field: { onChange, value, ref } }) => (
                        <Select
                          className="react-select-container mb-[4px]"
                          inputRef={ref}
                          menuPlacement="auto"
                          placeholder="Sélectionner une heure.."
                          styles={getCustomStyles("start_time")}
                          classNamePrefix="react-select"
                          options={timeOptions}
                          value={timeOptions.find((c) => c.value === value)}
                          onChange={(val) => onChange(val.value)}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: "8px",
                            colors: {
                              ...theme.colors,
                              primary25: "#D4E6FF",
                              primary: "#93C1FF",
                              color: "#020422",
                            },
                          })}
                        />
                      )}
                    />
                    <div className="text-red-500 h-[12px]">
                      {/* {errors?.wsstatustype?.message && errors.wsstatustype.message} */}
                    </div>
                  </div>
                  <div className="col-span-6">
                    <h1 className="mb-[16px] text-1.4 md:text-1.2 leading-16 md:mb-8">
                      Fin
                    </h1>
                    <label htmlFor="themes"></label>
                    <Controller
                      style={styles.select}
                      className="border rounded-12 px-16 py-17 max-w-full w-full"
                      control={methods.control}
                      name="end_time"
                      rules={{
                        required: "This field may not be blank.",
                      }}
                      render={({ field: { onChange, value, ref } }) => (
                        <Select
                          className="react-select-container mb-[4px]"
                          inputRef={ref}
                          menuPlacement="auto"
                          placeholder="Sélectionner une heure.."
                          styles={getCustomStyles("end_time")}
                          classNamePrefix="react-select"
                          options={timeOptions}
                          value={timeOptions.find((c) => c.value === value)}
                          onChange={(val) => onChange(val.value)}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: "8px",
                            colors: {
                              ...theme.colors,
                              primary25: "#D4E6FF",
                              primary: "#93C1FF",
                              color: "#020422",
                            },
                          })}
                        />
                      )}
                    />
                    <div className="text-red-500 h-[12px]">
                      {/* {errors?.end_time?.message && errors.end_time.message} */}
                    </div>
                  </div>
                </div>
                <div className="col-span-8 md:col-span-6">
                  <h1 className="mb-[16px] text-1.4 md:text-1.2 leading-16 md:mb-8">
                    Statut
                  </h1>
                  <label htmlFor="wsstatustype"></label>
                  <Controller
                    style={styles.select}
                    className="border rounded-12 px-16 py-17 max-w-full w-full"
                    control={methods.control}
                    rules={{
                      required: "This field may not be blank.",
                    }}
                    name="wsstatustype"
                    render={({ field: { onChange, value, ref } }) => (
                      <Select
                        className="react-select-container mb-[4px]"
                        inputRef={ref}
                        menuPlacement="auto"
                        placeholder="Définir un statut.."
                        styles={getCustomStyles("wsstatustype")}
                        classNamePrefix="react-select"
                        options={statusOptions}
                        value={statusOptions.find((c) => c.value === value)}
                        onChange={(val) => onChange(val.value)}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: "8px",
                          colors: {
                            ...theme.colors,
                            primary25: "#D4E6FF",
                            primary: "#93C1FF",
                            color: "#020422",
                          },
                        })}
                      />
                    )}
                  />
                  <div className="text-red-500 h-[12px]">
                    {errors?.wsstatustype?.message && errors.wsstatustype.message}
                  </div>
                </div>
              </div>
            </div>
            <div className="pb-32 pt-10 md:pb-16 md:pt-16">
              <button
                disabled={isLoading}
                className={cx("text-1.4 md:text-1.2 !font-medium", styles.button)}
                type="submit"
              >
                Créer l’atelier
              </button>
            </div>
          </form>
        </FormProvider>
      </div>
    </>
  );
};
