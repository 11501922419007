import cx from "classnames";
import React, { useContext, useEffect, useState } from "react";
import Modal from "react-modal";
import "reactjs-popup/dist/index.css";
import { ResetPassword } from "../../components/ResetPassword/ResetPassword";
import AuthContext from "../../context/AuthContext";
import styles from "./Login.module.scss";

export const Login = () => {
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }

  const [error, setError] = useState("");
  const { loginUser, logoutUser } = useContext(AuthContext);
  useEffect(() => {
    logoutUser();
  }, []);
  const handleSubmit = (event) => {
    event.preventDefault();
    loginUser(
      event.target.elements.email.value,
      event.target.elements.password.value,
      setError
    );
  };
  return (
    <>
      <div className="flex pt-10 md:py-10">
        <div
          className={cx(
            "flex flex-col justify-center w-[560px] h-[100vh] lg:w-[400px] p-32 bg-white rounded-12 md:w-full md:px-16 px-10 md:h-[calc(100vh-48px)]",
            styles.topbar
          )}
        >
          <div className={cx("text-center justify-center flex", styles.logoTop)}>
            <img
              alt="CLA logo"
              src={`${process.env.PUBLIC_URL}/images/logo1.svg`}
              className={cx(styles.logoStyle, "")}
            />
          </div>
          <div className="text-center pt-48 text-20 pb-48 sm:text-1.8 leading-20">
            <span className="">Se connecter</span>
          </div>
          <div className="">
            <div className="col-span-3  text-center ">
              <form onSubmit={handleSubmit}>
                <div className="col-span-1 py-16">
                  <h1 className="flex justify-start text-1.4 pb-8 sm:text-1.2 leading-16">
                    Email
                  </h1>
                  <input
                    autoComplete="off"
                    name="email"
                    type="email"
                    placeholder="Écrire le email"
                    className="p-16 w-full rounded-8 border text-1.2 leading-14"
                  ></input>
                </div>
                <div className="col-span-1 py-16">
                  <h1 className="flex justify-start text-1.4 pb-8 sm:text-1.2 leading-16">
                    Mot de passe
                  </h1>
                  <input
                    name="password"
                    type="password"
                    placeholder="Votre mot de passes"
                    className="py-16 px-20 w-full rounded-8 border text-1.2 leading-14"
                  ></input>
                  <div className="flex justify-end">
                    <button onClick={openModal} type="button">
                      <p className="text-ashgray flex justify-end text-1.2 pt-12 sm:text-1.1 leading-14">
                        Configurer un nouveau mot de passe
                      </p>
                    </button>
                  </div>
                </div>
                <div className="col-span-1 py-32">
                  <button
                    type=""
                    className="px-10 py-16 bg-green rounded-8 text-white w-full text-1.4 leading-16 sm:text-1.2 hover:bg-themeGreenHover"
                  >
                    Se connecter
                  </button>
                </div>
                {error && <div className="text-1.2 text-errorRed">{error}</div>}
              </form>
            </div>
          </div>
        </div>
        <div className="flex justify-end lg:w-4/5 md:hidden">
          <img
            alt="image on login screen"
            src={`${process.env.PUBLIC_URL}/images/sideImg.png`}
            className={cx(styles.sideImg)}
          />
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className={styles.EditProfile}
      >
        <ResetPassword closeModal={closeModal} />
      </Modal>
    </>
  );
};
