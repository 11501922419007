import cx from "classnames";
import React, { useContext, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import styles from "./LeftSideBar.module.scss";

export const LeftSideBar = (props) => {
  const { logoutUser, user } = useContext(AuthContext);
  const [isHovered, setisHovered] = useState(-1);
  const location = useLocation();
  const generateLinks = (arr) =>
    arr.map((item, index) =>
      item?.visibility?.includes(user?.user_type) ? (
        <div
          onMouseEnter={() => {
            setisHovered(item?.text);
          }}
          onMouseLeave={() => {
            setisHovered(-1);
          }}
          key={`link-${item.link}-${index}`}
          className={cx(styles.col, "col", "col-12", "col-lg-6")}
        >
          <NavLink
            className={cx(
              "flex items-center gap-16 ",
              {
                [styles.top]: true,
                [styles.activeBorder]: item.link === location.pathname,
                [styles.activeColor]:
                  item.link === location.pathname || isHovered === item?.text,
              },
              `${item.showAtBottom && "hover:text-red-600"} `
            )}
            to={item.link}
            onClick={(e) => {
              if (item.onClickHandler) {
                e.preventDefault();
                item.onClickHandler();
                props.setIsShown(false);
              }
            }}
          >
            <div className={styles.imgDiv}>
              {item.link === location.pathname || isHovered === item?.text ? (
                <img
                  alt="icon"
                  src={`${process.env.PUBLIC_URL}/images/${item.activeIcon}`}
                  className={styles.img}
                />
              ) : (
                <img
                  alt="icon"
                  src={`${process.env.PUBLIC_URL}/images/${item.icon}`}
                  className={styles.img}
                />
              )}
            </div>
            <div
              className={cx(styles.content, {
                [styles.red]: item.showAtBottom,
              })}
            >
              {item.text}
            </div>
          </NavLink>
        </div>
      ) : null
    );
  return (
    <div
      className="col-span-12 "
      onClick={() => {
        props.setIsShown(false);
      }}
    >
      <div className={cx(styles.row, "row rounded-xl")}>
        <div className="flex justify-end sm:pr-4 mmd:hidden">
          <button className="">
            <img
              alt="logo"
              src={`${process.env.PUBLIC_URL}/images/crossRed.svg`}
              className={cx(styles.crossStyle, " ")}
            />
          </button>
        </div>
        <div className="flex justify-center md:justify-start">
          <NavLink to={"/"}>
            <img
              alt="logo"
              src={`${process.env.PUBLIC_URL}/images/logo1.svg`}
              className={cx(styles.logoStyle, " ")}
            />
          </NavLink>
        </div>

        <div className={cx(styles.logoutWrapper, "group mt-5")}>
          <div>{generateLinks(props.mainNav)}</div>
          {generateLinks([
            {
              text: "Se déconnecter",
              link: "",
              onClickHandler: () => {
                logoutUser();
              },
              icon: "logout.png",
              activeIcon: "logoutActiveRed.svg",
              showAtBottom: true,
              visibility: [1, 2, 3],
            },
          ])}
        </div>
      </div>
    </div>
  );
};
