import React from "react";

export const LoadingScreen = () => {
  return (
    <div className="flex w-full justify-center">
      <span
        style={{
          display: "inherit",
          position: "relative",
          width: "77.1429px",
          height: "77.1429px",
          animation:
            "0.6s linear 0s infinite normal forwards running animation-spinner",
        }}
      >
        <span
          style={{
            width: "8.57143px",
            height: "8.57143px",
            borderRadius: "100%",
            backgroundColor: "#17A454",
            opacity: "0.8",
            position: "absolute",
            top: "25.7143px",
            animation:
              "0.6s linear 0s infinite normal forwards running animation-spinner",
          }}
        ></span>
        <span
          style={{
            width: "60px",
            height: "60px",
            borderRadius: "100%",
            border: "8.57143px solid #45B676",
            opacity: "0.1",
            boxSizing: "content-box",
            position: "absolute",
          }}
        ></span>
      </span>
    </div>
  );
};
