import cx from "classnames";
import React, { useContext, useRef, useState } from "react";
import Modal from "react-modal";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import AuthContext from "../../context/AuthContext";
import useOutsideAlerter from "../../hooks/outsideClickHook";
import { DeleteCard } from "../DeleteCard/DeleteCard";
import { Label } from "../Label/Label";
import { WEventOptions } from "../WEventOptions/WEventOptions";
import styles from "./EventWorkshop.module.scss";

export const EventWorkshop = (props) => {
  const { authTokens } = useContext(AuthContext);
  const workshopDateObj = {
    date: props.workshop_date,
    type: props.type_name,
    level: props.level_name,
  };
  const [isDrop, setIsDrop] = useState(false);
  const dropperRef = useRef(null);
  useOutsideAlerter(dropperRef, () => {
    setIsDrop(false);
  });

  const linkedContent = (
    <>
      <img alt="cover" src={`${props.cover_img}`} className={styles["img-style"]} />
      <div className="px-10 py-16">
        <div className="pb-16">
          <Label workshopData={workshopDateObj} hideLabels={props.hideLabels} />
        </div>
        <div
          title={props.workshop_name}
          className="text-20 leading-20 pb-8 mt-0 text-blue md:text-3xl text-ellipsis whitespace-nowrap overflow-hidden md:text-1.8"
        >
          {props.workshop_name}
        </div>
        {props.showStatus && (
          <span className="">
            <span className={cx("flex gap-[3px] pt-8")}>
              <p className="text-ashgray text-1.2 leading-14">Statut:</p>
              <p
                className={cx(
                  "text-1.2 leading-14",
                  props.wsstatustype === "DRAFT" ? styles.draft : styles.active
                )}
              >
                {props.wsstatustype}
              </p>
            </span>
          </span>
        )}
      </div>
    </>
  );

  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal(referenceId) {
    setToBeDeleted(referenceId);
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }
  const DeleteItems = [
    {
      cross: "cross1.svg",
      icon: "DelTrash.svg",
      title: "Delete Attachment?",
      text: "Deleteing this attachment will move it to the trash permanentaly.",
    },
  ];

  const [toBeDeleted, setToBeDeleted] = useState(null);
  const deleteContent = () => {
    var requestOptions = {
      method: "DELETE",
      redirect: "follow",
      headers: {
        Accept: "application/json",
        Authorization: `Token ${authTokens.token}`,
        "Content-Type": "application/json",
      },
    };
    fetch(
      `${process.env.REACT_APP_API_URL}add-workshop/${toBeDeleted}/`,
      requestOptions
    ).then((response) => {
      if (response.ok) {
        toast.success("Atelier supprimé");
        props.getWorkshopData();
        closeModal();
      }
    });
  };

  return (
    <>
      <div className="overflow-hidden rounded-12 bg-bg relative w-full">
        {!props.hideOptions && (
          <div
            onClick={() => {
              setIsDrop(!isDrop);
            }}
            className="relative"
          >
            <button className={cx("", styles.content)}>
              <img
                alt="icon"
                src={`${process.env.PUBLIC_URL}/images/whiteDots.svg`}
                className={styles.dotsIcon}
              />
            </button>
          </div>
        )}
        <NavLink
          className=""
          to={
            props.editLink
              ? `/edit-workshop/${props.id}`
              : `/workshop-detail/${props.id}`
          }
        >
          {linkedContent}
        </NavLink>
        {isDrop && (
          <div ref={dropperRef} className={cx("")}>
            <div className={cx("bg-white rounded-8  py-12 absolute", styles.uc)}>
              <WEventOptions
                id={props.id}
                openModal={() => {
                  openModal(props?.id);
                }}
              />
            </div>
          </div>
        )}
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className={styles.DeleteProfile}
      >
        {DeleteItems.map((item, index) => (
          <DeleteCard
            key={`delete-card-${index}`}
            {...item}
            deleteHandler={deleteContent}
            closeModal={closeModal}
          />
        ))}
      </Modal>
    </>
  );
};
