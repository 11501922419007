import cx from "classnames";
import React from "react";
import Modal from "react-modal";
import { AddStudentModal } from "../../pages/AddStudentModal/AddStudentModal";
import styles from "./PeopleAddButton.module.scss";

export const PeopleAddButton = (props) => {
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }
  return (
    <>
      <button
        onClick={openModal}
        className="flex px-12 py-8 bg-bgGreen rounded-4 gap-8 md:px-4 md:py-6 md:rounded-4 hover:bg-themeGreenHover group"
      >
        <div className="text-green !font-medium text-1.2 leading-15 md:text-1.1 mt-1 group-hover:text-white">
          {props.text}
        </div>
        <div className="group-hover:hidden">
          <img
            alt="icon"
            src={`${process.env.PUBLIC_URL}/images/${props.icon}`}
            className={cx(styles["img-style"])}
          />
        </div>
        <div className="hidden group-hover:block">
          <img
            alt="icon"
            src={`${process.env.PUBLIC_URL}/images/${props.hoverIcon}`}
            className={cx(styles["img-style"])}
          />
        </div>
      </button>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className={styles.EditProfile}
      >
        <AddStudentModal closeModal={closeModal} getPeople={props.getPeople} />
      </Modal>
    </>
  );
};
