import cx from "classnames";
import React from "react";
import { LoadingScreen } from "../LoadingScreen/LoadingScreen";
import { NoData } from "../NoData/NoData";
import { WorkshopPerformanceRow } from "../WorkshopPerformanceRow/WorkshopPerformanceRow";
import styles from "./WorkshopPerformance.module.scss";

export const WorkshopPerformance = (props) => {
  return (
    <div className="col-span-3 md:p-0">
      {props.loading ? (
        <div className="pb-4">
          <LoadingScreen />
        </div>
      ) : props.data?.length ? (
        <>
          <div id={styles["teacher-activity-grid"]}>
            <div
              className={cx(
                styles.row,
                "grid grid-cols-5 text-1.2 text-ashgray pt-10 md:pt-16 px-0 leading-14",
                styles.activityGrid
              )}
            >
              <div
                className={cx(
                  styles.col,
                  "col-span-2 text-1.2 leading-16",
                  styles.teacher
                )}
              >
                Atelier
              </div>
              <div className={cx(styles.col, "col-span-1", styles.activity)}>
                Format
              </div>
              <div className={cx(styles.col, "col-span-1", styles.activity)}>
                Niveau
              </div>
              <div className={cx(styles.col, "col-span-1", styles.activity)}>
                Élèves
              </div>
            </div>
          </div>
          {props.data.map((item, index) => (
            <WorkshopPerformanceRow
              key={`workshop-performance-row-${index}`}
              {...item}
              data={props.data}
            />
          ))}
        </>
      ) : (
        <NoData />
      )}
    </div>
  );
};
