import cx from "classnames";
import React, { useContext, useEffect, useState } from "react";
import Modal from "react-modal";
import { toast } from "react-toastify";
import { DeleteUserCard } from "../../components/DeleteUserCard/DeleteUserCard";
import { PeopleLabel } from "../../components/PeopleLabel/PeopleLabel";
import { PeopleTeacher } from "../../components/PeopleTeacher/PeopleTeacher";
import AuthContext from "../../context/AuthContext";
import styles from "./People.module.scss";
export const People = () => {
  const { authTokens, user } = useContext(AuthContext);
  const [selectedScreen, setselectedScreen] = useState("PeopleStudent");
  const [modalDelOpen, setmodalDelOpen] = React.useState(false);
  console.log("loginUser", user);
  function openDelModal() {
    setmodalDelOpen(true);
  }
  function closeDelModal() {
    setmodalDelOpen(false);
  }

  var requestOptions = {
    method: "GET",
    redirect: "follow",
    headers: {
      Accept: "application/json",
      Authorization: `Token ${authTokens.token}`,
    },
  };
  const deleteUser = (referenceId, callback) => {
    var requestOptions = {
      method: "DELETE",
      redirect: "follow",
      headers: {
        Accept: "application/json",
        Authorization: `Token ${authTokens.token}`,
        "Content-Type": "application/json",
      },
    };
    fetch(
      `${process.env.REACT_APP_API_URL}user/${referenceId}/`,
      requestOptions
    ).then((response) => {
      if (response.ok) {
        toast.success("Utilisateur supprimé");
        getPeopleData();
        callback();
      }
    });
  };
  const checkChangeHandler = (val, id, type) => {
    const tempPeopleData = peopleData?.map((people) => {
      if (id) {
        if (people.id === id) {
          people.checked = val;
        }
      } else {
        let tempType;
        if (type) {
          if (type === "Teachers") {
            tempType = 2;
          } else if (type === "Students") {
            tempType = 3;
          } else {
            tempType = 1;
          }
          if (people.user_type === tempType) {
            people.checked = val;
          }
        } else {
          people.checked = val;
        }
      }
      return people;
    });
    setpeopleData(tempPeopleData);
  };
  const [peopleData, setpeopleData] = useState([]);
  const getPeopleData = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}user/`,
      requestOptions
    ).then((response) => response.json());
    setpeopleData(response);
  };
  useEffect(() => {
    getPeopleData();
  }, []);
  const deleteSelected = () => {
    const toBeDeleted = peopleData
      ?.filter((people) => people.checked)
      .map((people) => people.id);
    var requestOptions = {
      method: "DELETE",
      redirect: "follow",
      headers: {
        Accept: "application/json",
        Authorization: `Token ${authTokens.token}`,
        "Content-Type": "application/json",
      },
    };

    fetch(
      `${process.env.REACT_APP_API_URL}/users-delete/?user_ids=${toBeDeleted.join(
        ","
      )}`,
      requestOptions
    ).then((response) => {
      if (response.ok) {
        toast.success("Utilisateurs supprimé");
        getPeopleData();
        closeDelModal();
      }
    });
  };
  const students = peopleData?.filter((data) => data.user_type === 3);
  const selectedStudents = students?.filter((student) => student.checked);
  const teachers = peopleData?.filter((data) => data.user_type === 2);
  const selectedTeachers = teachers?.filter((teacher) => teacher.checked);
  const staff = peopleData?.filter(
    (data) => data.user_type === 1 && data.id != user.id
  );
  const selectedStaff = staff?.filter((staff) => staff.checked);
  return (
    <>
      <div className="flex justify-center gap-64 bg-white mt-10 rounded-t-xl relative md:mt-[16px]">
        <button
          onClick={() => {
            setselectedScreen("PeopleStudent");
            checkChangeHandler(false, undefined, "Teachers");
            checkChangeHandler(false, undefined, "Staff");
          }}
          className={cx(
            "text-1.4 py-16 mt-[16px] leading-16",
            selectedScreen === "PeopleStudent" ? styles.activeScreen : styles.normal
          )}
        >
          Élèves
        </button>
        <button
          onClick={() => {
            setselectedScreen("PeopleTeacher");
            checkChangeHandler(false, undefined, "Students");
            checkChangeHandler(false, undefined, "Staff");
          }}
          className={cx(
            "text-1.4 py-16 mt-[16px] leading-16",
            selectedScreen === "PeopleTeacher" ? styles.activeScreen : styles.normal
          )}
        >
          Professeurs
        </button>
        <button
          onClick={() => {
            setselectedScreen("PeopleStaff");
            checkChangeHandler(false, undefined, "Students");
            checkChangeHandler(false, undefined, "Teachers");
          }}
          className={cx(
            "text-1.4 py-16 mt-[16px] leading-16",
            selectedScreen === "PeopleStaff" ? styles.activeScreen : styles.normal
          )}
        >
          Equipe CLA
        </button>
      </div>
      <hr />
      <div className="bg-white rounded-b-12 px-12 py-10 sm:p-16 mb-10 md:mb-[16px]">
        {selectedScreen === "PeopleStudent" && (
          <div className="bg-white rounded-12 px-12 pt-10 pb-8 sm:px-0 sm:pb-0 md:pt-16 md:px-[4px]">
            <h1 className="text-20 text-blue md:text-1.8 pb-10 sm:pb-16 md:pb-16">
              Tous les élèves ({students?.length})
            </h1>
            <div className="">
              <PeopleLabel
                label="Students"
                getPeople={getPeopleData}
                checkChangeHandler={checkChangeHandler}
                showCheckbox={!!students?.length}
                openDelModal={openDelModal}
                showDeleteButton={!!selectedStudents?.length}
                allSelected={students?.length === selectedStudents?.length}
              />
            </div>
            {students.map((item, index) => (
              <PeopleTeacher
                {...item}
                deleteUser={deleteUser}
                label="Student"
                key={`student-${index}`}
                checkChangeHandler={checkChangeHandler}
                setselectedScreen={setselectedScreen}
                getpeopleData={getPeopleData}
              />
            ))}
          </div>
        )}
        {selectedScreen === "PeopleTeacher" && (
          <div className="bg-white rounded-12 px-12 pt-10 pb-8 sm:px-0 sm:pb-0 md:pt-16 md:px-[4px]">
            <h1 className="text-20 text-blue md:text-1.8 pb-10 sm:pb-16 md:pb-16">
              Tous les Professeurs ({teachers?.length})
            </h1>
            <div className="">
              <PeopleLabel
                label="Teachers"
                getPeople={getPeopleData}
                showCheckbox={!!teachers?.length}
                checkChangeHandler={checkChangeHandler}
                openDelModal={openDelModal}
                showDeleteButton={!!selectedTeachers?.length}
                allSelected={teachers?.length === selectedTeachers?.length}
              />
            </div>
            {teachers.map((item, index) => (
              <PeopleTeacher
                {...item}
                deleteUser={deleteUser}
                label="Teacher"
                key={`teacher-${index}`}
                checkChangeHandler={checkChangeHandler}
                setselectedScreen={setselectedScreen}
                getpeopleData={getPeopleData}
              />
            ))}
          </div>
        )}
        {selectedScreen === "PeopleStaff" && (
          <div className="bg-white rounded-12 px-12 pt-10 pb-8 sm:px-0 sm:pb-0 md:pt-16 md:px-[4px]">
            <h1 className="text-20 text-blue md:text-1.8 pb-10 sm:pb-16 md:pb-16">
              Tous les Animateurs ({staff?.length})
            </h1>
            <div className="">
              {/* {JSON.stringify(staff)} */}
              <PeopleLabel
                label="Staff"
                getPeople={getPeopleData}
                showCheckbox={!!staff?.length}
                checkChangeHandler={checkChangeHandler}
                openDelModal={openDelModal}
                showDeleteButton={!!selectedStaff?.length}
                allSelected={staff?.length === selectedStaff?.length}
              />
            </div>
            {/* {JSON.stringify(staff)} */}
            {staff.map((item, index) => (
              <PeopleTeacher
                {...item}
                deleteUser={deleteUser}
                label="Staff"
                key={`staff-${index}`}
                checkChangeHandler={checkChangeHandler}
                setselectedScreen={setselectedScreen}
                getpeopleData={getPeopleData}
              />
            ))}
          </div>
        )}
        <Modal
          appElement={document.getElementById("root")}
          isOpen={modalDelOpen}
          onRequestClose={closeDelModal}
          className={styles.DeleteUsers}
        >
          <DeleteUserCard
            multiple
            closeModal={closeDelModal}
            deleteHandler={deleteSelected}
          />
        </Modal>
      </div>
    </>
  );
};
