import cx from "classnames";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Select from "react-select";
import { toast } from "react-toastify";
import AuthContext from "../../context/AuthContext";
import { AddStudentButton } from "../AddStudentButton/AddStudentButton";
import styles from "./AddStudentDropDown.module.scss";

const customStyles = {
  option: (base) => ({
    ...base,
    color: "#020422",
  }),
};

export const AddStudentDropDown = (props) => {
  const { authTokens } = useContext(AuthContext);
  const methods = useForm();
  const removeItem = (index) => {
    const tempSelectedOptions = selectedOptions;
    tempSelectedOptions.splice(index, 1);
    setSelectedOptions([...tempSelectedOptions]);
  };
  const [selectedSchools, setSelectedSchools] = useState([]);
  const createGroup = (groupName, options, setValue) => {
    return {
      label: (() => {
        return (
          <div
            onClick={() => {
              const tempSelectedSchools = selectedSchools;
              if (tempSelectedSchools.includes(groupName)) {
                tempSelectedSchools.splice(selectedSchools.indexOf(groupName), 1);
                // console.log("school removed", selectedOptions);
              } else {
                tempSelectedSchools.push(groupName);
                // console.log("school  added", selectedOptions);
              }
              setSelectedSchools([...tempSelectedSchools]);
              // console.log(selectedSchools);
              if (selectedSchools.indexOf(groupName) === -1) {
                setValue((value) => {
                  return value.filter(
                    (student) => student.school_name !== groupName
                  );
                });
              } else {
                setValue((value) => {
                  if (value) {
                    return value.concat(
                      options.filter((grpOpt) => !value.includes(grpOpt))
                    );
                  } else {
                    // console.log("elseeeeee", value);
                    // return (setValue = null);
                  }

                  // console.log("jckdjscdks", value);
                });
              }
            }}
          >
            {groupName}
          </div>
        );
      })(),
      options: options,
    };
  };
  const onSubmit = () => {
    const formData = new FormData();
    const selectedIds = [];
    selectedOptions.forEach((a) => selectedIds.push(a.id));
    // props.selectedOptions.forEach((a) => selectedIds.push(a.id));
    formData.append(
      "assignTo",
      selectedIds
        .reduce(function (a, b) {
          if (a.indexOf(b) < 0) a.push(b);
          return a;
        }, [])
        .join(",")
    );
    formData.append("workshop", props.workshopId);
    var requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: {
        Accept: "application/json",
        Authorization: `Token ${authTokens.token}`,
      },
      body: formData,
    };
    fetch(`${process.env.REACT_APP_API_URL}workshop-assigns/`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          props.closeModal();
          props.getPeople();
          toast.success("Élèves supprimé");
        } else if (!result.id) {
          Object.keys(result).forEach((key) =>
            methods.setError(key, { type: "custom", message: result[key] })
          );
        } else {
          const totalAddedStudents = selectedIds?.length;
          const totalGroups = props.workshopGroups?.length;
          const groupsNeeded = Math.ceil(totalAddedStudents / 3);
          const groupsToBeCreated = groupsNeeded - totalGroups;
          groupsToBeCreated > 0 &&
            props.createWorkshopGroupsHandler(groupsToBeCreated);
          toast.success("Élèves ajoutés");

          props.closeModal();
          props.getPeople();
        }
      });
  };

  const [workshopDropDown, setworkshopDropDown] = useState([]);
  const getDropDownData = async () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: {
        Accept: "application/json",
        Authorization: `Token ${authTokens.token}`,
      },
    };
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}user/`,
      requestOptions
    ).then((response) => response.json());
    // update the state
    setworkshopDropDown(response);
  };
  useEffect(() => {
    getDropDownData();
  }, []);

  function groupBy(arr, property) {
    return arr.reduce(function (memo, x) {
      if (!memo[x[property]]) {
        memo[x[property]] = [];
      }
      memo[x[property]].push(x);
      return memo;
    }, {});
  }

  var groupOptions = groupBy(workshopDropDown, "school_name");
  // console.log("oooo", groupOptions);

  var newOptions = [];
  var newOptions2 = [];
  const [selectedOptions, setSelectedOptions] = useState(
    props.selectedOptions || []
  );
  Object.keys(groupOptions).forEach((x) => {
    const tempObj = { label: x, options: groupOptions[x] };
    newOptions.push(tempObj);
  });
  newOptions.forEach((groupOptions) => {
    newOptions2.push(
      createGroup(groupOptions.label, groupOptions.options, setSelectedOptions)
    );
  });
  // console.log("dcsdcsdcs", newOptions2);

  // function handleSelect(data) {
  //   setSelectedOptions(data);
  // }

  // const students = workshopDropDown?.filter((data) => {
  //   if (data.user_type === 1) return false;
  //   if (
  //     props.selectedOptions?.filter(
  //       (selectedOption) => selectedOption.id === data.id
  //     )?.length === 0
  //   ) {
  //     return true;
  //   }
  //   return false;
  // });

  return (
    <>
      <div className="bg-white flex w-full p-10 rounded-12">
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          className="max-w-full w-full"
        >
          <div className="flex flex-col justify-between h-[350px]">
            <div className=" rounded-8 flex flex-col justify-between bg-white w-full ">
              <Select
                className="w-full"
                styles={customStyles}
                // menuIsOpen
                value={selectedOptions}
                onChange={(groupOptions) => {
                  console.log(groupOptions);
                  return setSelectedOptions(groupOptions);
                }}
                // onChange={handleSelect}
                placeholder="Sélectionner"
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                isMulti
                getOptionLabel={(option) =>
                  option.first_name + " " + option.last_name + " " + option.email
                }
                getOptionValue={(option) => option.id}
                isSearchable
                controlShouldRenderValue={false}
                options={newOptions2}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: "8px",
                  colors: {
                    ...theme.colors,
                    primary25: "#D4E6FF",
                    primary: "#93C1FF",
                    color: "#020422",
                  },
                })}
                formatOptionLabel={(student) => (
                  <div className="student-option">
                    <div className="gap-4">
                      <span className="pr-1">{student.first_name}</span>
                      <span>{student.last_name}</span>
                    </div>
                    <span>{student.email}</span>
                  </div>
                )}
              />
              <div
                className={cx(
                  styles.selectedOptions,
                  " border-none flex flex-wrap h-[202px] content-start overflow-y-scroll"
                )}
              >
                {/* {JSON.stringify(selectedOptions)}
                {JSON.stringify(props.selectedOptions)} */}
                {selectedOptions?.map((item, index) => {
                  // console.log("shsdcdslkchlsdch", index);
                  return (
                    <div
                      className="self-center"
                      key={
                        item.id +
                        "_" +
                        item.first_name +
                        "_" +
                        item.last_name +
                        "_" +
                        index
                      }
                    >
                      <div className="p-4 border bg-iceBlue bg-opacity-10 rounded-4 flex gap-8">
                        <div className="text-1.2">
                          {item.first_name + " " + item.last_name}
                        </div>
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            console.log("khcgdc", index);
                            removeItem(index);
                          }}
                          className=""
                        >
                          <img
                            alt="cross icon"
                            src={`${process.env.PUBLIC_URL}/images/Cross1.svg`}
                            className="max-w-20"
                          />
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div>
              <div className="pt-10 sm:pt-10">
                <AddStudentButton closeModal={props.closeModal} />
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
