import cx from "classnames";
import React, { useContext, useEffect, useState } from "react";
import Modal from "react-modal";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { DeleteUserCard } from "../../components/DeleteUserCard/DeleteUserCard";
import { EditProfile } from "../../components/EditProfile/EditProfile";
import { EventWorkshop } from "../../components/EventWorkshop/EventWorkshop";
import { LoadingScreen } from "../../components/LoadingScreen/LoadingScreen";
import { NoData } from "../../components/NoData/NoData";
import { StudentProfileItem } from "../../components/StudentProfileItem/StudentProfileItem";
import { StudentProfileMenu } from "../../components/StudentProfileMenu/StudentProfileMenu";
import AuthContext from "../../context/AuthContext";

import styles from "./StudentProfile.module.scss";

export const StudentProfile = () => {
  const navigate = useNavigate();
  let { authTokens, user } = useContext(AuthContext);
  const [selectedUser, setSelectedUser] = useState({});
  const params = useParams();

  const ProfileSummary = [
    {
      profileImage: "profile.png",
      profileName: "Georgie Ellis",
      role: "Student",
    },
  ];
  var assignOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  const tempWorkshopDate = new Date(selectedUser.date_joined).toLocaleString(
    "en-US",
    assignOptions
  );
  const ProfileMenu = [
    {
      icon: "calendarIcon.svg",
      subTitle: "Reg. Date",
      text: tempWorkshopDate,
    },
    {
      icon: "email.svg",
      subTitle: "Email",
      text: selectedUser.email,
    },
    {
      icon: "phone.svg",
      subTitle: "Contact Number",
      text: selectedUser.phone_number || "-",
    },
    {
      icon: "location.svg",
      subTitle: "Location",
      text: selectedUser.location || "-",
    },
  ];

  var requestOptions = {
    method: "GET",
    redirect: "follow",
    headers: {
      Accept: "application/json",
      Authorization: `Token ${authTokens.token}`,
    },
  };

  const [upcomingWorkshop, setupcomingWorkshop] = useState([]);
  const getUpcomingWorkshopData = async () => {
    setLoading(true);
    var requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Token ${authTokens.token}`,
      },
    };
    const response = await fetch(
      process.env.REACT_APP_API_URL + "upcoming-workshops/",
      requestOptions
    ).then((response) => response.json());
    setLoading(false);
    setupcomingWorkshop(response);
  };
  useEffect(() => {
    getUpcomingWorkshopData();
  }, []);

  var size = 3;
  const [studentWorkshop, setstudentWorkshop] = useState([]);
  const [loading, setLoading] = useState(false);
  const getWorkshopData = async () => {
    setLoading(true);
    const response = await fetch(
      process.env.REACT_APP_API_URL + "user-workshops-list/",
      requestOptions
    ).then((response) => response.json());
    setLoading(false);
    setstudentWorkshop(response);
  };
  const getUserProfile = async (id) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}user/${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .catch((error) => {
        console.log(error);
        toast.error(error.message);
      });
    setSelectedUser(response);
  };
  useEffect(() => {
    getWorkshopData();
    if (params.id) {
      getUserProfile(params.id);
    } else {
      setSelectedUser(user);
    }
  }, [params.id, user]);

  const deleteUser = (referenceId, callback) => {
    var requestOptions = {
      method: "DELETE",
      redirect: "follow",
      headers: {
        Accept: "application/json",
        Authorization: `Token ${authTokens.token}`,
        "Content-Type": "application/json",
      },
    };
    fetch(
      `${process.env.REACT_APP_API_URL}user/${referenceId}/`,
      requestOptions
    ).then((response) => {
      if (response.ok) {
        toast.success("Utilisateur supprimé");
        navigate("/people");
        callback();
      }
    });
  };

  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalDelOpen, setmodalDelOpen] = React.useState(false);
  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }
  function openDelModal() {
    setmodalDelOpen(true);
  }
  function closeDelModal() {
    setmodalDelOpen(false);
  }
  const removeHandler = () => {
    deleteUser(selectedUser?.id, closeDelModal);
  };

  return (
    <>
      <div className="bg-white pb-10 rounded-12 mb-10 sm:mb-4">
        <div className={styles.bgImg}></div>
        {selectedUser.id && (
          <>
            <div className="flex justify-between sm:flex-col">
              <div className="">
                {ProfileSummary.map((item, index) => (
                  <StudentProfileItem
                    key={`student-profile-item-${index}`}
                    {...item}
                    user={selectedUser}
                  />
                ))}
              </div>
              <div className="p-10 md:p-16">
                <div className="flex gap-16 grid-cols-4">
                  <button
                    onClick={openModal}
                    className={cx(
                      "flex col-span-2 px-12 py-8 rounded-4 w-[90px] bg-bgGreen justify-center group hover:bg-green"
                    )}
                  >
                    <div className="flex gap-2 items-center justify-center text-center">
                      <div className="group-hover:hidden">
                        <img
                          alt="edit button"
                          src={`${process.env.PUBLIC_URL}/images/edit.svg`}
                          className=""
                        />
                      </div>
                      <div className="hidden group-hover:block">
                        <img
                          alt="edit button"
                          src={`${process.env.PUBLIC_URL}/images/editWhite.svg`}
                          className=""
                        />
                      </div>
                      <div className="text-green group-hover:text-white text-1.2">
                        Éditer
                      </div>
                    </div>
                  </button>
                  {selectedUser.id !== user.id && (
                    <button
                      onClick={openDelModal}
                      className={cx(
                        "flex col-span-2 border px-12 py-8 rounded-4 w-[90px] bg-bgred group hover:bg-errorRed"
                      )}
                    >
                      <div className="flex gap-2 items-center">
                        <div className="group-hover:hidden">
                          <img
                            alt="edit button"
                            src={`${process.env.PUBLIC_URL}/images/trashRed.svg`}
                            className=""
                          />
                        </div>
                        <div className="group-hover:block hidden">
                          <img
                            alt="edit "
                            src={`${process.env.PUBLIC_URL}/images/trashWhite.svg`}
                            className=""
                          />
                        </div>
                        <div className="text-errorRed text-1.2 group-hover:text-white">
                          Supprimer
                        </div>
                      </div>
                    </button>
                  )}
                  <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    className={styles.EditProfile}
                  >
                    <EditProfile
                      user={selectedUser}
                      isLoggedIn={!params.id}
                      closeModal={closeModal}
                    />
                  </Modal>
                  <Modal
                    isOpen={modalDelOpen}
                    onRequestClose={closeModal}
                    className={styles.EditProfile}
                  >
                    <DeleteUserCard
                      user={selectedUser}
                      closeModal={closeDelModal}
                      deleteHandler={removeHandler}
                    />
                  </Modal>
                </div>
              </div>
            </div>
            <div className="flex grid grid-cols-4 gap-4 lg:grid-cols-2 sm:grid-cols-1 mt-3 md:mt-0 px-10 md:px-16">
              {ProfileMenu.map((item, index) => (
                <StudentProfileMenu
                  key={`student-profile-menu-${index}`}
                  {...item}
                />
              ))}
            </div>
          </>
        )}
      </div>
      {user.user_type !== 1 && (
        <div className="bg-white rounded-12 p-10">
          <div className="">
            <h1 className="text-20 text-blue md:mb-5">Prochains Ateliers</h1>
          </div>
          {loading ? (
            <LoadingScreen />
          ) : studentWorkshop?.length ? (
            <section className="grid grid-cols-3 gap-10 sm:flex sm: flex-col smd:grid-cols-2 xlm:grid-cols-2 my-10">
              {studentWorkshop?.slice(0, size).map((item, index) => (
                <EventWorkshop key={`event-workshop-${index}`} {...item.workshop} />
              ))}
            </section>
          ) : (
            <noData />
          )}
        </div>
      )}
      {user?.user_type === 1 && (
        <div className="bg-white rounded-12 p-10 mt-10 md:mt-[16px] mb-10 md:mb-[16px] md:p-16">
          <div className="">
            <h1 className="text-20 text-blue md:text-1.8">Prochains Ateliers</h1>
          </div>
          {loading ? (
            <LoadingScreen />
          ) : upcomingWorkshop?.length ? (
            <section className="grid grid-cols-3 gap-10 sm:flex sm: flex-col smd:grid-cols-2 xlm:grid-cols-2 mt-10 md:gap-16">
              {upcomingWorkshop?.slice(0, size).map((item, index) => (
                <EventWorkshop key={`student-event-workshop-${index}`} {...item} />
              ))}
            </section>
          ) : (
            <NoData />
          )}
        </div>
      )}
    </>
  );
};
