import cx from "classnames";
import React from "react";
import styles from "./Dashboard.module.scss";
export const Dashboard = (props) => {
  return (
    <div className="grid-cols-12 flex-grow flex-basis-0 min-w-0">
      <div className="sm: col-span-12, md:col-span-12 gap-10, lg:col-span-12, xl:col-span-12 , 2xl:col-span-12 ">
        <div className={cx(styles[props.theme], styles["dashboard-container"])}>
          <h1 className={styles.heading}>{props.title}</h1>
          <p className={styles.text}>{props.subTitle}</p>
        </div>
      </div>
    </div>
  );
};
