import cx from "classnames";
import React, { useContext } from "react";
import AuthContext from "../../context/AuthContext";
import styles from "./WorkshopGroupPeopleCard.module.scss";

export const WorkshopGroupPeopleCard = (props) => {
  const { user } = useContext(AuthContext);

  return (
    <>
      <div className="">
        <div className="pt-8 pb-8 ">
          <div className="group/group justify-between flex rounded-12 sm:px-20 items-center hover:bg-[rgba(104,213,219,0.05)] overflow-hidden">
            <div className="flex gap-5 align-middle sm:gap-3 w-[calc(100%-40px)] items-center">
              <div className="rounded-12 overflow-hidden min-w-[40px] h-[40px]">
                <img
                  alt="profile"
                  src={props.profile_pic}
                  className={cx(styles.profile)}
                />
              </div>
              <div
                className={cx(
                  "text-16 md:text-1.4 w-full whitespace-nowrap text-ellipsis overflow-hidden max-w-[calc(100%-40px)]",
                  styles.name
                )}
              >
                <p
                  title={`${props.first_name} ${props.last_name}`}
                  className=" whitespace-nowrap text-ellipsis overflow-hidden"
                >
                  {props.first_name} {props.last_name}
                </p>
              </div>
            </div>
            {user.user_type === 1 && (
              <div className="md:flex mmd:hidden group-hover/group:flex min-w-[30px] hover:bg-[rgba(196,51,51,0.05)] p-[5px]">
                <button
                  title="Remove student from group"
                  onClick={() => {
                    props.removeStudentFromGroupHandler(props.referenceId);
                  }}
                >
                  <img
                    alt="options icon"
                    src={`${process.env.PUBLIC_URL}/images/dash.svg`}
                    className={cx(styles["img-cross"])}
                  />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
