import cx from "classnames";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { toast } from "react-toastify";
import AuthContext from "../../context/AuthContext";

export const AddStudentModal = (props) => {
  const { authTokens } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);

  const getCustomStyles = (fieldName) => {
    const customStyles = {
      control: (base) => ({
        ...base,
        padding: "5px 8px 6px 8px",
        borderColor: errors[fieldName]?.message ? "#EB5757 !important" : "#d4e6ff",
        boxShadow: "0 !important",
        "&:hover": {
          borderColor: "#2684ff",
        },
      }),
      valueContainer: (base) => ({
        ...base,
        paddingTop: "0 !important",
        paddingBottom: "0 !important",
      }),
      singleValue: (base) => ({
        ...base,
        fontSize: "12px",
      }),
      menuList: (base) => ({
        ...base,
        paddingTop: "0 !important",
        paddingBottom: "0 !important",
        borderRadius: "4px",
      }),
      option: (base) => ({
        ...base,
        color: "#020422",
      }),
    };
    return customStyles;
  };
  const userOptions = [
    { label: "Staff", value: 1 },
    { label: "Teacher", value: 2 },
    { label: "Student", value: 3 },
  ];
  const [schoolOptions, setschoolOptions] = useState([]);
  const getSchool = async () => {
    const response = await fetch(process.env.REACT_APP_API_URL + "add-schools").then(
      (response) => response.json()
    );
    setschoolOptions(response);
  };
  useEffect(() => {
    getSchool();
  }, []);

  const methods = useForm();
  const {
    register,
    handleSubmit,
    setError,

    formState: { errors },
  } = methods;
  const onSubmit = (data) => {
    data.phone_number = null;
    data.location = null;
    var requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Token ${authTokens.token}`,

        "Content-Type": "application/json",
      },

      body: JSON.stringify({
        ...data,
      }),
    };
    setIsLoading(true); //Disable/able the Add Button
    fetch(process.env.REACT_APP_API_URL + "user/", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (!result.id) {
          Object.keys(result).forEach((key) =>
            setError(key, { type: "custom", message: result[key] })
          );
        } else {
          toast.success("Utilisateur ajouté");
          props.getPeople();
          props.closeModal();
          methods.reset();
        }
        setIsLoading(false);
      })
      .catch((error) => {
        toast.error(error.message);
        setIsLoading(false);
      });
  };

  return (
    <>
      <div className="bg-white rounded-12 p-10  mb-10">
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <div className="">
            <h2 className="text-20 text-blue pb-10 sm:pb-16 sm:text-1.8">
              Ajouter un profil
            </h2>
          </div>

          <div className="">
            <div className="grid grid-cols-12 flex gap-16 sm:grid-cols-6">
              <div className="col-span-6 w-full">
                <h1 className="text-1.4 pb-8 sm:pb-8 sm:1.2">Prénom</h1>
                <div className="w-full">
                  <input
                    className={cx(
                      "border border-solid leading-15 text-1.2 rounded-8 w-full px-16 py-17 mb-[4px]",
                      { "!border-errorRed": errors?.first_name?.message }
                    )}
                    type="text"
                    placeholder="Écrire le prénom"
                    {...register("first_name", {
                      required: "This field may not be blank.",
                    })}
                  />
                </div>
                <div className="text-red-500 h-[12px]">
                  {errors?.first_name?.message && errors.first_name.message}
                </div>
              </div>
              <div className="col-span-6 w-full">
                <h1 className="text-1.4 pb-8 sm:pb-8 sm:1.2">Nom de famille</h1>
                <div className="w-full">
                  <input
                    className={cx(
                      "border border-solid leading-15 text-1.2 rounded-8 w-full px-16 py-17 mb-[4px]",
                      { "!border-errorRed": errors?.last_name?.message }
                    )}
                    type="text"
                    placeholder="Écrire le nom de famille"
                    {...register("last_name", {
                      required: "This field may not be blank.",
                    })}
                  />
                </div>
                <div className="text-red-500 h-[12px]">
                  {errors?.last_name?.message && errors.last_name.message}
                </div>
              </div>
            </div>
            <div className="grid-cols-12 flex gap-16 pt-16 sm:grid-cols-6 sm:pt-16">
              <div className="col-span-6 w-full">
                <h1 className="text-1.4 pb-8 sm:pb-8 sm:1.2">Email</h1>
                <div className="">
                  <input
                    autoComplete="new-password"
                    className={cx(
                      "border border-solid leading-15 text-1.2 rounded-8 px-16 py-17 w-full mb-[4px]",
                      { "!border-errorRed": errors?.email?.message }
                    )}
                    type="email"
                    placeholder="Écrire l’adresse email"
                    {...register("email", {
                      required: "This field may not be blank.",
                    })}
                  />
                </div>
                <div className="text-red-500 h-[12px]">
                  {errors?.email?.message && errors.email.message}
                </div>
              </div>
            </div>
            <div className="flex gap-16 pt-16 sm:grid-cols-6 sm:pt-16">
              <div className="col-span-6 w-full ">
                <h1 className="text-1.4 pb-8 sm:pb-8 sm:1.2">Type de profil</h1>
                <div className="">
                  <Controller
                    className="border rounded-12 px-16 py-17 max-w-full w-full"
                    control={methods.control}
                    name="user_type"
                    rules={{ required: "This field may not be blank." }}
                    render={({ field: { onChange, value, ref } }) => (
                      <Select
                        className="react-select-container mb-[4px]"
                        inputRef={ref}
                        styles={getCustomStyles("user_type")}
                        classNamePrefix="react-select"
                        options={userOptions}
                        placeholder="Sélectionner"
                        value={userOptions?.find((c) => c.value === value)}
                        onChange={(val) => onChange(val.value)}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: "8px",
                          colors: {
                            ...theme.colors,
                            primary25: "#D4E6FF",
                            primary: "#93C1FF",
                            color: "#020422",
                          },
                        })}
                      />
                    )}
                  />
                  <div className="text-red-500 h-[16px]">
                    {errors?.user_type?.message && errors.user_type.message}
                  </div>
                </div>
              </div>
              <div className="col-span-6 w-full ">
                <h1 className="text-1.4 pb-8 sm:pb-8 sm:1.2">
                  Établissement scolaire
                </h1>
                <div className="">
                  <Controller
                    className="border rounded-12 px-16 py-17 max-w-full w-full"
                    control={methods.control}
                    name="schools"
                    rules={{ required: "This field may not be blank." }}
                    render={({ field: { onChange, ref } }) => (
                      <Select
                        className="react-select-container mb-[4px]"
                        inputRef={ref}
                        menuPlacement="auto"
                        placeholder="Sélectionner"
                        styles={getCustomStyles("schools")}
                        classNamePrefix="react-select"
                        getOptionLabel={(option) => option.school_name}
                        getOptionValue={(option) => option.id}
                        options={schoolOptions}
                        onChange={(val) => onChange(val.id)}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: "8px",
                          colors: {
                            ...theme.colors,
                            primary25: "#D4E6FF",
                            primary: "#93C1FF",
                            color: "#020422",
                          },
                        })}
                      />
                    )}
                  />
                  <div className="text-red-500 h-[16px]">
                    {errors?.schools?.message && errors.schools.message}
                  </div>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-12 flex gap-16 pt-16 sm:grid-cols-6 sm:pt-16">
              <div className="col-span-6 w-full">
                <h1 className="text-1.4 pb-8 sm:pb-8 sm:1.2">Mot de passe</h1>
                <div className="">
                  <input
                    autoComplete="new-password"
                    className={cx(
                      "border border-solid leading-15 text-1.2 rounded-8 w-full px-16 py-17 mb-[4px]",
                      { "!border-errorRed": errors?.password?.message }
                    )}
                    type="password"
                    placeholder="Créer un mot de passe"
                    {...register("password", {
                      required: "This field may not be blank.",
                    })}
                  />
                </div>
                <div className="text-red-500 h-[16px]">
                  {errors?.password?.message && errors.password.message}
                </div>
              </div>
              <div className="col-span-6 w-full">
                <h1 className="text-1.4 pb-8 sm:pb-8 sm:1.2">
                  Confirmer mot de passe
                </h1>
                <div className="">
                  <input
                    className={cx(
                      "border border-solid leading-15 text-1.2 rounded-8 w-full px-16 py-17 mb-[4px]",
                      { "!border-errorRed": errors?.confirm_password?.message }
                    )}
                    type="password"
                    placeholder="Écrire mot de passe"
                    {...register("confirm_password", {
                      required: "This field may not be blank.",
                    })}
                  />
                </div>
                <div className="text-red-500 h-[16px]">
                  {errors?.confirm_password?.message &&
                    errors.confirm_password.message}
                </div>
              </div>
            </div>
            <div className="pt-32 sm:pt-10">
              <div className="flex gap-16 grid-cols-12">
                <button
                  onClick={props.closeModal}
                  className="col-span-6 w-full border !font-medium border-green text-green p-16 rounded-8 leading-16 text-1.4 border-solid hover:bg-[#17A4541A]"
                >
                  Annuler
                </button>
                <button
                  type="submit"
                  disabled={isLoading}
                  className="col-span-6 w-full border !font-medium text-white bg-green p-16 rounded-8 leading-16 text-1.4 border-solid hover:bg-[#037f39] "
                >
                  Créer un profil
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
