import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../context/AuthContext";
import { NoData } from "../NoData/NoData";
import { WorkshopAssignment } from "../WorkshopAssignment/WorkshopAssignment";
import { WorkshopContent } from "../WorkshopContent/WorkshopContent";
import { WorkshopStudentAssignment } from "../WorkshopStudentAssignment/WorkshopStudentAssignment";
import { WorkshopVideoSection } from "../WorkshopVideoSection/WorkshopVideoSection";

export const WorkshopDetailContent = (props) => {
  const { authTokens, user } = useContext(AuthContext);

  const videos = props.attachments?.filter(
    (content) => content.type === "media" && content.user === 1
  );
  const documents = props.attachments?.filter(
    (content) => content.type === "document" && content.user === 1
  );
  const assignments = props.attachments?.filter((content) => content.user !== 1);

  var requestOptions = {
    method: "GET",
    redirect: "follow",
    headers: {
      Accept: "application/json",
      Authorization: `Token ${authTokens.token}`,
    },
  };
  //User API to get user details
  const [peopleData, setpeopleData] = useState([]);
  const getPeopleData = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}user/`,
      requestOptions
    ).then((response) => response.json());
    setpeopleData(response);
  };
  useEffect(() => {
    getPeopleData();
  }, []);
  //Object
  let workshopData = assignments?.map((activity) => {
    activity.user_details_object =
      activity.user_details &&
      peopleData.filter((user) => user.id === activity.user_details)[0];
    return activity;
  });

  let teacherAssignments = workshopData?.filter((workshop) => {
    return workshop.user_details === user.id;
  });

  return (
    <>
      <div className="bg-white p-10 md:px-16 md:pb-16 md:pt-10 rounded-b-xl ">
        <h1 className="text-20 text-blue md:text-1.8">Contenus multimédias</h1>
        <div className="">
          <WorkshopVideoSection
            workshopId={props.workshopId}
            videoData={videos}
            getWorkshopData={props.getWorkshopData}
            detailWorkshop={props.detailWorkshop}
          />
        </div>
      </div>
      <div className="bg-white p-10 md:p-16 rounded-12 my-10 md:my-[16px]">
        <h1 className="text-20 text-blue md:text-1.8">Contenus pédagogiques</h1>
        <div className="">
          <WorkshopContent
            documentData={documents}
            workshopId={props.workshopId}
            getWorkshopData={props.getWorkshopData}
            detailWorkshop={props.detailWorkshop}
          />
        </div>
      </div>
      {/* Student & Teacher */}
      {(user.user_type === 3 || user.user_type === 2) && (
        <div className="bg-white p-10 md:p-16 rounded-12 my-10 md:my-[16px]">
          <h1 className="text-20 text-blue pb-10 md:text-1.8">Exercices/Devoirs</h1>
          <div className="">
            <WorkshopAssignment
              assignmentData={teacherAssignments}
              workshopId={props.workshopId}
              detailWorkshop={props.detailWorkshop}
              getWorkshopData={props.getWorkshopData}
            />
          </div>
        </div>
      )}
      {/* Assignment List */}
      {(user.user_type === 1 || user.user_type === 2) && (
        <div className="bg-white p-10 md:p-16 rounded-12 my-10 md:my-[16px]">
          <h1 className="text-20 text-blue md:text-1.8">
            Exercices/Devoirs des élèves
          </h1>
          <div className="">
            {workshopData?.length ? (
              workshopData?.map((item, index) => (
                <WorkshopStudentAssignment
                  key={`workshop-student-assignment-${index}`}
                  workshopId={props.workshopId}
                  detailWorkshop={props.detailWorkshop}
                  getWorkshopData={props.getWorkshopData}
                  {...item}
                />
              ))
            ) : (
              <NoData />
            )}
          </div>
        </div>
      )}
    </>
  );
};
