import React from "react";
import { NavLink } from "react-router-dom";

export const ProfileLabels = (props) => {
  return (
    <div className="col-span-4 flex w-full px-10 hover:bg-[rgba(104,213,219,0.05)]">
      <NavLink
        className="flex w-full items-center"
        to={`${props.link}`}
        onClick={(e) => {
          if (props.onClickHandler) {
            e.preventDefault();
            props.onClickHandler();
          }
        }}
      >
        <div className="col-span-1 pr-16 py-12">
          <img
            alt="icon"
            src={`${process.env.PUBLIC_URL}/images/${props.icon}`}
            className="w-[25px] h-[25px]"
          />
        </div>
        <div className="col-span-3 text-16 text-opacity-15 py-12 md:text-1.4 w-full">
          {props.title}
        </div>
      </NavLink>
    </div>
  );
};
