import React, { useEffect, useState } from "react";
import Select from "react-select";
import styles from "./FilterComponent.module.scss";

const customStyles = {
  control: (base) => ({
    ...base,
    display: "flex",
    width: "110px",
    boxShadow: "none !important",
  }),
  menu: (base) => ({
    ...base,
    borderRadius: "8px",
    paddingTop: "0",
    paddingBottom: "0",
  }),
  input: (base) => ({
    ...base,
    padding: "0 !important",
  }),
  valueContainer: (base) => ({
    ...base,
    paddingTop: "0 !important",
    paddingBottom: "0 !important",
    paddingRight: "0",
  }),

  singleValue: (base) => ({
    ...base,
    fontSize: "12px",
  }),
  indicatorContainer: (base) => ({
    ...base,
    padding: "0 !important",
    marginLeft: "-40px !important",
  }),
  indicatorSeparator: (base) => ({
    ...base,
    padding: "0 !important",
  }),
  placeholder: (base) => ({
    ...base,
    fontSize: "9px",
  }),
  menuList: (base) => ({
    ...base,
    paddingTop: "0 !important",
    paddingBottom: "0 !important",
    borderRadius: "4px",
  }),
  option: (base) => ({
    ...base,
    color: "#020422",
  }),
  dropdownIndicator: (base) => ({
    ...base,
  }),
};

export const FilterComponent = (props) => {
  const [workshopType, setworkshopType] = useState([]);
  const getTypeData = async () => {
    const response = await fetch(process.env.REACT_APP_API_URL + "ws-types/").then(
      (response) => response.json()
    );
    const defaultOptions = { type_name: "Default", id: -1, status: true };
    response.unshift(defaultOptions);
    setworkshopType(response);
  };
  useEffect(() => {
    getTypeData();
  }, []);

  const [workshopLevel, setworkshopLevel] = useState([]);
  const getLevelData = async () => {
    const response = await fetch(process.env.REACT_APP_API_URL + "ws-levels/").then(
      (response) => response.json()
    );
    const defaultOptions = { level_name: "Default", id: -1, status: true };
    response.unshift(defaultOptions);
    setworkshopLevel(response);
  };
  useEffect(() => {
    getLevelData();
  }, []);

  return (
    <div className="flex md:justify-between ssm:gap-4 ssm:items-center">
      <div className="flex md:pl-0 pl-10 items-center">
        <div className="pr-4 sm:pr-[5px] col-span-2 md:text-1.2 flex text-ashgray text-xl mmd:text-1.4 leading-16">
          <span className="md:hidden pr-2">Format</span> atelier:
        </div>
        <div className="col-span-2 flex">
          <Select
            className={styles["react-select-container"]}
            id={styles.select}
            styles={customStyles}
            placeholder="Sélectionner"
            classNamePrefix="react-select"
            getOptionLabel={(option) => option.type_name}
            getOptionValue={(option) => option.id}
            value={
              workshopType.filter(
                (type) => type.type_name === props.selectedTypeOption
              )[0]
            }
            options={workshopType.filter((type) => type.status)}
            theme={(theme) => ({
              ...theme,
              borderRadius: "8px",
              colors: {
                ...theme.colors,
                primary25: "#D4E6FF",
                primary: "#93C1FF",
                color: "#020422",
              },
            })}
            onChange={(val) => {
              props.setWorkshopType(val.type_name);
            }}
          />
        </div>
      </div>
      <div className="flex md:pl-0 pl-10 items-center">
        <div className="pr-4 sm:pr-[5px] col-span-2 md:text-1.2 flex text-ashgray text-xl mmd:text-1.4 leading-16">
          <span className="md:hidden pr-2">Niveau</span> atelier:
        </div>
        <div className="col-span-2 flex">
          <Select
            className="react-select-container"
            id={styles.select}
            placeholder="Sélectionner"
            styles={customStyles}
            classNamePrefix="react-select"
            getOptionLabel={(option) => option.level_name}
            getOptionValue={(option) => option.id}
            value={
              workshopLevel.filter(
                (level) => level.level_name === props.selectedLevelOption
              )[0]
            }
            options={workshopLevel.filter((level) => level.status)}
            theme={(theme) => ({
              ...theme,
              borderRadius: "8px",
              colors: {
                ...theme.colors,
                primary25: "#D4E6FF",
                primary: "#93C1FF",
                color: "#020422",
              },
            })}
            onChange={(val) => {
              props.setWorkshopLevel(val.level_name);
            }}
          />
        </div>
      </div>
    </div>
  );
};
