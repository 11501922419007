import cx from "classnames";
import * as fetchIntercept from "fetch-intercept";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { useScrollDirection } from "react-use-scroll-direction";
import { AddStudentDropDown } from "../components/AddStudentDropDown/AddStudentDropDown";
import { LastActivityCard } from "../components/LastActivityCard/LastActivityCard";
import { LeftSideBar } from "../components/LeftSideBar/LeftSideBar";
import { MobileMenu } from "../components/MobileMenu/MobileMenu";
import { PerformanceCard } from "../components/PerformanceCard/PerformanceCard";
import { TopBar } from "../components/TopBar/TopBar";
import { WorkshopRequests } from "../components/WorkshopRequests/WorkshopRequests";
import AuthContext from "../context/AuthContext";
import useOutsideAlerter from "../hooks/outsideClickHook";
import PrivateRoute from "../utils/PrivateRoute";
import { AddStudentModal } from "./AddStudentModal/AddStudentModal";
import { CreateWorkshop } from "./CreateWorkshop/CreateWorkshop";
import { EditWorkshop } from "./EditWorkshop/EditWorkshop";
import { Login } from "./Login/Login";
import Overview from "./Overview/Overview";
import { PasswordRecovery } from "./PasswordRecovery/PasswordRecovery";
import { People } from "./People/People";
import { RequestWorkshop } from "./RequestWorkshop/RequestWorkshop";
import { StudentProfile } from "./StudentProfile/StudentProfile";
import { WorkshopDetail } from "./WorkshopDetail/WorkshopDetail";
import Workshops from "./Workshops/Workshops";
import { WorkshopSettings } from "./WorkshopSettings/WorkshopSettings";
export const Dashboard = () => {
  const controller = new AbortController();
  const { user, authTokens } = useContext(AuthContext);
  const [interceptorLoaded, setInterceptorLoaded] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const unregister = fetchIntercept.register({
      request: function (url, config = { headers: {} }) {
        config.headers.Accept = "application/json";
        authTokens?.token &&
          (config.headers.Authorization = `Token ${authTokens?.token}`);
        config.signal = controller.signal;
        return [url, config];
      },

      requestError: function (error) {
        console.log(error);
        return Promise.reject(error);
      },

      response: function (response) {
        console.log(`response from ${response.request.url} - ${response.status}`);
        const clonedResponse = response.clone();
        const json = () => clonedResponse.json().then((data) => data);
        if (response.status === 403) {
          window.location = `${process.env.REACT_APP_SUBPAGE}/login`;
        }

        response.json = json;
        if (response.status === 400 && response.url.includes("/login/")) {
          return response;
        }
        if (!response.ok) {
          toast.error(
            <div>
              <span>{response.statusText}</span>
              <br />
              <span>Merci de contacter le support</span>
            </div>,
            {
              toastId: response.statusText,
            }
          );
        }
        return response;
      },

      responseError: function (error) {
        console.log(error);
        if (error.code === 20) {
          console.log("Request aborted", error);
        } else if (error.message) {
          toast.error(
            <div>
              <span>{error.message}</span>
              <br />
              <span>Merci de contacter le support</span>
            </div>,
            {
              toastId: error.message,
            }
          );
        }
        controller.abort();
        return Promise.reject(error);
      },
    });
    setInterceptorLoaded(true);

    return () => {
      window.removeEventListener("scroll", onScroll);
      unregister();
    };
  }, [authTokens, user]);
  const [offset, setOffset] = useState(0);

  const onScroll = () => {
    setOffset(window.pageYOffset);
  };
  const [direction, setDirection] = useState(null);
  const { isScrollingUp, isScrollingDown, isScrolling } = useScrollDirection();
  useEffect(() => {
    if (isScrolling && isScrollingUp) setDirection("up");
    if (isScrolling && isScrollingDown) setDirection("down");
  }, [isScrolling]);

  useEffect(() => {
    window.addEventListener("scroll", onScroll, { passive: true });
    () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);
  const MainItems = [
    {
      text: "Vue d’ensemble",
      link: "/",
      icon: "category.svg",
      activeIcon: "active-category.svg",
      visibility: [1, 2, 3],
    },
    {
      text: "Atelier",
      link: "/workshops",
      icon: "note.svg",
      activeIcon: "active-note.svg",
      visibility: [1, 2, 3],
    },
    {
      text: "A l’agenda",
      link: "/upcoming-workshops",
      icon: "calendar.svg",
      activeIcon: "active-calendar.svg",
      visibility: [1, 2, 3],
    },
    {
      text: "Demander un atelier",
      link: "/workshop-request",
      icon: "add-circle.svg",
      activeIcon: "active-add-circle.svg",
      visibility: [2],
    },
    {
      text: "Ateliers dupliqués",
      link: "/cloned-workshops",
      icon: "Clone.svg",
      activeIcon: "ActiveClone.svg",
      visibility: [1],
    },
    {
      text: "Ateliers demandés",
      link: "/requested-workshops",
      icon: "add-circle.svg",
      activeIcon: "active-add-circle.svg",
      visibility: [1],
    },
    {
      text: "Créer un atelier",
      link: "/create-workshop",
      icon: "add-circle.svg",
      activeIcon: "active-add-circle.svg",
      visibility: [1],
    },

    {
      text: "Profils",
      link: "/people",
      icon: "profile-2user.svg",
      activeIcon: "active-profile-2user.svg",
      visibility: [1],
    },
    {
      text: "Paramètres",
      link: "/workshop-settings",
      icon: "setting.svg",
      activeIcon: "active-setting.svg",
      visibility: [1],
    },
  ];
  const FooterItems = [];
  const ProfileItems = {
    image: "profile.png",
    name: "Amna ",
    subTitle: "student",
    profileIcon: "dots.svg",
  };

  const MobileItems = [
    {
      logo: "mobileLogo.svg",
      icon: "Search.png",
      image: "profile.png",
      dotIcon: "dots.svg",
    },
  ];
  const [isShown, setIsShown] = useState(false);
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, () => {
    setIsShown(false);
  });

  const [isDrop, setIsDrop] = useState(false);
  const dropperRef = useRef(null);
  useOutsideAlerter(dropperRef, () => {
    setIsDrop(false);
  });
  const isLoginPage =
    location.pathname === "/login" ||
    location.pathname.includes("/password-recovery");
  return (
    <>
      <div className="App">
        <div className="grid grid-cols-10 gap-10">
          {!isLoginPage && (
            <div className="col-span-2 md:hidden lg:visible sticky top-0 h-screen">
              <LeftSideBar mainNav={MainItems} footerNav={FooterItems} />
            </div>
          )}
          <main className="col-span-8 pr-10 md:pr-16 md:pl-16 sm:overflow-hidden md:col-span-12 lg:col-span-8 lg:pl-0">
            <div
              className={cx(
                "col-span-12 mmd:hidden right-24 left-24 transition-all ease-in-out md:mt-[16px] z-[1]",
                direction === "up" && offset > 40 && "topbar"
              )}
            >
              {!isLoginPage && user && (
                <section ref={wrapperRef} className="">
                  {MobileItems.map((item, index) => (
                    <MobileMenu
                      setIsShown={setIsShown}
                      isShown={isShown}
                      setIsDrop={setIsDrop}
                      isDrop={isDrop}
                      dropperRef={dropperRef}
                      stickyHeader={offset >= 40}
                      {...item}
                      key={`mobile-menu-${index}`}
                    />
                  ))}
                </section>
              )}
            </div>
            {!isLoginPage && user && (
              <div
                className={cx(
                  "md:hidden mmd:visible pt-10 z-50 rounded-12 -translate-y-[12rem] transition-all duration-300 ease-in-out relative",
                  offset <= 80 && "!translate-y-[0rem]",

                  direction === "up" &&
                    offset > 80 &&
                    "topbar !-translate-y-[2.4rem] shadow-topbar transition-all ease-in-out duration-[500ms]"
                )}
              >
                <div className={cx("search-style  relative")}>
                  <TopBar
                    setIsDrop={setIsDrop}
                    isDrop={isDrop}
                    placeholder="Search Workshop"
                    icon="Search.png"
                    {...ProfileItems}
                  />
                </div>
              </div>
            )}
            <div className={cx(user?.user_type === 1 ? "" : "md:mt-[16px]")}>
              {interceptorLoaded && (
                <Routes>
                  <Route
                    path="/login"
                    element={
                      <Login
                        key={window.location.pathname}
                        activeClassName="active"
                      />
                    }
                  />
                  <Route
                    path="/"
                    element={
                      <PrivateRoute>
                        <Overview key={window.location.pathname} />
                      </PrivateRoute>
                    }
                    activeClassName="active"
                  />
                  <Route
                    path="/workshops"
                    element={
                      <PrivateRoute>
                        <Workshops key={window.location.pathname} hideDate />
                      </PrivateRoute>
                    }
                    activeClassName="active"
                  />
                  <Route
                    path="/upcoming-workshops"
                    element={
                      <PrivateRoute>
                        <Workshops
                          key={window.location.pathname}
                          upcoming
                          activeClassName="active"
                        />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/requested-workshops"
                    element={
                      <PrivateRoute>
                        <Workshops
                          key={window.location.pathname}
                          requested
                          hideDate
                          activeClassName="active"
                        />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/cloned-workshops"
                    element={
                      <PrivateRoute>
                        <Workshops
                          key={window.location.pathname}
                          clone
                          hideDate
                          activeClassName="active"
                        />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/workshop-detail/:id"
                    element={
                      <PrivateRoute>
                        <WorkshopDetail activeClassName="active" />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/workshop-request"
                    element={
                      <PrivateRoute>
                        <RequestWorkshop
                          activeClassName="active"
                          requested
                          key={window.location.pathname}
                        />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/requested-workshops"
                    element={
                      <PrivateRoute>
                        <WorkshopRequests activeClassName="active" />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/create-workshop"
                    element={
                      <PrivateRoute>
                        <CreateWorkshop activeClassName="active" />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/edit-workshop/:id"
                    element={
                      <PrivateRoute>
                        <EditWorkshop activeClassName="active" />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/people"
                    element={
                      <PrivateRoute>
                        <People activeClassName="active" />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/add-student"
                    element={
                      <PrivateRoute>
                        <AddStudentModal activeClassName="active" />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/student-profile"
                    element={
                      <PrivateRoute>
                        <StudentProfile activeClassName="active" />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/student-profile/:id"
                    element={
                      <PrivateRoute>
                        <StudentProfile activeClassName="active" />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/last-activity"
                    element={
                      <PrivateRoute>
                        <LastActivityCard activeClassName="active" />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/workshop-performance"
                    element={
                      <PrivateRoute>
                        <PerformanceCard activeClassName="active" />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/workshop-settings"
                    element={
                      <PrivateRoute>
                        <WorkshopSettings activeClassName="active" />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/student-d"
                    element={
                      <PrivateRoute>
                        <AddStudentDropDown activeClassName="active" />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/password-recovery/:uid/:token"
                    element={<PasswordRecovery activeClassName="active" />}
                  />
                </Routes>
              )}
            </div>
          </main>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};
