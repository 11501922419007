import cx from "classnames";
import React from "react";
import { ActivityCard } from "../ActivityCard/ActivityCard";
import styles from "./TeacherActivityRow.module.scss";

export const TeacherActivityRow = (props) => {
  return (
    <div className={cx(styles.row, "row", styles.TeacherActivityRowStyle)}>
      <div className={cx(styles.col, "col", "col-12", "col-lg-4")}>
        <ActivityCard {...props} data={props.data} />
      </div>

      <div
        className={cx(
          styles.col,
          "col capitalize text-1.4 leading-16",
          "col-12",
          "col-lg-4"
        )}
      >
        {props.workshop_object?.workshop_name}
      </div>
      <div
        className={cx(
          styles.col,
          "col capitalize text-1.4 leading-16",
          "col-12",
          "col-lg-4"
        )}
      >
        Uploaded {props.type}
      </div>
    </div>
  );
};
