import cx from "classnames";
import React, { useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { useFormContext } from "react-hook-form";
import { toast } from "react-toastify";

export const ImageInput = (props) => {
  const { name } = props;
  const { register, unregister, setValue, watch } = useFormContext();
  const files = watch(name);
  const onDropAccepted = useCallback(
    (droppedFiles) => {
      setValue(name, droppedFiles, { shouldValidate: true });
      props.onChangeHandler && props.onChangeHandler();
    },
    [(setValue, name)]
  );
  const onDropRejected = useCallback((rejectedFiles) => {
    toast.error(rejectedFiles[0].errors[0].message);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDropAccepted,
    onDropRejected,
    accept: props.accept,
    maxSize: 10000000,
  });
  useEffect(() => {
    register(name);
    return () => {
      unregister(name);
    };
  }, [register, unregister, name]);
  return (
    <>
      <div
        style={props.style}
        className={`border-dashed border rounded-12 w-[40%] mb-4 text-center bg-bg ${props.className}`}
      >
        {!(files?.length || props.default_value) && (
          <div className="h-[110px] pt-4">
            <div className="flex justify-center">
              <img
                alt="placeholder"
                src={`${process.env.PUBLIC_URL}/images/photo.svg`}
                className={cx()}
              />
            </div>
            <div {...getRootProps()}>
              <input
                {...props}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id={name}
                {...getInputProps()}
              />
              <div className={"w-full p-2  "}>
                <p className="text-center my-2 text-1.2">Parcourir</p>
              </div>
            </div>
          </div>
        )}
        <div className="relative">
          {(files?.length || props.defaultValue) && (
            <button
              onClick={() => {
                setValue(name, null);
              }}
              className="absolute right-[5px] top-[5px] z-[1]"
            >
              <img
                alt="icon"
                src={`${process.env.PUBLIC_URL}/images/cross.svg`}
                className="max-w-[20px]"
              />
            </button>
          )}
          {files?.length && (
            <div className="relative">
              <div key={`file-${files.name}`}>
                <img
                  src={
                    "File" in window && files[0] instanceof File
                      ? URL.createObjectURL(files[0])
                      : files
                  }
                  alt={""}
                  style={{
                    width: "100%",
                    height: "110px",
                    objectFit: "cover",
                    borderRadius: "12px",
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
