import cx from "classnames";
import React, { useContext, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import useOutsideAlerter from "../../hooks/outsideClickHook";
import { ProfileMenu } from "../ProfileMenu/ProfileMenu";
import { SearchBox } from "../SearchBox/SearchBox";
import styles from "./TopBar.module.scss";

export const TopBar = (props) => {
  const { user } = useContext(AuthContext);

  const [isDrop, setIsDrop] = useState(false);
  const dropperRef = useRef(null);
  useOutsideAlerter(dropperRef, () => {
    setIsDrop(false);
  });

  return (
    <>
      <div className=" w-full flex items-center gap-[35px]">
        <div className="relative w-[calc(100%-20rem)]">
          <SearchBox />
        </div>
        <div className="w-[20rem] flex items-center justify-end gap-4">
          <NavLink className="" to={"/student-profile"}>
            <div className="">
              <img
                alt="icon"
                src={`${user?.profile_pic}`}
                className={cx("max-w-20", styles["img-style"])}
              />
            </div>
          </NavLink>
          <div className="flex flex-col ">
            <NavLink className="flex flex-col" to={"/student-profile"}>
              <span className=" text-16 lmd:text-16 leading-16 whitespace-nowrap w-full text-ellipsis overflow-hidden">
                {user?.first_name} {user?.last_name.charAt(0)}.
              </span>
            </NavLink>
            <span className="text-1.2 leading-14 pt-6 text-ashgray">
              {user?.user_type_str}
            </span>
          </div>
          <div className={cx(styles.dropdown, "border-none bg-transparent ")}>
            <button
              onClick={() => {
                setIsDrop(!isDrop);
              }}
              className={styles.button}
            >
              <img
                alt="profile icon"
                src={`${process.env.PUBLIC_URL}/images/${props.profileIcon}`}
                className={cx(styles.dropdown, "inline-block ")}
              />
            </button>
          </div>
        </div>
      </div>

      {isDrop && (
        <div
          ref={dropperRef}
          className={cx("absolute right-0 top-94", styles.MenuWrapper)}
        >
          <div className="pt-[4px]">
            <ProfileMenu setIsDrop={setIsDrop} />
          </div>
        </div>
      )}
    </>
  );
};
