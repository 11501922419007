import cx from "classnames";
import React from "react";

export const WorkshopPerformanceRow = (props) => {
  return (
    <div className="flex leading-16 grid grid-cols-5 pt-10 md:pt-10 px-0 items-center">
      <div className={cx("text-1.4 col-span-2 pr-4 md:text-1.2")}>
        {props.workshop_name}
      </div>
      <div className={cx("text-1.4 col-span-1 pr-4 capitalize md:text-1.2")}>
        {props.type}
      </div>
      <div className={cx("text-1.4 col-span-1 pr-4 md:text-1.2 capitalize")}>
        Niveau {props.level}
      </div>
      <div className={cx("text-1.4 col-span-1 pr-4 md:text-1.2")}>
        {props.total_students}
      </div>
    </div>
  );
};
