import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import AsyncSelect from "react-select/async";
import AuthContext from "../../context/AuthContext";

const customStyles = {
  control: (base) => ({
    ...base,
    borderRadius: "8px !important",
    boxShadow: "none !important",
    paddingTop: "8px",
    paddingBottom: "8px",
    PaddingLeft: "24px !important",
    borderColor: "#d4e6ff",
    "&:hover": {
      borderColor: "#2684ff !important",
      focus: "#2684ff",
    },
  }),
  input: (base) => ({
    ...base,
    fontSize: "12px",
    lineHeight: "16px",
    fontFamily: "Stolzl",
  }),
  valueContainer: (base) => ({
    ...base,
    paddingLeft: "16px",
  }),
  menuList: (base) => ({
    ...base,
    paddingTop: "0 !important",
    paddingBottom: "0 !important",
    borderRadius: "4px",
  }),
  option: (base) => ({
    ...base,
    color: "#020422",
    fontSize: "12px",
  }),
  noOptionsMessage: (base) => ({
    ...base,
    padding: "20px 12px",
    fontSize: "12px",
  }),
};
export const SearchBox = () => {
  const { authTokens } = useContext(AuthContext);

  const navigate = useNavigate();
  const [inputValue, setValue] = useState("");
  const handleInputChange = (value) => {
    setValue(value);
  };
  const handleChange = (value) => {
    navigate(`/workshop-detail/${value.id}`);
  };

  const loadOptions = (inputValue) => {
    var requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Token ${authTokens.token}`,
      },
    };
    return fetch(
      `${process.env.REACT_APP_API_URL}add-workshop/?search=${inputValue}`,
      requestOptions
    ).then((res) => res.json());
  };
  return (
    <AsyncSelect
      className="w-full text-1.4 leading-16 rounded-8 h-full"
      cacheOptions
      classNamePrefix={"search-box"}
      styles={customStyles}
      placeholder="Rechercher un atelier..."
      value={inputValue}
      getOptionLabel={(e) => e.workshop_name}
      getOptionValue={(e) => e.id}
      loadOptions={loadOptions}
      onInputChange={handleInputChange}
      onChange={handleChange}
      isClearable={true}
      components={{
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
      }}
      noOptionsMessage={() => "Chercher un atelier"}
    />
  );
};
