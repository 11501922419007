import cx from "classnames";
import React from "react";
import styles from "./ActivityCard.module.scss";

export const ActivityCard = (props) => {
  var assignOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
  };
  const tempWorkshopDate = new Date(props.created_at).toLocaleString(
    "en-US",
    assignOptions
  );

  return (
    <div
      className={
        (styles.row, "row", styles[props.theme], styles["ActivityCard_container"])
      }
    >
      <div className={styles.rowStyle}>
        <div className={cx(styles.imgWrapper)}>
          <img
            alt="profile"
            src={`${props.user_details_object?.profile_pic}`}
            className={styles["img-style"]}
          />
        </div>

        <div className={cx(styles.wrapper)}>
          <h1 className={styles.teacherHeading}>
            {props.user_details_object?.first_name}
          </h1>
          <p className={styles.text}>{tempWorkshopDate}</p>
          <p className={styles.text}>{props.time}</p>
        </div>
      </div>
    </div>
  );
};
