import React from "react";

export const WorkshopLabels = (props) => {
  return (
    <div className=" flex lmd:gap-8 lmd:text-1.4 text-1.2 gap-4">
      <div>{props.name}</div>
      <div className="text-ashgray">{props.subTitle}</div>
    </div>
  );
};
