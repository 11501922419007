import cx from "classnames";
import React, { useContext, useRef, useState } from "react";
import Modal from "react-modal";
import { toast } from "react-toastify";
import AuthContext from "../../context/AuthContext";
import useOutsideAlerter from "../../hooks/outsideClickHook";
import { DeleteCard } from "../DeleteCard/DeleteCard";
import { NoData } from "../NoData/NoData";
import { WAssignmentOptions } from "../WAssignmentOptions/WAssignmentOptions";
import styles from "./WorkshopAssignmentCom.module.scss";

export const WorkshopAssignmentCom = (props) => {
  const { authTokens } = useContext(AuthContext);
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal(referenceId) {
    setToBeDeleted(referenceId);
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }
  const DeleteItems = [
    {
      cross: "cross1.svg",
      icon: "DelTrash.svg",
      title: "Delete Attachment?",
      text: "Deleteing this attachment will move it to the trash permanentaly.",
    },
  ];
  const [toBeDeleted, setToBeDeleted] = useState(null);
  const deleteAssignment = () => {
    var requestOptions = {
      method: "DELETE",
      redirect: "follow",
      headers: {
        Accept: "application/json",
        Authorization: `Token ${authTokens.token}`,
        "Content-Type": "application/json",
      },
    };
    fetch(
      `${process.env.REACT_APP_API_URL}workshop-view/${toBeDeleted}/`,
      requestOptions
    ).then((response) => {
      if (response.ok) {
        toast.success("Pièce-jointe supprimée");
        props.getWorkshopData();
        closeModal();
      }
    });
  };
  const [isDrop, setIsDrop] = useState(false);
  const dropperRef = useRef(null);
  useOutsideAlerter(dropperRef, () => {
    setIsDrop(-1);
  });
  return (
    <>
      <div className="mt-1.6">
        {props.assignmentData?.length ? (
          props.assignmentData?.map((content, index) => (
            <div
              key={`assignment-data-${content.document}-${index}`}
              className="relative justify-between flex p-10 sm:flex-col sm:px-12 sm:py-16 ssm:px-8 border rounded-12 my-6 items-center"
            >
              <div className="flex sm:justify-between w-full">
                <div className="flex items-center">
                  <div className="">
                    <img
                      alt="content icon"
                      src={`${process.env.PUBLIC_URL}/images/content.svg`}
                      className={cx(styles["img-style"])}
                    />
                  </div>
                  <div className="">
                    <div className={cx("lmd:text-16 text-1.4 pl-16", styles.text)}>
                      {content.document.split("/").pop()}
                    </div>
                    <div className="flex pl-16 sm:pl-8">
                      <div className="lmd:text-1.4 text-1.2 text-ashgray ssm:text-1.1  ">
                        {props.meta}
                      </div>
                      <div className="lmd:text-1.4 text-1.2 text-ashgray pl-5 ssm:text-1.1 ssm:pl-2">
                        {props.date}
                      </div>
                    </div>
                  </div>
                </div>
                <div className={cx("smm:hidden flex items-center", styles.button)}>
                  <button
                    onClick={() => {
                      setIsDrop(index);
                    }}
                    className={styles.content}
                  >
                    <img
                      alt="options icon"
                      src={`${process.env.PUBLIC_URL}/images/dots.svg`}
                    />
                  </button>
                </div>
              </div>
              <div className=" smm:flex">
                <div className={cx("sm:hidden", styles.button)}>
                  <button
                    onClick={() => {
                      setIsDrop(index);
                    }}
                    className={styles.content}
                  >
                    <img
                      alt="options icon"
                      src={`${process.env.PUBLIC_URL}/images/dots.svg`}
                    />
                  </button>
                </div>
                {isDrop === index && (
                  <div ref={dropperRef} className={cx("")}>
                    <div
                      className={cx("bg-white rounded-8 py-12 absolute", styles.uc)}
                    >
                      <WAssignmentOptions
                        document={content}
                        openModal={() => {
                          openModal(content.id);
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))
        ) : (
          <NoData />
        )}
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className={styles.DeleteProfile}
      >
        {DeleteItems.map((item, index) => (
          <DeleteCard
            key={`delete-card${index}`}
            deleteHandler={deleteAssignment}
            closeModal={closeModal}
            {...item}
          />
        ))}
      </Modal>
    </>
  );
};
