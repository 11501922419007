import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "./App.scss";
import { AuthProvider } from "./context/AuthContext";
import { Dashboard } from "./pages";

function App() {
  return (
    <Router basename={process.env.REACT_APP_SUBPAGE}>
      <AuthProvider>
        <Dashboard />
      </AuthProvider>
    </Router>
  );
}

export default App;
