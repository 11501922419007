import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";

const PrivateRoute = ({ children }) => {
  let { user, authTokens } = useContext(AuthContext);
  return (
    <>
      {!user || !authTokens || !authTokens.token ? (
        <Navigate to="/login" />
      ) : (
        children
      )}
    </>
  );
};

export default PrivateRoute;
