import cx from "classnames";
import React, { useContext, useRef, useState } from "react";
import Modal from "react-modal";
import { toast } from "react-toastify";
import AuthContext from "../../context/AuthContext";
import useOutsideAlerter from "../../hooks/outsideClickHook";
import { DeleteCard } from "../DeleteCard/DeleteCard";
import { WAssignmentOptions } from "../WAssignmentOptions/WAssignmentOptions";
import { WorkshopButton } from "../WorkshopButton/WorkshopButton";
import styles from "./WorkshopStudentAssignment.module.scss";

export const WorkshopStudentAssignment = (props) => {
  const { authTokens } = useContext(AuthContext);
  const tempWorkshopDate = new Date(props.created_at).toUTCString();
  const [isDrop, setIsDrop] = useState(false);
  const dropperRef = useRef(null);
  useOutsideAlerter(dropperRef, () => {
    setIsDrop(false);
  });
  const ButtonItems = [
    {
      icon: "download.svg",
      text: "Télécharger",
    },
  ];
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }
  const DeleteItems = [
    {
      cross: "cross1.svg",
      icon: "DelTrash.svg",
      title: "Delete Attachment?",
      text: "Deleteing this attachment will move it to the trash permanentaly.",
    },
  ];

  const deleteAssignment = () => {
    var requestOptions = {
      method: "DELETE",
      redirect: "follow",
      headers: {
        Accept: "application/json",
        Authorization: `Token ${authTokens.token}`,
        "Content-Type": "application/json",
      },
    };
    fetch(
      `${process.env.REACT_APP_API_URL}workshop-view/${props?.id}/`,
      requestOptions
    ).then((response) => {
      if (response.ok) {
        toast.success("Pièce-jointe supprimée");
        props.getWorkshopData();
        closeModal();
      }
    });
  };
  return (
    <>
      <div className="flex justify-between border rounded-12 p-10 mt-1.6 text-center bg-white sm:flex-col sm:p-12 items-center gap-4">
        <div className="flex md:justify-between sm:w-full">
          <div className="flex gap-4 text-start items-center">
            <div className="">
              <img
                alt="profile"
                src={`${props.user_details_object?.profile_pic}`}
                className={cx(styles["img-style"])}
              />
            </div>
            <div className="">
              <div className="text-16 lg:text-1.4">
                {props.user_details_object?.first_name}
              </div>
              <div className="text-1.2 text-ashgray">{tempWorkshopDate}</div>
            </div>
          </div>
          <div
            className={cx("md:px-[4px] smm:hidden flex items-center", styles.button)}
          >
            <button
              onClick={() => {
                setIsDrop(!isDrop);
              }}
            >
              <img
                alt="options icon"
                src={`${process.env.PUBLIC_URL}/images/dots.svg`}
                className={cx(styles[""])}
              />
            </button>
          </div>
        </div>
        <div
          className={cx(
            "text-16 lg:text-1.4 sm:pt-4 sm:pl-0 lmd:text-start text-ellipsis whitespace-nowrap overflow-hidden w-[550px]",
            styles.text
          )}
        >
          {props.document.split("/").pop()}
        </div>
        <div className="flex sm:hidden items-center">
          <div className="">
            {ButtonItems.map((item, index) => (
              <WorkshopButton
                key={`workshop-button-${index}`}
                {...item}
                document={props.document}
              />
            ))}
          </div>
          <div className={cx(styles.button)}>
            <button
              onClick={() => {
                setIsDrop(!isDrop);
              }}
              className={cx(styles.content, "flex items-center")}
            >
              <img
                alt="options icon"
                src={`${process.env.PUBLIC_URL}/images/dots.svg`}
                className={cx(styles["img-style-dots"])}
              />
            </button>
          </div>
        </div>
      </div>
      <div className="">
        {isDrop && (
          <div ref={dropperRef} className={cx("relative")}>
            <div className={cx("bg-white rounded-8 py-12 absolute", styles.uc)}>
              <WAssignmentOptions document={props.document} openModal={openModal} />
            </div>
          </div>
        )}
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className={styles.DeleteProfile}
      >
        {DeleteItems.map((item, index) => (
          <DeleteCard
            key={`delete-card${index}`}
            deleteHandler={deleteAssignment}
            {...item}
          />
        ))}
      </Modal>
    </>
  );
};
