import React from "react";
export const AddStudentButton = (props) => {
  return (
    <div className="flex gap-16 grid-cols-12">
      <button
        onClick={props.closeModal}
        className="col-span-6 w-full !font-medium border border-green text-green px-10 py-8 rounded-8 leading-16 text-1.4 border-solid hover:bg-[#17A4541A]"
      >
        Annuler
      </button>
      <button
        type="submit"
        className="col-span-6 w-full !font-medium border text-white bg-green px-10 py-8 rounded-8 leading-16 text-1.4 border-solid hover:bg-[#037f39]"
      >
        Créer un profil
      </button>
    </div>
  );
};
