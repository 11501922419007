import cx from "classnames";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import styles from "./ResetPassword.module.scss";

export const ResetPassword = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const methods = useForm();
  const onSubmit = (data) => {
    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...data,
      }),
    };

    fetch(
      process.env.REACT_APP_API_URL + "dj-rest-auth/password/reset/",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (!result.detail) {
          Object.keys(result).forEach((key) =>
            methods.setError(key, { type: "custom", message: result[key] })
          );
        } else {
          toast.success("Email envoyé");
          props.closeModal();
          navigate("/login");
          methods.reset();
        }
        setIsLoading(false);
      })
      .catch((error) => {
        toast.error(error.message);
        setIsLoading(false);
      });
  };

  return (
    <div className="bg-white rounded-12 p-10  mb-10">
      <div className="text-center justify-center flex pt-38">
        <img
          alt="logo"
          src={`${process.env.PUBLIC_URL}/images/logo1.svg`}
          className={cx(styles.logoStyle, "")}
        />
      </div>
      <p className="text-center pt-48 pb-12 text-blue text-20 md:text-1.8">
        Récupérer mot de passe
      </p>
      <p className="pb-32 text-1.4 md:text-1.2">
        Merci d’inscrire l’adresse email de votre compte CLA
      </p>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="text-center text-20 sm:text-1.8"></div>
        <span className="text-1.4 md:text-1.2">Email</span>
        <div className="pt-16 pb-38">
          <input
            type="email"
            className="p-4 rounded-4 border w-full text-1.4 md:text-1.2"
            placeholder="Votre email"
            {...methods.register("email", { required: "This field is required" })}
          />
        </div>
        <div className="flex gap-16 justify-center">
          <button
            onClick={props.closeModal}
            type=""
            className="pt-4 !font-medium pb-4 pr-7 pl-7 border-green bg-white border text-green rounded-12 text-1.4 md:text-1.2 hover:bg-[#17A4541A]"
          >
            Annuler
          </button>
          <button
            disabled={isLoading}
            type="submit"
            className="pt-4 !font-medium pb-4 pr-7 pl-7 bg-green rounded-12 text-white text-1.4 md:text-1.2 hover:bg-[#037f39]"
          >
            Récupérer
          </button>
        </div>
      </form>
    </div>
  );
};
