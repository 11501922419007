import cx from "classnames";
import React from "react";
import styles from "./WorkshopTop.module.scss";

export const WorkshopTop = (props) => {
  const handleOnClick = () => {
    props.setthemeClicked(props.category_name);
  };
  return (
    <button
      onClick={handleOnClick}
      className={cx(
        props.selectedTheme === props.category_name ? styles.theme : styles.normal,
        "text-ashgray text-1.4 leading-16 pb-16 md:text-1.2"
      )}
    >
      {props.category_name}
    </button>
  );
};
