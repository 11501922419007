import React, { useContext } from "react";
import { toast } from "react-toastify";
import AuthContext from "../../context/AuthContext";

export const WContentOptions = (props) => {
  const { user } = useContext(AuthContext);

  return (
    <div className="">
      <button
        onClick={() => {
          window.open(props.document.document, "_blank");
        }}
        className="px-16 flex gap-1.2 py-6 items-center hover:bg-[rgba(104,213,219,0.05)]"
      >
        <div className="">
          <img
            alt="link download icon"
            src={`${process.env.PUBLIC_URL}/images/downarrow.svg`}
            className="img-style"
          />
        </div>
        <p className="text-1.2">Télécharger</p>
      </button>
      <button
        onClick={() => {
          navigator.clipboard.writeText(props.document.document);
          toast.success("Copié dans le presse-papier");
        }}
        className="px-16 flex gap-1.2 py-6 w-full hover:bg-[rgba(104,213,219,0.05)]"
      >
        <div className="">
          <img
            alt="link circle icon"
            src={`${process.env.PUBLIC_URL}/images/link-circle.svg`}
            className="img-style"
          />
        </div>
        <p className="text-1.2">Copier le lien</p>
      </button>

      {user.user_type === 1 && (
        <div className="px-16 flex w-full py-6 hover:bg-[rgba(104,213,219,0.05)]">
          <button onClick={props.openModal} className="flex w-full gap-1.2">
            <div className="">
              <img
                alt="delete icon"
                src={`${process.env.PUBLIC_URL}/images/Trash.svg`}
                className="img-style"
              />
            </div>
            <p className="text-1.2">Supprimer</p>
          </button>
        </div>
      )}
    </div>
  );
};
