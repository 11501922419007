import cx from "classnames";
import React, { useContext, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { FileInput } from "../../components/FileInput/FileInput";
import AuthContext from "../../context/AuthContext";
import styles from "./EditWorkshop.module.scss";

export const EditWorkshop = () => {
  const [workshopCats, setworkshopCats] = useState([]);
  const { authTokens } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const cancelEdit = () => {
    navigate("/requested-workshops");
  };

  const getCatsData = async () => {
    if (workshopCats?.length) {
      return;
    }
    const response = await fetch(
      process.env.REACT_APP_API_URL + "add-category/"
    ).then((response) => response.json());
    setworkshopCats(response);
  };

  const [workshopType, setworkshopType] = useState([]);
  const getTypeData = async () => {
    if (workshopType?.length) {
      return;
    }
    const response = await fetch(process.env.REACT_APP_API_URL + "ws-types/").then(
      (response) => response.json()
    );
    setworkshopType(response);
  };

  const [workshopLevel, setworkshopLevel] = useState([]);
  const getLevelData = async () => {
    if (workshopLevel?.length) {
      return;
    }
    const response = await fetch(process.env.REACT_APP_API_URL + "ws-levels/").then(
      (response) => response.json()
    );
    setworkshopLevel(response);
  };

  var requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Token ${authTokens.token}`,
    },
  };
  const params = useParams();
  const getWorkshopData = async () => {
    setIsLoading(true);
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}workshop/${params.id}`,
      requestOptions
    ).then((response) => response.json());
    // if (response.wsstatustype === "CLONE") {
    //   response.wsstatustype = "DRAFT";
    // }
    if (response.start_time) {
      let tmpDate = response.start_time.split(":");
      tmpDate.pop();
      response.start_time = tmpDate.join(":");
    }
    if (response.end_time) {
      let tmpDate = response.end_time.split(":");
      tmpDate.pop();
      response.end_time = tmpDate.join(":");
    }
    if (response.workshop_date) {
      response.workshop_date = new Date(response.workshop_date);
    }
    const namedFields = ["category", "level", "type"];
    Object.keys(methods.getValues()).forEach((key) => {
      if (namedFields.includes(key)) {
        methods.setValue(key, response[key]);
      } else methods.setValue(key, response[key]);
    });

    setIsLoading(false);
  };
  const fetchData = async () => {
    await getCatsData();
    await getTypeData();
    await getLevelData();
    await getWorkshopData();
  };
  useEffect(() => {
    console.log("loading edit workshop");
    fetchData();
  }, []);

  const getCustomStyles = (fieldName) => {
    const customStyles = {
      control: (base) => ({
        ...base,
        padding: "5px 8px 6px 8px",
        borderColor: errors[fieldName]?.message ? "red !important" : "#d4e6ff",
        "&:hover": {
          borderColor: "#2684ff",
        },
      }),
      valueContainer: (base) => ({
        ...base,
        paddingTop: "0 !important",
        paddingBottom: "0 !important",
      }),
      singleValue: (base) => ({
        ...base,
        fontSize: "12px",
      }),
      menuList: (base) => ({
        ...base,
        paddingTop: "0 !important",
        paddingBottom: "0 !important",
        borderRadius: "4px",
      }),
      option: (base) => ({
        ...base,
        color: "#020422",
      }),
    };
    return customStyles;
  };

  const timeOptions = [
    { label: "12:00 AM", value: "00:00" },
    { label: "12:15 AM", value: "00:15" },
    { label: "12:30 AM", value: "00:30" },
    { label: "12:45 AM", value: "00:45" },
    { label: "01:00 AM", value: "01:00" },
    { label: "01:15 AM", value: "01:15" },
    { label: "01:30 AM", value: "01:30" },
    { label: "01:45 AM", value: "01:45" },
    { label: "02:00 AM", value: "02:00" },
    { label: "02:15 AM", value: "02:15" },
    { label: "02:30 AM", value: "02:30" },
    { label: "02:45 AM", value: "02:45" },
    { label: "03:00 AM", value: "03:00" },
    { label: "03:15 AM", value: "03:15" },
    { label: "03:30 AM", value: "03:30" },
    { label: "03:45 AM", value: "03:45" },
    { label: "04:00 AM", value: "04:00" },
    { label: "04:15 AM", value: "04:15" },
    { label: "04:30 AM", value: "04:30" },
    { label: "04:45 AM", value: "04:45" },
    { label: "05:00 AM", value: "05:00" },
    { label: "05:15 AM", value: "05:15" },
    { label: "05:30 AM", value: "05:30" },
    { label: "05:45 AM", value: "05:45" },
    { label: "06:00 AM", value: "06:00" },
    { label: "06:15 AM", value: "06:15" },
    { label: "06:30 AM", value: "06:30" },
    { label: "06:45 AM", value: "06:45" },
    { label: "07:00 AM", value: "07:00" },
    { label: "07:15 AM", value: "07:15" },
    { label: "07:30 AM", value: "07:30" },
    { label: "07:45 AM", value: "07:45" },
    { label: "08:00 AM", value: "08:00" },
    { label: "08:15 AM", value: "08:15" },
    { label: "08:30 AM", value: "08:30" },
    { label: "08:45 AM", value: "08:45" },
    { label: "09:00 AM", value: "09:00" },
    { label: "09:15 AM", value: "09:15" },
    { label: "09:30 AM", value: "09:30" },
    { label: "09:45 AM", value: "09:45" },
    { label: "10:00 AM", value: "10:00" },
    { label: "10:15 AM", value: "10:15" },
    { label: "10:30 AM", value: "10:30" },
    { label: "10:45 AM", value: "10:45" },
    { label: "11:00 AM", value: "11:00" },
    { label: "11:15 AM", value: "11:15" },
    { label: "11:30 AM", value: "11:30" },
    { label: "11:45 AM", value: "11:45" },
    { label: "12:00 PM", value: "12:00" },
    { label: "12:15 PM", value: "12:15" },
    { label: "12:30 PM", value: "12:30" },
    { label: "12:45 PM", value: "12:45" },
    { label: "1:00 PM", value: "13:00" },
    { label: "01:15 PM", value: "013:15" },
    { label: "01:30 PM", value: "013:30" },
    { label: "01:45 PM", value: "013:45" },
    { label: "02:00 PM", value: "014:00" },
    { label: "02:15 PM", value: "14:15" },
    { label: "02:30 PM", value: "14:30" },
    { label: "02:45 PM", value: "14:45" },
    { label: "03:00 PM", value: "15:00" },
    { label: "03:15 PM", value: "15:15" },
    { label: "03:30 PM", value: "15:30" },
    { label: "03:45 PM", value: "15:45" },
    { label: "04:00 PM", value: "16:00" },
    { label: "04:15 PM", value: "16:15" },
    { label: "04:30 PM", value: "16:30" },
    { label: "04:45 PM", value: "16:45" },
    { label: "05:00 PM", value: "17:00" },
    { label: "05:15 PM", value: "17:15" },
    { label: "05:30 PM", value: "17:30" },
    { label: "05:45 PM", value: "17:45" },
    { label: "06:00 PM", value: "18:00" },
    { label: "06:15 PM", value: "18:15" },
    { label: "06:30 PM", value: "18:30" },
    { label: "06:45 PM", value: "18:45" },
    { label: "07:00 PM", value: "19:00" },
    { label: "07:15 PM", value: "19:15" },
    { label: "07:30 PM", value: "19:30" },
    { label: "07:45 PM", value: "19:45" },
    { label: "08:00 PM", value: "20:00" },
    { label: "08:15 PM", value: "20:15" },
    { label: "08:30 PM", value: "20:30" },
    { label: "08:45 PM", value: "20:45" },
    { label: "09:00 PM", value: "21:00" },
    { label: "09:15 PM", value: "21:15" },
    { label: "09:30 PM", value: "21:30" },
    { label: "09:45 PM", value: "21:45" },
    { label: "10:00 PM", value: "22:00" },
    { label: "10:15 PM", value: "22:15" },
    { label: "10:30 PM", value: "22:30" },
    { label: "10:45 PM", value: "22:45" },
    { label: "11:00 PM", value: "23:00" },
    { label: "11:15 PM", value: "23:15" },
    { label: "11:30 PM", value: "23:30" },
    { label: "11:45 PM", value: "23:45" },
  ];
  const statusOptions = [
    { label: "Active", value: "ACTIVE" },
    { label: "Draft", value: "DRAFT" },
    { label: "Cloned (Draft)", value: "CLONE" },
  ];
  const methods = useForm({});

  const errors = methods.formState.errors;
  const onSubmit = (data) => {
    const formData = new FormData();
    const tempWorkshopDate = new Date(data.workshop_date);
    data.workshop_date = `${tempWorkshopDate.toLocaleString("default", {
      month: "long",
    })} ${tempWorkshopDate.getDate()}, ${tempWorkshopDate.getFullYear()}`;
    data.workshop_date = `${tempWorkshopDate.getFullYear()}-${
      tempWorkshopDate.getMonth() + 1
    }-${tempWorkshopDate.getDate()}`;
    Object.keys(data).forEach((key) => {
      if (key === "cover_img") {
        if ("File" in window && data[key][0] instanceof File)
          formData.append(key, data[key][0]);
      } else if (data[key]) formData.append(key, data[key]);
    });

    var requestOptions = {
      method: "PUT",
      redirect: "follow",
      headers: {
        Accept: "application/json",
        Authorization: `Token ${authTokens.token}`,
      },
      body: formData,
    };
    setIsLoading(true);
    fetch(
      process.env.REACT_APP_API_URL + `add-workshop/${params.id}/`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (!result.id) {
          Object.keys(result).forEach((key) =>
            methods.setError(key, { type: "custom", message: result[key] })
          );
        } else {
          toast.success("Atelier édité");
          methods.reset();
          navigate("/workshops");
        }
        setIsLoading(false);
      });
  };

  return (
    <>
      <div className=" bg-white rounded-12 p-10 mb-10 md:p-16">
        <h1 className="text-20 text-blue pb-10 md:text-1.8">Éditer un atelier</h1>
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onSubmit)}
            encType="multipart/form-data"
          >
            <div className="grid grid-cols-12 md:grid-cols-6">
              <div className="col-span-6 pr-16 md:pr-0">
                <div className="pb-16">
                  <h1 className="mb-[16px] text-1.4 md:text-1.2 leading-16 md:mb-8">
                    Titre
                  </h1>
                  <input
                    className={cx(
                      "border rounded-8 px-16 py-17 max-w-full w-full mb-[4px] text-1.2 leading-14",
                      { "!border-red-500": errors?.workshop_name?.message }
                    )}
                    placeholder="Write Workshop Title"
                    {...methods.register("workshop_name", {
                      required: "This field may not be blank.",
                    })}
                  />
                  <div className="text-red-500 h-[12px] ">
                    {errors?.workshop_name?.message && errors.workshop_name.message}
                  </div>
                </div>
                <div className="pb-16">
                  <h1 className="mb-[16px] text-1.4 md:text-1.2 leading-16 md:mb-8">
                    Description
                  </h1>
                  <textarea
                    type="textarea"
                    className={cx(
                      "border rounded-12 p-16 max-w-full w-full h-[131px] mb-[4px] text-1.2 leading-14",
                      {
                        "!border-red": errors?.description?.message,
                      }
                    )}
                    placeholder="Write Workshop Description"
                    {...methods.register("description")}
                  />
                  <div className="text-red-500 h-[12px] ">
                    {errors?.description?.message && errors.description.message}
                  </div>
                </div>
                <div className="pb-16">
                  <h1 className="mb-[16px] text-1.4 md:text-1.2 leading-16 md:mb-8">
                    Thème
                  </h1>
                  <label htmlFor="category"></label>

                  <Controller
                    style={styles.select}
                    className="border rounded-12 px-16 py-17 max-w-full w-full"
                    control={methods.control}
                    name="category"
                    rules={{
                      required: "This field may not be blank.",
                    }}
                    render={({ field: { onChange, value, ref } }) => (
                      <Select
                        className="react-select-container mb-[4px]"
                        inputRef={ref}
                        menuPlacement="auto"
                        placeholder="Sélectionner thème.."
                        styles={getCustomStyles("category")}
                        classNamePrefix="react-select"
                        getOptionLabel={(option) => option.category_name}
                        getOptionValue={(option) => option.id}
                        options={workshopCats}
                        onChange={(val) => onChange(val.id)}
                        value={workshopCats?.find((c) => c.id === value)}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: "8px",
                          colors: {
                            ...theme.colors,
                            primary25: "#D4E6FF",
                            primary: "#93C1FF",
                            color: "#020422",
                          },
                        })}
                      />
                    )}
                  />

                  <div className="text-red-500 h-[12px] ">
                    {errors?.category?.message && errors.category.message}
                  </div>
                </div>

                <div className="flex gap-16 grid grid-cols-12 pb-16 md:flex-col md:grid-cols-2">
                  <div className="col-span-4 md:col-span-6 ">
                    <h1 className="mb-[16px] text-1.4 md:text-1.2 leading-16 md:mb-8">
                      Type
                    </h1>
                    <label htmlFor="type"></label>

                    <Controller
                      style={styles.select}
                      className="border rounded-12 px-16 py-17 max-w-full w-full"
                      control={methods.control}
                      name="type"
                      rules={{
                        required: "This field may not be blank.",
                      }}
                      render={({ field: { onChange, value, ref } }) => (
                        <Select
                          className="react-select-container mb-[4px]"
                          inputRef={ref}
                          menuPlacement="auto"
                          placeholder="Sélectionner type.."
                          styles={getCustomStyles("type")}
                          classNamePrefix="react-select"
                          getOptionLabel={(option) => option.type_name}
                          getOptionValue={(option) => option.id}
                          options={workshopType}
                          onChange={(val) => onChange(val.id)}
                          value={workshopType.find((c) => c.id === value)}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: "8px",
                            colors: {
                              ...theme.colors,
                              primary25: "#D4E6FF",
                              primary: "#93C1FF",
                              color: "#020422",
                            },
                          })}
                        />
                      )}
                    />
                    <div className="text-red-500 h-[12px] ">
                      {errors?.type?.message && errors.type.message}
                    </div>
                  </div>
                  <div className="col-span-8 md:col-span-6">
                    <h1 className="mb-[16px] text-1.4 md:text-1.2 leading-16 md:mb-8">
                      Niveau
                    </h1>
                    <label htmlFor="level"></label>

                    <Controller
                      style={styles.select}
                      className="border rounded-12 px-16 py-17 max-w-full w-full"
                      control={methods.control}
                      name="level"
                      rules={{
                        required: "This field may not be blank.",
                      }}
                      render={({ field: { onChange, value, ref } }) => (
                        <Select
                          className="react-select-container mb-[4px]"
                          inputRef={ref}
                          menuPlacement="auto"
                          placeholder="Sélectionner niveau.."
                          styles={getCustomStyles("level")}
                          classNamePrefix="react-select"
                          getOptionLabel={(option) => option.level_name}
                          getOptionValue={(option) => option.id}
                          options={workshopLevel}
                          onChange={(val) => onChange(val.id)}
                          value={workshopLevel.find((c) => c.id === value)}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: "8px",
                            colors: {
                              ...theme.colors,
                              primary25: "#D4E6FF",
                              primary: "#93C1FF",
                              color: "#020422",
                            },
                          })}
                        />
                      )}
                    />
                    <div className="text-red-500 h-[12px] ">
                      {errors?.level?.message && errors.level.message}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-6 pl-16 md:pl-0">
                <div className="pb-16">
                  <h1 className="mb-[16px] text-1.4 md:text-1.2 leading-16 md:mb-8">
                    Image
                  </h1>
                  <FileInput
                    accept={{
                      "image/*": [".jpeg", ".jpg", ".png", ".webp"],
                    }}
                    name="cover_img"
                    {...methods.register("cover_img", {
                      required: "This field may not be blank.",
                    })}
                    isError={errors?.cover_img?.message}
                  />
                  <div className="text-red-500 mt-[4px] h-[12px] ">
                    {errors?.cover_img?.message && errors.cover_img.message}
                  </div>
                </div>
                <div className="pb-16">
                  <h1 className="text-1.4 md:text-1.2 leading-16">Date</h1>
                  <Controller
                    control={methods.control}
                    name="workshop_date"
                    rules={{
                      required: "This field may not be blank.",
                    }}
                    render={({ field }) => (
                      <>
                        <DatePicker
                          wrapperClassName={cx(
                            errors?.workshop_date?.message
                              ? styles.error
                              : styles.DatePicker
                          )}
                          dateFormat="MMMM dd, yyyy"
                          placeholderText="Sélectionner Date.."
                          onChange={(date) => field.onChange(date)}
                          selected={field.value}
                          className="mb-[4px]"
                        />
                      </>
                    )}
                  />
                  <div className="text-red-500 h-[12px] ">
                    {/* {errors?.workshop_date?.message && errors.workshop_date.message} */}
                  </div>
                </div>
                <div className="flex gap-16 grid grid-cols-12 pb-16 md:flex-col md:grid-cols-2">
                  <div className="col-span-6 ">
                    <h1 className="mb-[16px] text-1.4 md:text-1.2 leading-16 md:mb-8">
                      Début
                    </h1>
                    <label htmlFor="start_time"></label>
                    <Controller
                      style={styles.select}
                      className="border rounded-12 px-16 py-17 max-w-full w-full"
                      control={methods.control}
                      name="start_time"
                      rules={{
                        required: "This field may not be blank.",
                      }}
                      render={({ field: { onChange, value, ref } }) => (
                        <>
                          <Select
                            className="react-select-container mb-[4px]"
                            inputRef={ref}
                            placeholder="Sélectionner début.."
                            styles={getCustomStyles("start_time")}
                            classNamePrefix="react-select"
                            options={timeOptions}
                            value={timeOptions.find((c) => c.value === value)}
                            onChange={(val) => onChange(val.value)}
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: "8px",
                              colors: {
                                ...theme.colors,
                                primary25: "#D4E6FF",
                                primary: "#93C1FF",
                                color: "#020422",
                              },
                            })}
                          />
                        </>
                      )}
                    />
                    <div className="text-red-500 h-[12px] ">
                      {/* {errors?.start_time?.message && errors.start_time.message} */}
                    </div>
                  </div>
                  <div className="col-span-6">
                    <h1 className="mb-[16px] text-1.4 md:text-1.2 leading-16 md:mb-8">
                      Fin
                    </h1>
                    <label htmlFor="themes"></label>
                    <Controller
                      style={styles.select}
                      className="border rounded-12 px-16 py-17 max-w-full w-full"
                      control={methods.control}
                      name="end_time"
                      rules={{
                        required: "This field may not be blank.",
                      }}
                      render={({ field: { onChange, value, ref } }) => (
                        <Select
                          className="react-select-container mb-[4px]"
                          inputRef={ref}
                          placeholder="Sélectionner fin.."
                          menuPlacement="auto"
                          styles={getCustomStyles("end_time")}
                          classNamePrefix="react-select"
                          options={timeOptions}
                          value={timeOptions.find((c) => c.value === value)}
                          onChange={(val) => onChange(val.value)}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: "8px",
                            colors: {
                              ...theme.colors,
                              primary25: "#D4E6FF",
                              primary: "#93C1FF",
                              color: "#020422",
                            },
                          })}
                        />
                      )}
                    />
                    <div className="text-red-500 h-[12px] ">
                      {/* {errors?.end_time?.message && errors.end_time.message} */}
                    </div>
                  </div>
                </div>

                <div className="col-span-8 md:col-span-6 pb-16">
                  <h1 className="mb-[16px] text-1.4 md:text-1.2 leading-16 md:mb-8">
                    Statut
                  </h1>
                  <label htmlFor="wsstatustype"></label>

                  <Controller
                    style={styles.select}
                    className="border rounded-12 px-16 py-17 max-w-full w-full"
                    control={methods.control}
                    name="wsstatustype"
                    rules={{
                      required: "This field may not be blank.",
                    }}
                    render={({ field: { onChange, value, ref } }) => (
                      <Select
                        className="react-select-container mb-[4px]"
                        inputRef={ref}
                        menuPlacement="auto"
                        placeholder="Sélectionner statut.."
                        styles={getCustomStyles("wsstatustype")}
                        classNamePrefix="react-select"
                        options={statusOptions}
                        value={statusOptions.find((c) => c.value === value)}
                        onChange={(val) => onChange(val.value)}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: "8px",
                          colors: {
                            ...theme.colors,
                            primary25: "#D4E6FF",
                            primary: "#93C1FF",
                            color: "#020422",
                          },
                        })}
                      />
                    )}
                  />

                  <div className="text-red-500 h-[12px] ">
                    {errors?.wsstatustype?.message && errors.wsstatustype.message}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex pb-32 pt-10 gap-[32px] md:pb-16 md:pt-16 md:gap-10">
              <button
                onClick={cancelEdit}
                disabled={isLoading}
                className={cx("text-1.4 !font-medium md:text-1.2", styles.cancel)}
                type="button"
              >
                Annuler
              </button>
              <button
                disabled={isLoading}
                className={cx("text-1.4 !font-medium md:text-1.2", styles.button)}
                type="submit"
              >
                Confirmer
              </button>
            </div>
          </form>
        </FormProvider>
      </div>
    </>
  );
};
