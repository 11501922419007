import cx from "classnames";
import React, { useRef, useState } from "react";
import Modal from "react-modal";
import { NavLink } from "react-router-dom";
import useOutsideAlerter from "../../hooks/outsideClickHook";
import { DeleteUserCard } from "../DeleteUserCard/DeleteUserCard";
import { StudentList } from "../StudentList/StudentList";
import { WorkshopUCMenu } from "../WorkshopUCMenu/WorkshopUCMenu";
import styles from "./PeopleTeacher.module.scss";

export const PeopleTeacher = (props) => {
  const [isDrop, setIsDrop] = useState(false);
  const dropperRef = useRef(null);
  useOutsideAlerter(dropperRef, () => {
    setIsDrop(false);
  });
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalDelOpen, setmodalDelOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }
  function openDelModal() {
    setmodalDelOpen(true);
  }
  function closeDelModal() {
    setmodalDelOpen(false);
  }
  const removeHandler = () => {
    props.deleteUser(props.id, closeDelModal);
  };
  return (
    <div className="">
      <div className="pt-16">
        <div className="border px-10 py-16 justify-between flex rounded-12 sm:p-16 items-center hover:bg-[rgba(104,213,219,0.05)] ">
          <div className="flex gap-10 items-center sm:gap-4 w-full">
            <div className="w-[17px] pr-1 flex items-center">
              <input
                checked={!!props.checked}
                onChange={(e) => {
                  props.checkChangeHandler(e.target.checked, props.id);
                }}
                type="checkbox"
              />
            </div>
            <NavLink className="w-full" to={`/student-profile/${props?.id}`}>
              <div className="flex gap-5 items-center sm:gap-2 w-full ">
                <div className="sm:hidden min-w-[40px]">
                  <img
                    alt="profile icon"
                    src={props.profile_pic}
                    className={cx(styles["img-style"])}
                  />
                </div>
                <div className="text-16 leading-[24px] md:text-1.4 w-[calc(100%-17px)] sm:w-[calc(100%-80px)]">
                  <p
                    title={`${props.first_name} ${props.last_name}`}
                    className=" whitespace-nowrap text-ellipsis overflow-hidden"
                  >
                    {props.first_name} {props.last_name}
                  </p>
                  <p
                    title={props.email}
                    className="text-1.3 opacity-40 whitespace-nowrap text-ellipsis overflow-hidden leading-16 font-light"
                  >
                    {props.email}
                  </p>
                </div>
              </div>
            </NavLink>
          </div>
          <div className={cx("flex", styles.button)}>
            <button
              onClick={() => {
                setIsDrop(!isDrop);
              }}
              className={cx(styles.content, "items-center sm:min-w-[32px]")}
            >
              <img
                alt="options icon"
                src={`${process.env.PUBLIC_URL}/images/dots.svg`}
                className={cx(styles["dot-style"])}
              />
            </button>
          </div>
        </div>
      </div>
      {isDrop && (
        <div ref={dropperRef} className={cx("relative", "z-[1]")}>
          <div
            className={cx(
              "bg-white rounded-8 py-12 absolute mld:mt-[10px] mld:mr-[12px]",
              styles.uc
            )}
          >
            <WorkshopUCMenu
              openDelModal={openDelModal}
              openModal={openModal}
              data={props}
            />
          </div>
        </div>
      )}
      <Modal
        isOpen={modalDelOpen}
        onRequestClose={closeDelModal}
        className={styles.DeleteProfile}
      >
        <DeleteUserCard deleteHandler={removeHandler} closeModal={closeDelModal} />
      </Modal>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className={styles.EditProfile}
      >
        <StudentList
          label={props?.label}
          peopleData={props}
          getpeopleData={props.getpeopleData}
          closeModal={closeModal}
        />
      </Modal>
    </div>
  );
};
