import cx from "classnames";
import React, { useContext, useEffect, useState } from "react";
import { EventWorkshop } from "../../components/EventWorkshop/EventWorkshop";
import { FilterComponent } from "../../components/FilterComponent/FilterComponent";
import { LoadingScreen } from "../../components/LoadingScreen/LoadingScreen";
import { NoData } from "../../components/NoData/NoData";
import { WorkshopTop } from "../../components/WorkshopTop/WorkshopTop";
import AuthContext from "../../context/AuthContext";

const Workshops = (props) => {
  const { authTokens } = useContext(AuthContext);
  const [upcomingWorkshop, setupcomingWorkshop] = useState([]);
  const [workshopType, setWorkshopType] = useState(null);
  const [workshopLevel, setWorkshopLevel] = useState(null);

  const [isLoading, setisLoading] = useState(false);

  const getWorkshopData = async () => {
    setisLoading(true);
    var requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Token ${authTokens.token}`,
      },
    };
    let typeofAPI = "";
    if (props.upcoming) {
      typeofAPI = "upcoming-workshops/";
    } else if (props.requested) {
      typeofAPI = "add-workshop/?wsstatustype=DRAFT";
    } else if (props.clone) {
      typeofAPI = "add-workshop/?wsstatustype=CLONE";
    } else {
      typeofAPI = "add-workshop/?wsstatustype=ACTIVE";
    }

    const response = await fetch(
      process.env.REACT_APP_API_URL + typeofAPI,
      requestOptions
    ).then((response) => response.json());
    setupcomingWorkshop(response);
    setisLoading(false);
  };

  useEffect(() => {
    getWorkshopData();
  }, []);
  let heading = "";
  if (props.upcoming) {
    heading = "Ateliers programmés";
  } else if (props.requested) {
    heading = "Ateliers demandés";
  } else if (props.clone) {
    heading = "Ateliers dupliqués";
  } else {
    heading = "Ateliers";
  }

  const [workshopCats, setworkshopCats] = useState([]);
  const getCatsData = async () => {
    const response = await fetch(
      process.env.REACT_APP_API_URL + "add-category"
    ).then((response) => response.json());
    const defaultOptions = { category_name: "Tous", id: -1, status: true };
    response.unshift(defaultOptions);
    setworkshopCats(response);
  };
  useEffect(() => {
    getCatsData();
  }, []);

  const [themeClicked, setthemeClicked] = useState(0);
  const filteredWorkshops = upcomingWorkshop.filter((workshop) => {
    let filterCount = 0;
    let matchingFilterCount = 0;
    if (workshopType && workshopType !== "Default") {
      filterCount++;
      if (workshop.type_name === workshopType) {
        matchingFilterCount++;
      }
    }
    if (workshopLevel && workshopLevel !== "Default") {
      filterCount++;
      if (workshop.level_name === workshopLevel) {
        matchingFilterCount++;
      }
    }
    if (themeClicked && themeClicked !== "Tous") {
      filterCount++;
      if (workshop.category_name === themeClicked) {
        matchingFilterCount++;
      }
    }
    if (filterCount === matchingFilterCount) return true;
    return false;
  });

  return (
    <>
      <div className="bg-white p-10 rounded-12 flex flex-col mb-10 md:p-16 md:mb-[16px]">
        {upcomingWorkshop?.length && workshopCats?.length ? (
          <div className="grid grid-cols-5 bg-white ">
            <span
              className={cx(
                "col-span-5 flex gap-48 sm:overflow-scroll sm:w-full sm:justify-start sm:whitespace-nowrap sm:gap-10 justify-center"
              )}
            >
              {workshopCats.map(
                (item, index) =>
                  item.status && (
                    <WorkshopTop
                      key={`workshop-top-${index}`}
                      {...item}
                      selectedTheme={themeClicked}
                      setthemeClicked={setthemeClicked}
                    />
                  )
              )}
            </span>
          </div>
        ) : (
          <></>
        )}
        {upcomingWorkshop?.length && workshopCats?.length ? <hr></hr> : <></>}
        <div className="mmd:flex pb-10 justify-between pt-38 md:pt-10  items-center">
          <div className="flex gap-4 md:text-center md:mb-10 sm:mb-[16px]">
            <h1 className="text-16 mmd:text-20 text-blue md:text-1.8">{heading}</h1>
          </div>

          {upcomingWorkshop?.length && workshopCats?.length ? (
            <div className="mmd:flex mmd:justify-end">
              <FilterComponent
                setWorkshopType={setWorkshopType}
                selectedTypeOption={workshopType}
                setWorkshopLevel={setWorkshopLevel}
                selectedLevelOption={workshopLevel}
              />
            </div>
          ) : (
            <></>
          )}
        </div>
        {isLoading ? (
          <div className="py-[200px]">
            <LoadingScreen />
          </div>
        ) : filteredWorkshops?.length ? (
          <div>
            <section className="grid grid-cols-3 gap-10 md:gap-16 sm:flex sm:flex-col smd:grid-cols-2 xlm:grid-cols-2">
              {filteredWorkshops.map((item, index) => (
                <EventWorkshop
                  editLink={props.requested}
                  hideOptions={props.requested}
                  showStatus={props.requested || props.clone}
                  // hideDate={props.hideDate}
                  hideLabels={props.hideDate}
                  key={`event-item-${index}`}
                  {...item}
                  getWorkshopData={getWorkshopData}
                />
              ))}
            </section>
          </div>
        ) : (
          <NoData />
        )}
      </div>
    </>
  );
};

export default Workshops;
