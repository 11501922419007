import React, { useContext } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import AuthContext from "../../context/AuthContext";
import { FileInput } from "../FileInput/FileInput";
import { WorkshopAssignmentCom } from "../WorkshopAssignmentCom/WorkshopAssignmentCom";

export const WorkshopAssignment = (props) => {
  const { authTokens } = useContext(AuthContext);

  const methods = useForm();

  const onSubmit = (data) => {
    let formData = new FormData();
    formData.append("workshop", parseInt(props.workshopId));
    formData.append("document", data.cover_img?.pop());
    var requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Token ${authTokens.token}`,
      },
      body: formData,
    };
    fetch(process.env.REACT_APP_API_URL + "workshop-view/", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.error) {
          toast.error(result.error);
        } else if (!result?.length) {
          Object.keys(result).forEach((key) =>
            methods.setError(key, { type: "custom", message: result[key] })
          );
        } else {
          toast.success("Ajouté");
          props.detailWorkshop();
        }
        methods.reset();
      });
  };

  return (
    <>
      <div className="">
        <FormProvider {...methods}>
          <form
            onChange={methods.handleSubmit(onSubmit)}
            encType="multipart/form-data"
          >
            <FileInput
              accept={{
                "image/*": [".jpeg", ".jpg", ".png", ".webp"],
                "video/*": [".mp4", ".mpeg", ".webp"],
                "application/pdf": [".pdf"],
                "application/msword": [".doc"],
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                  [".docx"],
                "application/vnd.ms-excel": [".xls"],
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                  [".xlsx"],
                "application/vnd.ms-powerpoint": [".ppt"],
                "application/vnd.openxmlformats-officedocument.presentationml.presentation":
                  [".pptx"],
                "text/csv": [".csv"],
                "text/rtf": [".rtf"],
                "application/vnd.oasis.opendocument.text": [".odt"],
              }}
              name="cover_img"
              onChangeHandler={methods.handleSubmit(onSubmit)}
            />
          </form>
        </FormProvider>
      </div>
      <div className="bg-white rounded-12">
        <WorkshopAssignmentCom
          assignmentData={props.assignmentData}
          getWorkshopData={props.getWorkshopData}
        />
      </div>
    </>
  );
};
